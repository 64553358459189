import React, { useState } from "react";
import "./singin.css";
import CustomNavbar from "../Navbar/Navbar";
import CustomFooter from "../Footer/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify'; // Added import for react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Added import for react-toastify CSS

const Singin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();


     // Check if email and password are filled
     if (!formData.email || !formData.password) {
      toast.error('Please fill in both email and password fields.');
      return;
    }
    // Add your form submission logic here
    console.log(formData); 
      console.log("abc====", formData)    
          axios.post(`${Env.server}/api/trader/login`, formData)
          .then((res) => {
              const resp = res.data.trader;
              localStorage.setItem('auth-data',JSON.stringify(res.data.trader));
              console.log("response===>>>", res.data)
              localStorage.setItem("id", resp._id)
              localStorage.setItem("firstname", resp.firstName)
              localStorage.setItem("lastname", resp.lastName) 
              localStorage.setItem("email", resp.email)
              localStorage.setItem("role", resp.role)
              localStorage.setItem("address", resp.tradingAddress)
              toast.success('Sign in successful!');
              navigate("/"); 
          })
          .catch((err) => {
              console.log("abc===>>>", err);
              toast.error('Error signing in. Please check your credentials.');
              
          }) 
 
  };

  return (
    <>
      <CustomNavbar />
      <div className="trade-signin-container">
        <div className="trade-signin-form-container">
          <form onSubmit={handleSubmit} className="trade-signin-form">
          <h1 className="fs-7" style={{color: '#071A44'}}>Sign In</h1>
            <div className="trade-form-group">
              <input
                type="email"
                name="email"
                className="trade-signin-input"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
              <input
                type="password"
                name="password"
                className="trade-signin-input"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
            </div>
           
            <button type="button" onClick={handleSubmit} class="btnSubmit btn-lg mt-2 bg-white fw-bolder fs-9" style={{color: '#071A44', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>Sign In</button>
            <p className="text-center">
              Don't have an account? <Link to="/TradeSignup">Sign up</Link>
            </p>
          </form>
        </div>
      </div>
      <CustomFooter />
      <ToastContainer /> {/* Added ToastContainer for toast notifications */}
    </>
  );
};

export default Singin;
