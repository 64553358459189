import React, { useEffect, useState } from "react";
// import "./Banar.css"
import Navbar from "../Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import { useParams } from "react-router-dom";


import { Paper, Grid, Card, CardContent } from '@mui/material'
import { TextField, List, ListItemButton, ListItemText, Popper } from '@mui/material';
const AmPage2 = () => {
    const { brand } = useParams();
    const navigate = useNavigate();
 
 
    const options = ['Option 1', 'Option 2', 'Option 3']

    const [data, setdata] = useState();


    const [isSelected, setIsSelected] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [ModelGetByParams, setModelGetByParams] = useState([]);
    const [AccessGetByParams, setAccessGetByParams] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleCheckboxChange = () => {
        setIsSelected(!isSelected);
    };

    function groupProductsByType(products) {
        const groupedProducts = {};
        products.forEach(product => {
            if (!groupedProducts[product.typeName]) {
                groupedProducts[product.typeName] = [];
            }
            groupedProducts[product.typeName].push(product);
        });
        return groupedProducts;
    }

    useEffect(() => {
        getAllByNmeAcc();
    }, [])

    const getAllByNmeAcc = () => {
        axios.get(`${Env.server}/api/subsubcatagory/GetModelByAccTS/${brand}`)
            .then((res) => {
                console.log("abc====>>>", res.data.subsubcatagories);
                setModelGetByParams(res.data.subsubcatagories);
                setAccessGetByParams(res.data.subsubcatagories)


                setdata(res.data.subsubcatagories)
            })
            .catch((err) => {
                console.log("err====", err)
            })
    }
    const uniqueSubcategories = new Set(ModelGetByParams.map(card => card.subcatagory));

    // Convert Set back to an array
    const uniqueSubcategoriesArray = [...uniqueSubcategories];



    const handleInputChange = (event) => {
        setSearchText(event.target.value?.toLowerCase());
        setAnchorEl(event.currentTarget);
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };



    const handleCardClick = (selectedProduct, quantity) => {
        if (quantity === undefined) {
            quantity = 1;
        }
        console.log("quantity------", quantity);
        let products = JSON.parse(localStorage.getItem('selectedProducts')) || [];

        // Check if the selected product already exists in the array
        const existingProductIndex = products.findIndex(product => product._id === selectedProduct._id);

        if (existingProductIndex !== -1) {
            // If the product already exists, update it including quantity
            products[existingProductIndex].quantity = quantity;
        } else {
            // If the product does not exist, add it to the array including quantity
            selectedProduct.quantity = quantity;
            products.push(selectedProduct);
        }

        // Store the updated products array in local storage
        localStorage.setItem('selectedProducts', JSON.stringify(products));
    }
















    const [productQuantities, setProductQuantities] = useState({});


    const increaseQuantity = (productId) => {
        setProductQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 0) + 1
        }));
    };

    // Function to handle decreasing the quantity for a specific product
    const decreaseQuantity = (productId) => {
        if (productQuantities[productId] && productQuantities[productId] > 0) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: prevQuantities[productId] - 1
            }));
        }
    };

    // Function to handle changing the quantity directly for a specific product
    const handleQuantityChange = (productId, event) => {
        const value = parseInt(event.target.value);
        if (!isNaN(value) && value >= 1) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: value
            }));
        }
    };














    return (
        <>

            <Navbar />

 
            <div className="container-fluid">
                <h3 className="text-center mt-3">Select Model</h3>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-6"> {/* Adjust column size here */}
                            <div className="input-group mb-3">
                                {/* <input type="text" className="form-control" placeholder="Search products" /> */}
                                <div style={{ position: 'relative', display: 'inline-block', width: '400px' }}>
                                    <TextField
                                        id="search"
                                        label="Search..."
                                        variant="outlined"
                                        value={searchText}
                                        onChange={handleInputChange}
                                        onClick={handleClick}
                                        sx={{ width: '100%' }} // Set the width of the TextField
                                    />
                                    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} style={{ width: '400px' }}>
                                        <Paper>
                                            <List>
                                                {options
                                                    .filter(option => option?.toLowerCase().includes(searchText))
                                                    .map((option, index) => (
                                                        <ListItemButton key={index} onClick={() => setSearchText(option)}>
                                                            <ListItemText primary={option} />
                                                        </ListItemButton>
                                                    ))}
                                            </List>
                                        </Paper>
                                    </Popper>
                                </div>
                                <button className="btn btn-outline-primary" type="button">
                                    <i className="bi bi-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row" style={{ cursor: "pointer" }}>
                        {uniqueSubcategoriesArray.map((subcategory, index) => (
                            <div  key={index} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <Link to={`/Model/${brand}/${subcategory}`}>
                                    <div className="card mb-4" style={{ backgroundColor: "white", border: "1px solid grey", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
                                        <div className="view overlay">
                                            {/* Assuming card.imageSubsubCatagory[0] exists */}
                                            <img className="card-img-top"  style={{height:"300px"}} src={getAsset(ModelGetByParams.find(card => card.subcatagory === subcategory).imageSubsubCatagory[0])}  alt="Card image cap" />
                                            <div className="mask rgba-white-slight"></div>
                                        </div>
                                        <div className="card-body">
                                            <h4 className="card-title text-center">{subcategory}</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">



                    <div className="col-lg-3">
                        <div style={{ width: '100%', backgroundColor: '#F8F8F8', padding: '10px', borderRadius: '3px', overflow: 'hidden', textAlign: 'center' }}>
                            <h3 style={{ marginTop: '1rem', marginBottom: '0.5rem', fontSize: '1.5rem' }}>Browse by Category</h3>
                            <div style={{ fontSize: '1rem', display: 'inline-block' }}>
                                <div style={{ marginBottom: '0.5rem', display: 'inline-block', verticalAlign: 'middle' }}>
                                    <h6 style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '0.5rem' }}>
                                        <input
                                            type="checkbox"
                                            checked={isSelected}
                                            onChange={handleCheckboxChange}
                                            style={{ verticalAlign: 'middle', marginRight: '4px' }}
                                        />
                                        Single Hardcoded
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>


     

 



                    <div className="col-lg-9">
                        <div className="container">
                            {AccessGetByParams && (
                                Object.entries(groupProductsByType(AccessGetByParams)).map(([type, products]) => (
                                    <div key={type}>
                                        <h3 className="">{type}</h3>
                                        <div className="row">
                                            {products.map((card, index) => (
                                                <div key={index} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                                    <div className="card mb-4" style={{ cursor: "pointer", backgroundColor: "white", border: "1px solid grey", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
                                                        <div className="view overlay">
                                                            <img className="card-img-top" style={{ height: "300px" }} src={getAsset(card && card.imageSubsubCatagory && card.imageSubsubCatagory[0])} alt="Card image cap" />
                                                            <a href="#!">
                                                                <div className="mask rgba-white-slight"></div>
                                                            </a>
                                                        </div>

                                                        <div className="row align-items-center">
                                                            <h5 className="card-title text-center">Name: {card.subsubcatagory}</h5>
                                                            <h5 className="card-title text-center">Price: {card.wholesellerPrice}</h5>
                                                        </div>

                                                        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                                                            <div className="input-group">
                                                                <button className="btn btn-outline-secondary" type="button" onClick={() => decreaseQuantity(card._id)}>-</button>
                                                                <input type="text" className="form-control" value={productQuantities[card._id] || 1} onChange={(e) => handleQuantityChange(card._id, e)} readOnly />
                                                                <button className="btn btn-outline-secondary" type="button" onClick={() => increaseQuantity(card._id)}>+</button>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12 mt-3">
                                                            <button className="btn" style={{ backgroundColor: "black", color: "white", cursor: "pointer" }} onClick={() => {
                                                                handleCardClick(card, productQuantities[card._id]);
                                                                navigate("/Cart");
                                                            }}>Submit</button>
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>



                </div>
            </div>
        </>
    )
}
export default AmPage2;


