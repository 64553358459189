import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css"; // Import CSS file containing styles for the component
import axios from "axios";
import Env from "../Environment/Env";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.png";
import {
  TextField,
  List,
  ListItemButton,
  ListItemText,
  Popper,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Paper, Grid, Card, CardContent } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { FaSearch, FaShoppingCart, FaBars } from "react-icons/fa";




const Main = () => {
  const navigate = useNavigate();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [AccessoriesData, setAccessoriesData] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);

  const [options, setOptions] = useState(["Choose Options"]);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const inputRef = useRef(null);
  const popperRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const getNavBarData = async () => {
    axios.get(`${Env.server}/api/filters/getNavBarDataForTrade`)
      .then((res) => {
        setNewNav(res.data.navData)
        console.log("rana navbar data received",res.data.navData)
        console.log('Navbar data recieved==>>>', res.data);
      })
      .catch((err) => {
        console.log('Error loading navbar data==>>', err);
      })
  }

  useEffect(() => {
    getNavBarData();
    GetAllSubSubCatagory();
    GetAllTypes();
    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const handleClickOutside = (event) => {
    // Close popper if clicked outside of input and popper
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setAnchorEl(null);
    }
  };




  const GetAllTypes = () => {
    axios
      .get(`${Env.server}/api/filters/gettypePermitedToShow`)
      .then((res) => {
        let resp = res.data.allType;
        // Extracting unique types
        // const types = Array.from(new Set(resp.map(item => item.typeName)));
        // console.log("types====", resp)
        const typesArray = resp.map((item) => item.type);

        console.log("typesArray====", typesArray);
        setUniqueTypes(typesArray);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const GetAllSubSubCatagory = () => {
    axios
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
      .then((res) => {
        let resp = res.data.subsubcatagories;
        // Extracting unique types
        // const types = Array.from(new Set(resp.map(item => item.typeName)));
        // console.log("types====", types)
        // setUniqueTypes(types);
        setAccessoriesData(resp);
        const CatagoryArray = res.data.subsubcatagories.map(
          (data) => data.catagory
        );
        const SubCatagoryArray = res.data.subsubcatagories.map(
          (data) => data.subcatagory
        );
        const SubSubCatagoryArray = res.data.subsubcatagories.map(
          (data) => data.subsubcatagory
        );

        // Create a Set from the CatagoryArray
        const uniqueOptionsSet = new Set(CatagoryArray);
        const uniqueOptionsArray = Array.from(uniqueOptionsSet);

        // Create a Set from the CatagoryArray
        const uniqueOptionsSet1 = new Set(SubCatagoryArray);
        const uniqueOptionsArray1 = Array.from(uniqueOptionsSet1);

        // Create a Set from the CatagoryArray
        const uniqueOptionsSet2 = new Set(SubSubCatagoryArray);
        const uniqueOptionsArray2 = Array.from(uniqueOptionsSet2);
        setOptions(uniqueOptionsArray);
        setOptions1(uniqueOptionsArray1);
        setOptions2(uniqueOptionsArray2);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value?.toLowerCase());
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [newNav, setNewNav] = useState()
  const [isSideBar, setIsSideBar] = useState(false)
  const [expandedItems, setExpandedItems] = useState([]);
  const [subMenu, setSubMenu] = useState([]);
  const toggleItem = (index) => {
    setExpandedItems(prev => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    });
  };
  const toggleSubMenu = (index) => {
    setSubMenu(prev => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    })
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-center topnavresponsivetext" style={{ gap: '15px', padding: '10px', backgroundColor: '#F8F6ED' }}>


        <Link to="/">
          <p href="#action1" style={{ marginBottom: '0px' }}>Home</p>
        </Link>
        <Link to='/AllTypes'>
          <p href="#action2" style={{ marginBottom: '0px' }}>Shop</p>
        </Link>
        <Link to="/aboutus">
          <p href="#" disabled style={{ marginBottom: '0px' }}>
            About Us
          </p>
        </Link>
        <Link to="/contactus">
          <p href="#action2" style={{ marginBottom: '0px' }}>Contact Us</p>
        </Link>

      </div>

      <Navbar expand="lg" className="" style={{ borderBottom: '1px solid lightgray', backgroundColor: 'white', overflow: 'hidden' }}>
        <Container fluid >
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Nav className="ml-auto">
              <div style={{ cursor: 'pointer', padding: '10px' }} className="d-block d-md-none"
                onClick={() => {
                  console.log("clicking done")
                  setIsSideBar((prev) => !prev)
                }}
              >
                <FaBars size={30} color="black" />
              </div>
            </Nav>
            <Navbar.Brand href="/">
              <img
                src={Env.picLogo}
                width="130px"
                height="auto"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
          </div>

          <Navbar.Toggle aria-controls="navbarScroll" />
          {/* from here our mobile navbar code  started */}
          <div className={`mobileSidebar ${isSideBar ? 'show' : ''}`}>
              <div className="" style={{ display: 'flex', justifyContent: 'end', padding: '20px' }}>
                <p style={{ fontSize: '25px', color: 'white' }}
                  onClick={() => setIsSideBar(false)}
                >X</p>
              </div>
              <div className="sidebar-content">
                {newNav && newNav.map((item, index) => (
                  <div key={index} className={`item ${expandedItems[index] ? 'expanded' : 'collapsed'}`}>
                    <div className="header" >
                      <h1
                        onClick={() => {
                          setIsSideBar(false)
                          navigate(`/AmPage3/${item.type}`)
                        }}
                      >{item.type}</h1>
                      <p style={{ fontSize: '30px' }} onClick={() => toggleItem(index)}
                      >{expandedItems[index] ? '-' : '+'}
                      </p>
                    </div>
                    <div className={`sub-content ${expandedItems[index] ? 'show' : 'hide'}`}>
                      {item.categories.length > 0 && item.categories.map((data, subIndex) => (
                        <div key={subIndex} className="category">
                          <div className="category-header" onClick={() => toggleSubMenu(subIndex)}>
                            <p
                              onClick={() => {
                                setIsSideBar(false)
                                navigate(`/AmPage4/${item.type}/${data.category}`)
                              }}
                            >{data.category}</p>
                            <p style={{ fontSize: '23px' }}>{subMenu[subIndex] ? '-' : '+'}</p>
                          </div>
                          <div className={`sub-sub-content ${subMenu[subIndex] ? 'show' : 'hide'}`}>
                            { data.subcategories.length > 0 && data.subcategories.map((category, subSubIndex) => (
                              <p key={subSubIndex}
                                onClick={() => {
                                  setIsSideBar(false)
                                  navigate(`/AccessoryType/${category}/${item.type}`)
                                }}
                              >{category}</p>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

            </div>
          <Navbar.Collapse id="navbarScroll" className="secondnavbarresponsive">
            <div className="d-none d-lg-flex justify-content-center align-items-center flex-grow-1" >
              <div className="input-group search-bar-lg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }} >
                <input
                  style={{ border: '1px solid black', fontStyle: 'italic', color: 'darkgray' }}
                  type="text"
                  className="form-control"
                  placeholder="By device, brand or item"
                  value={searchText}
                  onChange={handleInputChange}
                  onClick={handleClick}
                  ref={inputRef}

                />
                <div className="input-group-append">
                  <span className="input-group-text" style={{ border: "1px solid black" }}>
                    <i className="bi bi-search"></i>
                  </span>
                </div>
              </div>
              <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                style={{ width: "400px" }}
                ref={popperRef}
              >
                <Paper
                  style={{
                    maxHeight: "250px",
                    overflowY: "auto",
                    width: "auto",
                  }}
                >
                  <List>
                    {/* Assuming options2, options1, and options are defined */}
                    <ListItemButton disabled>
                      <ListItemText primary="Accessories" />
                    </ListItemButton>
                    {options2
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/AwmPageB/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Models" />
                    </ListItemButton>
                    {options1
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/Model/brand/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Brand" />
                    </ListItemButton>
                    {options
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/BrandPage/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                  </List>
                </Paper>
              </Popper>
            </div>

            {/* Search Bar for Mobile */}
            <div className="d-lg-none flex-grow-1 ml-lg-4">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="By device, brand or item"
                  value={searchText}
                  onChange={handleInputChange}
                  onClick={handleClick}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="bi bi-search"></i>
                  </span>
                </div>
              </div>
              <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                style={{ width: "400px" }}
              >
                <Paper
                  style={{
                    maxHeight: "250px",
                    overflowY: "auto",
                    width: "auto",
                  }}
                >
                  <List>
                    {/* Assuming options2, options1, and options are defined */}
                    <ListItemButton disabled>
                      <ListItemText primary="Accessories" />
                    </ListItemButton>
                    {options2
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/AwmPageB/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Models" />
                    </ListItemButton>
                    {options1
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/Model/brand/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Brand" />
                    </ListItemButton>
                    {options
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/BrandPage/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                  </List>
                </Paper>
              </Popper>
            </div>
            <Form className="d-flex align-items-center m-auto" style={{ position: 'relative' }}>
              {/* <input
          type="text"
          placeholder="Search"
          style={{ paddingRight: '30px' }} 
          className="form-control mr-2"
        />
        <span style={{ position: 'absolute', top: '50%', right: '20px', transform: 'translateY(-50%)' }}>
          <i className="fa fa-search"></i>
        </span> */}

              {/* <div className="d-flex justify-content-center align-items-center flex-grow-1">
              <div className="input-group search-bar-lg" style={{margin: '0px'}}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="By device, brand or item"
                  value={searchText}
                  onChange={handleInputChange}
                  onClick={handleClick}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="bi bi-search"></i>
                  </span>
                </div>
              </div>
              <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                style={{ width: "400px" }}
              >
                <Paper
                  style={{
                    maxHeight: "250px",
                    overflowY: "auto",
                    width: "auto",
                  }}
                >
                  <List>
                    {/* Assuming options2, options1, and options are defined */}
              {/* <ListItemButton disabled>
                      <ListItemText primary="Accessories" />
                    </ListItemButton>
                    {options2
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/AwmPageB/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Models" />
                    </ListItemButton>
                    {options1
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/Model/brand/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Brand" />
                    </ListItemButton>
                    {options
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/BrandPage/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                  </List>
                </Paper>
              </Popper>
            </div>  */}
              <Link to={"/UserDashboad"}>


                <div className='col-7 button-container secondnavbarbuttonresponsive' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: 'auto' }}>
                  <button
                    style={{
                      color: "black",
                      borderRadius: '50px',
                      backgroundColor: '#F8D803',
                      fontSize: "10px",
                      padding: '10px 10px 10px 10px',
                      width: '250px',
                      marginTop: '5px',
                      border: 'none',
                      fontWeight: 'bold',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'auto',
                      marginBottom: '8px',
                      alignItems: 'center'

                    }}
                  >
                    Manage my account
                  </button>
                </div>

              </Link>

            </Form>
            <div className="position-relative mr-2 cart-icon-lg"  >
              <Link to={"/Cart1"} className="nav-link" >
                <i className="bi bi-cart" ></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {(JSON.parse(localStorage.getItem("selectedProducts")) &&
                    JSON.parse(localStorage.getItem("selectedProducts"))
                      .length) ||
                    0}
                  <span className="visually-hidden">items in cart</span>
                </span>
              </Link>
            </div>
            {/* <span style={{ marginLeft: '10px', fontSize: '25px'  }}>
        <i className="fa fa-shopping-cart"></i>
      </span> */}
            {/* <span style={{ marginLeft: '10px', fontSize: '25px'  }}>
        <i className="fa fa-user" ></i>
      </span> */}
            {localStorage.getItem("role") === "admin" ? (
              // If user role is admin
              <>
                <button
                  onClick={() =>
                  (window.location.href =
                    `${Env.dashboard}`)
                  }
                  className="text-center text-black"
                  style={{
                    // backgroundColor: "#F8F9FA",
                    background: 'none',
                    // border: "1px solid #DEE2E6",
                    border: 'none',
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {localStorage.getItem("id") ? (
                    <span style={{ fontSize: '25px' }}>
                      <i className="fa fa-user"></i>
                    </span>
                  ) : (
                    <img
                      src="https://ik.imagekit.io/vhfsx9xkeh/login.png?updatedAt=1713536012173"
                      alt="Login/Signup"
                      style={{ width: '25px', height: '25px' }}
                    />
                  )}
                </button>
              </>
            ) : (
              // If user role is not admin
              <>
                <Link to={"/UserDashboad"}>
                  <button
                    className="text-center text-black"
                    style={{
                      // backgroundColor: "#F8F9FA",
                      background: 'none',
                      // border: "1px solid #DEE2E6",
                      border: 'none',

                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    {localStorage.getItem("id") ? (
                      <span style={{ fontSize: '25px' }}>
                        <i className="fa fa-user"></i>
                      </span>
                    ) : (
                      <img
                        src="https://ik.imagekit.io/vhfsx9xkeh/login.png?updatedAt=1713536012173"
                        alt="Login/Signup"
                        style={{ width: '25px', height: '25px' }}
                      />
                    )}
                  </button>
                </Link>
              </>
            )}


          </Navbar.Collapse>
        </Container>
      </Navbar>





    </>
  );
};

export default Main;
