import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import OrderSummary from './OrderSummary';
import ShippingDetails from './ShippingDetails';
import Payment from './Payment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Env from './../Environment/Env';


const stripePromise = loadStripe(Env.publicKey);
const productList = [
  { id: 1, name: 'Product 1', price: 10, quantity: 1 },
  { id: 2, name: 'Product 2', price: 20, quantity: 2 },
];


function FinalCheckout() {
  const [step, setStep] = useState(1);
  const [products, setProducts] = useState(productList);

  const handleDelete = (id) => {
    setProducts(products.filter(product => product.id !== id));
  };



  return (
    <Container fluid style={{ backgroundColor: 'aliceblue', height: '100vh',padding:'10px'}}>
      <div className="d-flex justify-content-around mb-4" style={{width:'95%',maxWidth:'500px',margin:'3rem auto 0px',alignItems:'center'}} >
        <p 
        style={{
          fontSize:'22px',
          fontWeight:(step === 1 ? 'bold':'normal'),
          cursor:'pointer',
          paddingRight:'3px'
        }} 
        onClick={() => setStep(1)} >Order Summary</p>
        <span style={{fontSize:'24px',display:'flex',alignItems:'center',justifyContent:'center',padding:'2px 5px'}} ><p>{' > '}</p></span>
        <p 
        style={{
          fontSize:'22px',
          fontWeight:(step === 2 ? 'bold':'normal'),
          cursor:'pointer',
          paddingRight:'3px'
        }} 
        onClick={() => setStep(2)}>Shipping Details</p>
        <span style={{fontSize:'24px',display:'flex',alignItems:'center',justifyContent:'center',padding:'2px 5px'}} ><p>{' > '}</p></span>
        <p 
        style={{
          fontSize:'22px',
          fontWeight:(step === 3 ? 'bold':'normal'),
          cursor:'pointer',
          paddingRight:'3px'
        }} 
        onClick={() => setStep(3)}>Payment</p>
      </div>
      <Row className="justify-content-center  h-100">
        <Col md={6} >
          <div className="bg-white p-4 rounded shadow-sm">
            {step === 1 && <OrderSummary  setStep={setStep} />}
            {step === 2 && <ShippingDetails setStep={setStep} />}
            {step === 3 && 
            <Elements stripe={stripePromise} >
              <Payment setStep={setStep} />
            </Elements>
            }
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default FinalCheckout;
