// EditableCard.js

import React, { useState } from 'react';

const EditableCard = () => {
  const [heading, setHeading] = useState('Your Heading');
  const [field1, setField1] = useState('Shahan');
  const [field2, setField2] = useState('Anwar');
  const [additionalField, setAdditionalField] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // Implement logic to save changes (e.g., update state, make API calls)
  };

  return (
    <div style={{ position: 'relative', width: '100%', maxWidth: '720px', padding: '20px', borderRadius: '16px', margin: '20px auto', backgroundColor: 'white', border: '1px solid #ccc' }}>
      <div style={{ marginBottom: '20px' }}>
        <h3>Personal Info</h3>
      </div>
      <div style={{ marginBottom: '20px', display: 'flex' }}>
        <div style={{ flex: '1', marginRight: '10px' }}>
          <label style={{ marginBottom: '5px', }}>First Name</label>
          
            <div style={{ padding: '8px', borderRadius: '5px', backgroundColor: 'white' }}>{localStorage.getItem("firstname")}</div>
          
        </div>
        <div style={{ flex: '1' }}>
          <label style={{ marginBottom: '5px' }}>Last Name</label>
           
          <div style={{ padding: '8px', borderRadius: '5px', backgroundColor: 'white' }}>{localStorage.getItem("lastname")}</div>
           
        </div>
      </div>
      
    
    </div>
  );
};

export default EditableCard;
