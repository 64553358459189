import React , {useState, useEffect} from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import './Order.css';
import { useParams } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PubKey } from "../PubKey/PubKey";
import { loadStripe } from "@stripe/stripe-js";

import { faTrash } from '@fortawesome/free-solid-svg-icons';
const Order = () => {  
  let navigate = useNavigate();
  const [ClickData, setClickData] = useState([]);
  const [productData, setProductData] = useState([]); // State hook to hold product data from localStorage
  const [TotalPrice, setTotalPrice] = useState(0); // State hook to hold product data from localStorage
  const [Message, setMessage] = useState(""); // State hook to hold product data from localStorage
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedOption, setSelectedOption] = useState('regular');


  useEffect(() => {
    const storedProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];

    // Store items into the hook
    setSelectedProducts(storedProducts);
    const parsedData = storedProducts;
    console.log("Abcd====", parsedData);
    // if (storedProducts) {
    //   setProductData(JSON.parse(storedProducts)); // Parse the data and update the state

    //   let totalPrice = parsedData.reduce((total, item) => {
    //     return total + (item.wholesellerPrice * item.quantity);
    //   }, 0);

    //   console.log("Total Price:", totalPrice);
    //   setTotalPrice(totalPrice)
    // }

  }, [])

  const removeItem = (id) => {
    const updatedProductData = productData.filter(item => item.data._id !== id);
    setProductData(updatedProductData);
    localStorage.setItem("productData", JSON.stringify(updatedProductData));
  };


  const SubmitOrder=async ()=>{ 

    const stripe = await loadStripe(PubKey); 
    console.log("abc====", selectedProducts)
 let deliveryCharge = 0;

 if(selectedOption === "regular"){
   if (TotalPrice >= 15){
     deliveryCharge = 0
    }else{
      deliveryCharge = 399
    }
 }else if(selectedOption === "express"){
   if (TotalPrice >= 15) {
     deliveryCharge = 0
   } else {
     deliveryCharge = 599
   }
 }


    console.log("deliverycharges:====", deliveryCharge )

    axios
      .post(`${Env.server}/api/customer/order-online`, { data: selectedProducts, totalPrice: TotalPrice , deliverycharges: deliveryCharge   })
      .then((res) => {
        console.log("abc", res.data);
        const result = stripe.redirectToCheckout({
          sessionId: res.data.sessionId,
        });
        if (result.error) throw new Error("Stripe Payment Not Successful!");
      })
      .catch((err) => console.log(err));
  }

  React.useEffect(() => {
    getDataByIDLocalStorage();
  }, [])

  // Function to retrieve data by ID from localStorage
  const getDataByIDLocalStorage = () => {
    let data = localStorage.getItem("selectedProducts");
    const parsedData = JSON.parse(data);
    console.log("Abcd====", JSON.parse(data));
    if (data) {
      setProductData(JSON.parse(data)); // Parse the data and update the state
      
      let totalPrice = parsedData.reduce((total, item) => {
        return total + (item.wholesellerPrice * item.quantity);
      }, 0);

      console.log("Total Price:", totalPrice);
      setTotalPrice(totalPrice)
    }
  }


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(event.target.value);
  };

  const handleSubmit=()=>{
let login = localStorage.getItem("role");
    if (login){
      console.log("abc=====>>>>", productData);
      let id = localStorage.getItem("id");
      let role = localStorage.getItem("role");
      let orderplaceData = {
        clientid : id,
        role :  "forsale",
        name: localStorage.getItem("firstname") + " " + localStorage.getItem("lastname"),
        product: productData,
        totalprice: TotalPrice
      }
      console.log("abcbbecbeb======", orderplaceData);
 
      axios.post(`${Env.server}/api/order/neworder`, orderplaceData)
.then((res) => {
  console.log("abc===>>>", res.data)
  console.log("abc===>>>", res.data.message)
  if (res.data.message === "success" ){ 
    localStorage.removeItem("productData");
    setProductData([]);
    setTotalPrice(0);
    setMessage("Product Sell request sent Successfully. Please Check your email"); 
    const timer = setTimeout(() => {
      setMessage("");
      navigate("/");
    }, 5000); 
    return () => clearTimeout(timer);
  }
})
.catch((err) => {
console.log("err======", err); 
})
 
    }else{
      alert("Please Login First")
    }
 
  }

  const handleDelete = (index) => {
    // Create a copy of selectedProducts array
    const updatedProducts = [...selectedProducts];
    // Remove the product at the specified index
    updatedProducts.splice(index, 1);
    // Update the state with the modified array
    setSelectedProducts(updatedProducts);
    // Update the localStorage with the modified array
    localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
    window.location.reload();
  };
  return (
    <>
      <Navbar />
      <div className="mt-5" >
        <h1 className="text-black p-5 text-center" style={{ fontWeight: "bolder" }}>Selling items</h1>
        <div className=" row d-flex" > 

          <div className="col-md-5 ml-5">

            <div className="col-lg-9">
              <div className="container">
                <div className="row">
                  {selectedProducts.map((card, index) => (
                    <div style={{ cursor: "pointer" }} key={index}>
                      <div className="card mb-4 align-items-center pt-4" style={{ backgroundColor: "white", cursor: 'pointer' , boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
                        <div className="view overlay">
                          <img className="card-img-top" style={{height: "160px", width: "138px", border: "none"}} src={getAsset(card && card.imageSubsubCatagory && card.imageSubsubCatagory[0])} alt="Card image cap" />
                          <a href="#!">
                            <div className="mask rgba-white-slight"></div>
                          </a>
                        </div>
                        <div className="card-body">
                          <h4 className="card-title text-center">{card.subsubcatagory}</h4>
                          <h5 className="card-title text-center"><span className="fw-bold">Price:</span> {card.wholesellerPrice}</h5>
                          <h5 className="card-title text-center"><span className="fw-bold">Quantity:</span>{card.quantity}</h5>
                          <div className="text-center">
                            <button onClick={() => handleDelete(index)} className="btn btn-danger" style={{backgroundColor: '#DB241B'}}>Remove</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <Link to="/">
            <button type="button" class="btn btn-light border rounded-pill  my-3 ml-2 w-50 custom-button text-white btn-lg" onclick="changeColor()" style={{ background: '#DB241B' }} ><i class="bi bi-plus"></i> Add another item</button>
                </Link>


          </div>




          <div className="col-md-4 ">
            <div class="order-card" style={{ font: "10rem" , backgroundColor:"#F8F8F8" , borderRadius:"10px"  }}>
              <div class="card-body tt " style={{ background: "#F8F8F8" }}>
                <h5 class="fs-3 text-center text-black   fw-bolder p-2">Order Summary</h5>  
                <div  >
                  <input
                    className="ml-3 text-black"
                    type="radio"
                    id="regular"
                    name="deliveryOption"
                    value="regular"
                    checked={selectedOption === 'regular'}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="regular" className="ml-3 text-black">Regular</label>

                  <input
                    className="ml-3 text-black"
                    type="radio"
                    id="express"
                    name="deliveryOption"
                    value="express"
                    checked={selectedOption === 'express'}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="express" className="ml-3 text-black">Express</label>
                </div>
                <br/>
                <h6 class="ml-3 text-black">TOTAL <span className="ml-5">£{TotalPrice}</span></h6>
               
                {selectedOption && selectedOption === 'express' && (
                  <>
                    {TotalPrice >= 15 ? (
                      <>
                        <h6 class="ml-3 text-black">DELIVERY CHARGES <span className="ml-5">£0.00</span></h6>
                        <h6 className="ml-3 text-black">TOTAL CASH VALUE <span className="ml-5">£{TotalPrice + 0.00}</span></h6>
                      </>
                    ) : (
                      <>
                        <h6 class="ml-3 text-black">DELIVERY CHARGES <span className="ml-5">£5.99</span></h6>
                        <h6 className="ml-3 text-black">TOTAL CASH VALUE <span className="ml-5">£{TotalPrice + 5.99}</span></h6>
                      </>
                    )} 

<br/>
                    <span style={{ lineHeight: "1px" }}>
                      <p class="ml-3 text-black fw-bold">Deliver in Next Day</p>
                      <p className="ml-3 mb-1 text-black fw-bold">Delivery Free upto £15</p>
                    </span>

                  </>
                )}
                {selectedOption === 'regular' && (
                  <>
                    {TotalPrice >= 15 ? (
                      <>
                        <h6 class="ml-3 text-black">DELIVERY CHARGES <span className="ml-5">£0.00</span></h6>
                        <h6 className="ml-3 text-black">TOTAL CASH VALUE <span className="ml-5">£{TotalPrice + 0.00}</span></h6>
                      </>
                    ) : (
                      <>
                        <h6 class="ml-3 text-black">DELIVERY CHARGES <span className="ml-5">£3.99</span></h6>
                        <h6 className="ml-3 text-black">TOTAL CASH VALUE <span className="ml-5">£{TotalPrice + 3.99}</span></h6>
                      </>
                    )}


<br/> 
                    <span style={{ lineHeight: "1px" }}>
                      <p class="ml-3 text-black fw-bold">Delivery in 1-3 days</p> 
                      <p className="ml-3 mb-1 text-black fw-bold">Delivery Free upto £15</p>
                    </span>
                  </>
                )}
                  {/* <p class="ml-3 text-black fw-bold">Delivery Charges: £3.99</p> */}

               {/* <Link to={'https://buy.stripe.cm/test_5kA3cx4secxS6f67ss'}> */}
                <button type="button" onClick={SubmitOrder} class="btn btn-light border rounded-pill  my-3 ml-5 w-75 p-1 fs-5 custom-button text-white btn-lg"  style={{ background: "#DB241B" }} >Buy</button>
                {/* </Link> */}
              </div>
            </div>
          <h5 style={{ marginTop:"10px" ,width:"400px", fontWeight: "bolder", color:"#000000"}}> <center> {Message}</center></h5>
          </div> 
        </div>
      </div ><br /><br />
      <Footer />
    </>
  )
}
export default Order;