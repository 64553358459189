import React, { useEffect, useState, useRef } from "react";
// import "./Banar.css"
import Navbar1 from "../Navbar/Navbar1";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import { useParams } from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faComment } from '@fortawesome/free-solid-svg-icons';
import { MdCancel } from 'react-icons/md'; // Import the React Icons

import { Paper, Grid, Card, CardContent } from '@mui/material'
import { TextField, List, ListItemButton, ListItemText, Popper } from '@mui/material';
const AmPage4 = () => {
    const { type, brand } = useParams();
    const navigate = useNavigate();


    const [options, setOptions] = useState(['Choose Options']);


    const [isSelected, setIsSelected] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [ModelGetByParams, setModelGetByParams] = useState([]);
    const [AccessGetByParams, setAccessGetByParams] = useState([]);
    const [allProductData1, setAllProductData1] = useState([]);
    const [allProductData, setAllProductData] = useState([]);
    const [FilteredData, setFilteredData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const inputRef = useRef(null);
    const popperRef = useRef(null);
    const [data, setdata] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleCheckboxChange = () => {
        setIsSelected(!isSelected);
    };



    const [MinRange, setMinRange] = useState(0);
    const [MaxRange, setMaxRange] = useState(6000);

    const [selectedRange, setSelectedRange] = useState(20);
    const [selectedColor, setSelectedColor] = useState("");
    const [cartItemIndexes,setCartItemIndexes] = useState([]);


    const [selectedProducts, setSelectedProducts] = useState([]);
    const [ShowPro, setShowPro] = useState(true);
    const [again,setAgain]=useState(false);
    // Load data from localStorage on component mount
    useEffect(() => {
        const dataFromLocalStorage = JSON.parse(localStorage.getItem("selectedProducts"));
        if (dataFromLocalStorage) {
            setCartItemIndexes(dataFromLocalStorage.map(prod=>prod._id));
            setSelectedProducts(dataFromLocalStorage);
        }
    }, [ShowPro,again]);




    const [productQuantity, setProductQuantity] = useState({});

    // Function to handle incrementing quantity
    const incrementQuantity = (productId) => {
        setProductQuantity(prevQuantity => {
            const newQuantity = (prevQuantity[productId] || 0) + 1;
            console.log(`Quantity for product ${productId} incremented to ${newQuantity}`);
            return {
                ...prevQuantity,
                [productId]: newQuantity
            };
        });
    };

    // Function to handle decrementing quantity
    const decrementQuantity = (productId) => {
        setProductQuantity(prevQuantity => {
            const newQuantity = Math.max((prevQuantity[productId] || 0) - 1, 1); // Ensure quantity does not go below 1
            console.log(`Quantity for product ${productId} decremented to ${newQuantity}`);
            return {
                ...prevQuantity,
                [productId]: newQuantity
            };
        });
    };

    // Function to calculate total price for a product
    const calculateTotalPrice = (product) => {
        const quantity = productQuantity[product._id] || 1;
        return (product.wholesellerPrice * quantity).toFixed(2);
    };
    useEffect(() => {
        getAllByNmeAcc();
        document.addEventListener("click", handleClickOutside);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [type, brand])


    const handleClickOutside = (event) => {
        // Close popper if clicked outside of input and popper
        if (
            inputRef.current &&
            !inputRef.current.contains(event.target) &&
            popperRef.current &&
            !popperRef.current.contains(event.target)
        ) {
            setAnchorEl(null);
        }
    };

    useEffect(() => {
        applyFilterProper();
    }, [selectedFilters, selectedRange, MinRange]);



    const handleRangeChange = (event) => {
        const range = parseInt(event.target.value);
        setSelectedRange(range);
    };

    const applyFilterProper = async () => {
        let url = `${Env.server}/api/subsubcatagory/GetByFilterForTradeByTypeAndBrand?min=${MinRange}&max=${selectedRange}&typeName=${encodeURIComponent(type)}&catagory=${encodeURIComponent(brand)}`;
        console.log('aaaaaaaaaaaaaa', url);
        if (selectedFilters.length === 0 && selectedRange === MaxRange) {
            getAllByNmeAcc();
            return;
        }
        console.log('aaaaaaaaaaaaaaaaaaaa', selectedFilters);
        selectedFilters.map(filter => {
            if (filter.type === "Manufacturer") {
                url += `&manufacturerName=${filter.var}`
            }
            if (filter.type === "Color") {
                url += `&color=${filter.var}`
            }
            if (filter.type === "Variant") {
                url += `&variantName=${filter.var}`
            }
        })
        await axios.get(url)
            .then((res) => {
                console.log('aaaaaaaaaaaaaaaaaaa', res.data.data);
                setAllProductData(res.data.data);
            })
            .catch((err) => console.log('aaaaaaaaaaaaaaaaaaaa', err));
    }
    let filteredItems = allProductData && allProductData.filter(item => parseInt(item.wholesellerPrice) <= selectedRange);


    const handleFilter = () => {
        localStorage.setItem("priceFilter", MaxRange);
    }

    // useEffect(()=>{
    // console.log("muzzamal====", filteredItems)
    // setAllProductData(filteredItems)
    // }, [filteredItems])



    function groupProductsByType(products) {
        const groupedProducts = {};
        products.forEach(product => {
            if (!groupedProducts[product.typeName]) {
                groupedProducts[product.typeName] = [];
            }
            groupedProducts[product.typeName].push(product);
        });
        return groupedProducts;
    }


    const getAllByNmeAcc = () => {
        axios.get(`${Env.server}/api/subsubcatagory/getAllByTypeByBrand/${type}/${brand}`)
            .then((res) => {
                console.log("abc====>>>", res.data.subsubcatagories);
                setModelGetByParams(res.data.subsubcatagories);
                setAccessGetByParams(res.data.subsubcatagories);
                setdata(res.data.subsubcatagories)
                console.log("rana", res.data.subsubcatagories);
                const prodArr = res.data.subsubcatagories;
                console.log('fffff', prodArr);
                const maxNum = Math.max(...prodArr.map(product => product.wholesellerPrice));
                const minNum = Math.min(...prodArr.map(product => product.wholesellerPrice));
                // setMaxRange(maxNum);
                setMinRange(minNum === Infinity ? 0 : minNum);
                localStorage.setItem("minRange", MinRange);
                setMaxRange(maxNum === -Infinity ? 0 : maxNum);
                if (!localStorage.getItem("minRange")) {
                    console.log('I just set the value to be', MinRange);
                } else {
                    setMinRange(localStorage.getItem("minRange"));
                }
                setSelectedRange(maxNum === -Infinity ? 0 : maxNum);


                if (localStorage.getItem("filterselected") === 0) {
                    console.log("");
                } else {
                    const savedFilters = localStorage.getItem('selectedFilters');
                    if (savedFilters) {
                        setSelectedFilters(JSON.parse(savedFilters));
                    }
                }

                console.log("res.data.subsubcatagories====", res.data.subsubcatagories)
                setAllProductData(res.data.subsubcatagories)
                setAllProductData1(res.data.subsubcatagories)
                let dataAll = res.data.subsubcatagories

                console.log('Selected Range ==>', selectedRange);
                let filteredItems = dataAll && dataAll.filter(item => parseInt(item.wholesellerPrice) <= selectedRange && parseInt(item.wholesellerPrice) >= MinRange);
                console.log("muzzamal====", filteredItems)
                setAllProductData(filteredItems)
                setAllProductData1(filteredItems)

                let data = res.data.subsubcatagories;
                // console.log("hhhhhhhhhhhhhhhh======", data);
                const combinedFilters = {};

                data.forEach((product) => {
                    if (product.filter) {
                        product.filter.forEach((filterCategory) => {
                            Object.entries(filterCategory).forEach(([key, values]) => {
                                if (values.length === 0) return; // Skip if no data

                                if (!combinedFilters[key]) {
                                    combinedFilters[key] = [];
                                }
                                values.forEach((value) => {
                                    let existingFilter = combinedFilters[key].find(
                                        (existing) => existing.var === value
                                    );
                                    if (!existingFilter) {
                                        combinedFilters[key].push({ var: value, id: [product._id] });
                                    } else {
                                        existingFilter.id.push(product._id);
                                    }
                                });
                            });
                        });
                    }
                });

                console.log("Combined filters:", combinedFilters);
                setFilteredData(combinedFilters);


                const subCatagoryArray = res.data.subsubcatagories.map(data => data.subcatagory);
                setOptions(prevOptions => {
                    const mergedOptions = [...prevOptions, ...subCatagoryArray];
                    const uniqueOptions = Array.from(new Set(mergedOptions));
                    return uniqueOptions;
                });

            })
            .catch((err) => {
                console.log("err====", err)
            })
    }


    const uniqueSubcategories = new Set(ModelGetByParams.map(card => card.subcatagory));

    // Convert Set back to an array
    const uniqueSubcategoriesArray = [...uniqueSubcategories];


    const handleCardClick = (selectedProduct) => {
        setShowPro(!ShowPro);
        let quantity = productQuantity[selectedProduct._id] !== undefined ? productQuantity[selectedProduct._id] : 1;

        let products = JSON.parse(localStorage.getItem('selectedProducts')) || [];

        // Check if the selected product already exists in the array
        const existingProductIndex = products.findIndex(product => product._id === selectedProduct._id);

        if (existingProductIndex !== -1) {
            // If the product already exists, update it including quantity
            products[existingProductIndex].quantity = quantity;
            products[existingProductIndex].selectedColor = selectedColor;
            setSelectedColor("");
        } else {
            // If the product does not exist, add it to the array including quantity
            selectedProduct.quantity = quantity;
            selectedProduct.selectedColor = selectedColor;
            setSelectedColor("");
            products.push(selectedProduct);
        }

        // Store the updated products array in local storage
        localStorage.setItem('selectedProducts', JSON.stringify(products));
    }











    // useEffect(() => {
    //     // Retrieve saved selected filters from localStorage when the component mounts
    //     const savedFilters = localStorage.getItem('selectedFilters');
    //     if (savedFilters) {
    //         setSelectedFilters(JSON.parse(savedFilters));
    //     }
    // }, []);

    // useEffect(() => {
    //     let getdatafiltercounting = localStorage.getItem("filterselected");
    //     // alert(selectedFilters.length + "    " + getdatafiltercounting);  
    //     if (!allProductData) {
    //         // console.log("selectedFilters====tt", selectedFilters); 
    //     } else if (selectedFilters.length > 0) {
    //         const uniqueProducts = []; // Array to store unique products

    //         selectedFilters.forEach((filter) => {
    //             const uniqueIds = new Set(); // Create a Set to store unique IDs
    //             const filterid = filter.id;

    //             filterid.forEach((idfilter) => {
    //                 const id = idfilter[0]; // Extract the ID 
    //                 if (!uniqueIds.has(id)) { // Check if the ID is not already in the Set 
    //                     const product = allProductData.find((product) => product._id === id);
    //                     if (product) { // Check if product exists
    //                         uniqueProducts.push(product); // Push the unique product to the array
    //                     }
    //                 }
    //             });
    //         });
    //         const uniqueSet = new Set(uniqueProducts);

    //         // Convert the Set back to an array if needed
    //         const uniqueArray = Array.from(uniqueSet);
    //         if (uniqueArray) {
    //             setAllProductData(uniqueArray);
    //         }
    //     }

    // }, [selectedFilters]);



    const handleCheckboxClick = (filterVar, productId, filterName) => {
        setSelectedFilters(prevFilters => {
            const selectedIndex = prevFilters.findIndex(filter => filter.var === filterVar);

            if (selectedIndex === -1) {
                const newFilters = [...prevFilters, { var: filterVar, id: [productId], type: filterName }];
                console.log('Here is the data vvv=>>', newFilters);
                return newFilters;
            } else {
                const newFilters = prevFilters.filter((_, index) => index !== selectedIndex);
                return newFilters;
            }
        });
    };



    useEffect(() => {
        // Log the IDs of all selected filters after each state update
        const selectedIds = selectedFilters.flatMap(filter => filter.id);
        console.log("Selected IDs:", selectedIds);
    }, [selectedFilters]);





















    const handleInputChange = (event) => {
        setSearchText(event.target.value?.toLowerCase());
        setAnchorEl(event.currentTarget);
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };










    const [productQuantities, setProductQuantities] = useState({});


    const increaseQuantity = (productId) => {
        setProductQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 0) + 1
        }));
    };

    // Function to handle decreasing the quantity for a specific product
    const decreaseQuantity = (productId) => {
        if (productQuantities[productId] && productQuantities[productId] > 0) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: prevQuantities[productId] - 1
            }));
        }
    };

    // Function to handle changing the quantity directly for a specific product
    const handleQuantityChange = (productId, event) => {
        const value = parseInt(event.target.value);
        if (!isNaN(value) && value >= 1) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: value
            }));
        }
    };



    //    function changeQty(color){
    //     if(color.quantity){

    //     }
    //    }


    const handleRemoveProduct = (index) => {
        const updatedSelectedProducts = [...selectedProducts];
        updatedSelectedProducts.splice(index, 1);
        setSelectedProducts(updatedSelectedProducts);
        // Optionally, update localStorage if needed
        localStorage.setItem('selectedProducts', JSON.stringify(updatedSelectedProducts));
    };
    const [stockQty, setStockQty] = useState(0);
    const [changeIndex, setChangeIndex] = useState('');
    const [isStock, setIStock] = useState(true)
    const handleSelectChange = (index, e, card) => {
        console.log('rana', card.color)
        const color = card.color
        const selectedValue = e.target.value;
        console.log('rana2', selectedValue)
        setChangeIndex(index);
        setSelectedColor(selectedValue);
        if (selectedValue === '') {
            console.log('rana i am emty', card.qty)
            setStockQty(card.qty)
            setIStock(true)
        }
        else {
            const selectedColorObject = color.find(color => color.name === selectedValue);
            if (selectedColorObject) {
                setStockQty(selectedColorObject.quantity ? selectedColorObject.quantity : 0);
                if (!selectedColorObject.quantity) {
                    setIStock(false);
                }
                else {
                    setIStock(true);
                }
            } else {
                setStockQty(null);
            }
        }
    };



    return (
        <>

            <Navbar1 />

            <div className="container-fluid">
                <h3 className="text-center mt-3" style={{ fontFamily: 'poppins', fontWeight: 'bold' }}>Search Model</h3>

                <div className="container ">
                    <div className="row">
                        <div className="col">
                            <div className="row align-items-center" style={{ backgroundColor: '#404040' }}>
                                <div className="col-lg-9">

                                    <div className="input-group mb-3 " style={{}}>
                                        <p style={{ fontSize: '1.5vw', color: 'white' }}>Enter the name of your Samsung Galaxy</p>
                                        <div style={{ position: 'relative', display: 'inline-block', width: '900px', backgroundColor: 'white' }}>
                                            <TextField
                                                id="search"
                                                label="Search..."
                                                variant="outlined"
                                                value={searchText}
                                                onChange={handleInputChange}
                                                onClick={handleClick}
                                                sx={{ width: '100%', zIndex: '0' }} // Set the width of the TextField
                                                ref={inputRef}
                                                InputLabelProps={{
                                                    style: {
                                                        fontStyle: 'italic', // Set label to italic
                                                        fontSize: '14px', // Set label font size


                                                    }
                                                }}

                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <SearchIcon style={{ margin: '0' }} />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} style={{ width: '400px' }} ref={popperRef}>
                                                <Paper style={{
                                                    maxHeight: "200px",
                                                    overflowY: "auto",
                                                    width: "100%",

                                                }}>
                                                    <List>
                                                        {options
                                                            .filter(option => option?.toLowerCase().includes(searchText))
                                                            .map((option, index) => (
                                                                <Link to={`/AmPage/${option}/${type}`}>
                                                                    <ListItemButton key={index} onClick={() => setSearchText(option)}>
                                                                        <ListItemText primary={option} />
                                                                    </ListItemButton>
                                                                </Link>
                                                            ))}
                                                    </List>
                                                </Paper>
                                            </Popper>
                                        </div>
                                        {/* <button className="btn btn-outline-primary" type="button">
                    <i className="bi bi-search"></i>
                  </button> */}
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                <div className="col-lg-2 pt-3">
                                    <img srcSet="https://ik.imagekit.io/vhfsx9xkeh/3754cc7a79749b4cc20fbc36303377a8.png?updatedAt=1712242424312" style={{ maxWidth: '80%', height: 'auto' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-6">
                            <div className="input-group mb-3">
                                <div style={{ position: 'relative', display: 'inline-block', width: '400px' }}>
                                    <TextField
                                        id="search"
                                        label="Search..."
                                        variant="outlined"
                                        value={searchText}
                                        onChange={handleInputChange}
                                        onClick={handleClick}
                                        sx={{ width: '100%' }} 
                                        InputLabelProps={{
                                            style: {
                                              fontStyle: 'italic', 
                                              fontSize: '14px' 
                                             
                                            }
                                          }}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <SearchIcon style={{margin: '0'}} />
                                              </InputAdornment>
                                            )
                                          }}
                                    />
                                    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} style={{ width: '400px' }}>
                                        <Paper>
                                            <List>
                                                {options
                                                    .filter(option => option?.toLowerCase().includes(searchText))
                                                    .map((option, index) => (
                                                        <Link to={`/AmPage/${option}/${type}`}>
                                                            <ListItemButton key={index} onClick={() => setSearchText(option)}>
                                                                <ListItemText primary={option} />
                                                            </ListItemButton>
                                                        </Link>
                                                    ))}
                                            </List>
                                        </Paper>
                                    </Popper>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="container">
                    <div className="row align-items-center" style={{ cursor: "pointer" }}>
                        {uniqueSubcategoriesArray.map((subcategory, index) => (
                            <div key={index} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <Link to={`/AmPage/${subcategory}/${type}`}>
                                    <div className="card mb-4 bg-white align-items-center pt-4" style={{border: 'none'  }}>
                                        <div className="view overlay">
              
                                            <img className="card-img-top" style={{ height: "110px", width: "110px" }} src={getAsset(ModelGetByParams.find(card => card.subcatagory === subcategory).imageSubsubCatagory[0])} alt="Card image cap" />
                                            <div className="mask rgba-white-slight"></div>
                                        </div>
                                        <div className="card-body">
                                            <h4 className="card-title text-center" style={{fontSize: '13px'}}>{subcategory}</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div> */}
            </div>

            <div className="container-fluid" style={{ width: '100%', overflow: 'hidden' }} >
                <div className="row" style={{ width: '100vw' }} >



                    <div className="col-lg-2" style={{ maxWidth: '200px' }}>
                        <div style={{ backgroundColor: '#F8F6ED', borderRadius: '5px' }} >

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <input
                                        type="range"
                                        min={MinRange}
                                        max={MaxRange}
                                        step="1"
                                        value={selectedRange}
                                        onChange={handleRangeChange}
                                    />
                                </div>
                                <div style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                                    <input type="text" style={{ width: '35%', fontSize: '12px' }} value={MinRange} onChange={(e) => {
                                        setMinRange(e.target.value)
                                        localStorage.setItem("minRange", e.target.value);
                                    }} placeholder="" /> - to -
                                    <input type="text" style={{ width: '35%', fontSize: '12px' }} value={selectedRange} onChange={(e) => setSelectedRange(e.target.value)} placeholder="" />
                                    <button onClick={handleFilter} style={{ width: '50%', borderRadius: "17px", border: "1px solid transparent", backgroundColor: '#DB241B', color: 'white', padding: '5px', margin: '8px auto 0px', display: 'block' }} >Filter</button>
                                </div>
                            </div>

                            <div>
                                {/* Map over the filtered data and create checkboxes */}
                                {FilteredData && Object.entries(FilteredData).map(([filterName, filterValues]) => (
                                    // Check if filterName is not "Variant"
                                    filterName !== "Variant" &&
                                    filterName !== "ProductPrice" &&
                                    <div className="box w-75 my-4">
                                        <h5 className="text-black border-top pt-3">{filterName}</h5>
                                        <div className="p-2">
                                            {/* Map over filter values and create checkboxes */}
                                            {filterValues.map(filter => (
                                                <div key={filter.var} className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={filter.var}
                                                        checked={selectedFilters.some(selectedFilter => selectedFilter.var === filter.var)}
                                                        onClick={() => handleCheckboxClick(filter.var, filter.id, filterName)}
                                                    />
                                                    <label className="form-check-label" style={{ textWrap: 'nowrap', fontSize: '12px' }} htmlFor={filter.var}>
                                                        {filter.var}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>



                    <div className="col-lg-10">
                        <div className="container">

                            {allProductData && (
                                Object.entries(groupProductsByType(allProductData)).map(([type, products]) => (
                                    <div key={type}>
                                        <h3 className="" style={{ fontFamily: 'poppins', fontWeight: 'bold' }}>{type}</h3>
                                        <div className="row">

                                            <div className="col-12 col-lg-9 col-md-9" style={{ padding: "10px", paddingTop: '0px' }}>
                                                <div className="container-fluid " style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div className="row responsive-row" style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', backgroundColor: '#F8F6ED', padding: '5px 10px 5px 10px' }}>
                                                        <div className="col-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>Name</h5>
                                                        </div>
                                                        <div className="col-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>Model</h5>
                                                        </div>
                                                        <div className="col-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>image</h5>
                                                        </div>
                                                        <div className="col-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>color</h5>
                                                        </div>
                                                        <div className="col-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>price</h5>
                                                        </div>
                                                        <div className="col-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>Qty</h5>
                                                        </div>

                                                        <div className="col-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <h5 className='responsive-font' style={{ fontWeight: 'bold' }}>Stock</h5>
                                                        </div>

                                                        <div className="col-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <h5 className='responsive-font' style={{ fontWeight: 'bold' }}>Add to cart</h5>
                                                        </div>
                                                    </div>














                                                    {/* Data */}

                                                    {products.map((card, index) => (
                                                        <>
                                                            <div key={index} className="row responsive-row" style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', padding: '5px 10px 5px 10px' }}>
                                                                <div className='col-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <h5 className='responsive-font' style={{ fontSize: '14px' }} >
                                                                        {card && card.subsubcatagory}
                                                                    </h5>
                                                                </div>
                                                                <div className='col-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <h5 className='responsive-font' style={{ fontSize: '14px' }} >{card && card.subcatagory}</h5>
                                                                </div>
                                                                <div className='col-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <img className="card-img-top" style={{ height: "80px", width: "80px", border: "none" }} src={getAsset(card && card.imageSubsubCatagory && card.imageSubsubCatagory[0])} alt="Card image cap" />
                                                                </div>
                                                                <div className='col-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                                                    {card && card.color && card.color.length === 1 ? (
                                                                        <>
                                                                            <h5
                                                                                style={{ fontSize: "16px" }}
                                                                                className="card-title"
                                                                            >
                                                                                {card && card.color && card.color[0].name || "N/A"}
                                                                            </h5>
                                                                        </>
                                                                    ) : card && card.color && card.color.length > 1 ? (
                                                                        <select
                                                                            className="form-select"
                                                                            style={{ fontSize: '13px', width: '88%' }}
                                                                            onChange={(e) => handleSelectChange(index, e, card)}
                                                                            value={index !== changeIndex ? '' : selectedColor}
                                                                        >
                                                                            <option value="">Select Color</option>
                                                                            {card && card.color && card.color.map((color, index) => (
                                                                                <option key={index} value={color.name}>
                                                                                    {color.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    ) : (
                                                                        <>
                                                                            <h5
                                                                                style={{ fontSize: "15px" }}
                                                                                className="card-title"
                                                                            >
                                                                                N/A
                                                                            </h5>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {/* ===== WholeSale Price ===== */}
                                                                <div className='col-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <h5 className='responsive-font-product' style={{ fontSize: '14px' }} >£{card && card.wholesellerPrice}</h5>
                                                                </div>

                                                                <div className='col-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <button style={{ border: 'none', background: 'none', width: '14px', fontWeight: 'bold' }} onClick={() => decrementQuantity(card._id)}>-</button>
                                                                    <span>&nbsp;&nbsp;{productQuantity[card._id] || 1}&nbsp;&nbsp;</span>
                                                                    <button style={{ border: 'none', background: 'none', width: '14px', fontWeight: 'bold' }} onClick={() => incrementQuantity(card._id)}>+</button>
                                                                </div>
                                                                {/* <div className='col-2' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <h5 className='responsive-font-product'>
                £{calculateTotalPrice(card)}
            </h5>
        </div> */}

                                                                {/* ===== Stock ===== */}
                                                                <div className='col-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    {card && card.qty > 0 ? (<h5 className='responsive-font-product' style={{ color: '#53C304', fontSize: '14px' }}>{
                                                                        index === changeIndex ? stockQty : card.qty
                                                                    }</h5>) :
                                                                        (<h5 className='responsive-font-product' style={{ color: 'red', fontSize: '14px' }}>NA</h5>)}
                                                                </div>

                                                                {/* ===== add to cart ===== */}
                                                                <div className='col-2 button-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <button
                                                                        onClick={index === changeIndex && !isStock ? undefined : () => handleCardClick(card)}
                                                                        style={{
                                                                            color: index === changeIndex && !isStock ? 'red' : 'black',
                                                                            borderRadius: '50px',
                                                                            backgroundColor: '#F8D803',
                                                                            fontSize: "10px",
                                                                            padding: '13px 5px',
                                                                            width: '70%',
                                                                            marginTop: '5px',
                                                                            border: 'none',
                                                                            fontWeight: 'bold',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            margin: 'auto',
                                                                            marginBottom: '8px',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        {
                                                                            index === changeIndex && !isStock ?
                                                                            "Out of Stock"
                                                                            :
                                                                            ( cartItemIndexes.includes(card._id)  ?
                                                                            "Update Cart"
                                                                            :
                                                                            "Add to Cart")
                                                                        }
                                                                    </button>
                                                                </div>
                                                                <div className="row responsive-row" style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', padding: '5px 10px 5px 10px' }}>
                                                                    <div className='col-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                                                        <div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                        </>
                                                    ))}


                                                    {/* {products.map((card, index) => (
                                                
                                            ))} */}








                                                </div>
                                            </div>

                                            {/* WHOLESALE PAYMENT SECTION */}
                                            <div className="col-12 col-lg-3 col-md-3  " style={{ padding: "0px", marginTop: '0px' }}>
                                                <div style={{ borderRadius: '20px', paddingTop: '10px', paddingRight: '10px', fontSize: '25px', paddingLeft: "10px", border: "2px solid lightgray", backgroundColor: '#F8F6ED' }}>
                                                    <h5 className="text-center" style={{ color: '#000000', fontWeight: 'bold', marginBottom: '7px' }}>Your order summary</h5>
                                                    <div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p className="col-3" style={{ fontWeight: '600', marginBottom: '0', fontSize: '14px' }}>Product</p>
                                                            <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '14px', fontWeight: "bolder" }}>Price</p>
                                                            <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '14px', fontWeight: "bolder" }}>Qty</p>
                                                            <p className="col-3" style={{ fontWeight: '500', marginBottom: '0', fontSize: '14px', fontWeight: "bolder" }}>T.Price</p>
                                                            <p className="col-2 hidden-element" style={{ fontWeight: '500', marginBottom: '0', fontSize: '7px', fontWeight: "bolder", visibility: 'hidden' }}>Price</p>

                                                        </div>
                                                        {selectedProducts.map((item, index) => (
                                                            <>

                                                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <p className="col-3" style={{ fontWeight: '600', marginBottom: '0', fontSize: '12px' }}>
                                                                        {item?.subsubcatagory}
                                                                        {item.selectedColor && <span style={{ fontWeight: 'normal', borderRadius: '3px', background: '#fff' }} >{` (${item.selectedColor})`}</span>}
                                                                    </p>
                                                                    <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '12px' }}>£{item.wholesellerPrice}</p>
                                                                    <p className="col-2" style={{ fontWeight: '600', marginBottom: '0', fontSize: '12px' }}>{item?.quantity}</p>
                                                                    <p className="col-3" style={{ fontWeight: '500', marginBottom: '0', fontSize: '12px' }}>£{(item.wholesellerPrice * item?.quantity).toFixed(2)}</p>
                                                                    <MdCancel className="col-2" onClick={() => {
                                                                        setAgain((prev)=>!prev)
                                                                        handleRemoveProduct(index)
                                                                        }} style={{ fontSize: '30px', color: 'red', cursor: 'pointer', margin: 'auto', minWidth: '50px' }} />
                                                                </div>
                                                                <hr />
                                                            </>
                                                        ))}



                                                        <br />

                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p style={{ fontWeight: '600', marginBottom: '0', fontSize: '13px' }}>Total Price</p>
                                                            <p style={{ fontWeight: '500', marginBottom: '0', fontSize: '13px' }}>
                                                                £{selectedProducts?.reduce((total, item) => total + parseFloat(item?.wholesellerPrice * item?.quantity), 0).toFixed(2)}
                                                            </p>
                                                        </div>
                                                        <br />

                                                        <Link to="/Cart1">
                                                            <button
                                                                style={{
                                                                    color: "black",
                                                                    borderRadius: '50px',
                                                                    backgroundColor: '#F8D803',
                                                                    fontSize: "10px",
                                                                    padding: '10px 10px 10px 10px',
                                                                    width: '70%',
                                                                    marginTop: '5px',
                                                                    border: 'none',
                                                                    fontWeight: 'bold',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    margin: 'auto',
                                                                    marginBottom: '8px',
                                                                    alignItems: 'center'

                                                                }}
                                                            >
                                                                Continue to checkout
                                                            </button>
                                                        </Link>


                                                    </div>
                                                </div>

                                            </div>








                                        </div>
                                    </div>
                                ))
                            )}

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default AmPage4;