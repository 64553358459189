import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, Row, Col, ToggleButton, ToggleButtonGroup, Alert } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const ShippingDetails = ({ setStep }) => {
    const { register, handleSubmit, formState: { errors }, setValue,getValues } = useForm();
    const [subscribeChk, setSubscribeChk] = useState(false);
    const [saveChk, setSaveChk] = useState(false);
    const [countries, setCountries] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('stripe');
    const [codAllowed,setCodAllowed] = useState(false);
    const navigate = useNavigate();
    
    const getOldData = async()=>{
        const data = await JSON.parse(localStorage.getItem('shippingData'));
        if(data){
            setValue('email',data.email);
            setValue('firstName',data.firstName);
            setValue('lastName',data.lastName);
            setValue('address',data.address);
            setValue('email',data.email);
            setValue('country',data.country);
            setValue('zip',data.zip);
            setValue('phone',data.phone);
            setPaymentMethod(data.paymentMethod);
        }
    }

    const checkLogin = async() =>{
        const data = await JSON.parse(localStorage.getItem('auth-data'));
        console.log('The data from auth ==>>',data);
        if(data){
            setValue('email',data.email);
            setValue('firstName',data.firstName);
            setValue('lastName',data.lastName);
            setCodAllowed(data.allowedCOD);
            if(!data.allowedCOD){
                setPaymentMethod('stripe')
            }
        }else{
            navigate('/TradeSignin');
        }
    }

    useEffect(() => {
        checkLogin()
        const fetchCountries = async () => {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            const countryNames = response.data.map(country => country.name.common).sort();
            setCountries(countryNames);
        };
        fetchCountries();
    }, []);

    const onSubmit = data => {
        console.log(data);
        if(saveChk){
            // logic to save his data
        }
        if(subscribeChk){
            // logic to subscribeChk 
        }
        data.paymentMethod = paymentMethod;
        localStorage.setItem('shippingData',JSON.stringify(data))
        setStep(3);
    };

    return (
        <div>
            <p style={{fontSize:'14px',color:'#777',marginBottom:'22px'}} >
            <span onClick={()=>setStep(prev=>prev-1)} style={{cursor:'pointer'}} >
            ← Back
            </span>
            </p>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formEmail" style={{marginBottom:'15px'}} >
                    <Form.Label>Email</Form.Label>
                    <input 
                        style={{padding:'2px 8px',borderTopRightRadius:'3px',borderBottomRightRadius:'3px',minHeight:'50px',flex:'1',outline:'0px',display:'block',width:'100%'}} 
                        type="email" 
                        placeholder="Enter email" 
                        {...register('email', { required: true })}
                    />
                    {errors.email && <Alert variant="danger">This field is required</Alert>}
                </Form.Group>
                <Form.Group controlId="formFirstName"  style={{marginBottom:'15px'}}>
                    <Row>
                        <Col>
                        <Form.Label>First Name</Form.Label>
                        <input 
                            style={{padding:'2px 8px',borderTopRightRadius:'3px',borderBottomRightRadius:'3px',minHeight:'50px',flex:'1',outline:'0px',display:'block',width:'100%'}} 
                            type="text" 
                            placeholder="First Name" 
                            {...register('firstName', { required: true })}
                        />
                        {errors.firstName && <Alert variant="danger">This field is required</Alert>}
                        </Col>
                        <Col>
                        <Form.Label>Last Name</Form.Label>
                        <input 
                            style={{padding:'2px 8px',borderTopRightRadius:'3px',borderBottomRightRadius:'3px',minHeight:'50px',flex:'1',outline:'0px',display:'block',width:'100%'}} 
                            type="text" 
                            placeholder="Last Name" 
                            {...register('lastName', { required: true })}
                        />
                        {errors.lastName && <Alert variant="danger">This field is required</Alert>}
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group controlId="formAddress"  style={{marginBottom:'15px'}}>
                    <Form.Label>Street Address</Form.Label>
                    <input 
                        style={{padding:'2px 8px',borderTopRightRadius:'3px',borderBottomRightRadius:'3px',minHeight:'50px',flex:'1',outline:'0px',display:'block',width:'100%'}} 
                        type="text" 
                        placeholder="Street Address" 
                        {...register('address', { required: true })}
                    />
                    {errors.address && <Alert variant="danger">This field is required</Alert>}
                </Form.Group>
                <Form.Group controlId="formCountry"  style={{marginBottom:'15px'}}>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                    style={{padding:'2px 8px',borderRadius:'3px',minHeight:'50px',flex:'1',outline:'0px',display:'block',width:'100%'}} 
                    as="select" {...register('country', { required: true })}>
                        <option value="">Select Country</option>
                        {countries.map((country, index) => (
                        <option key={index} value={country}>{country}</option>
                        ))}
                    </Form.Control>
                    {errors.country && <Alert variant="danger">This field is required</Alert>}
                </Form.Group>
                <Form.Group controlId="formZipPhone"  style={{marginBottom:'15px'}}>
                    <Row>
                        <Col>
                        <Form.Label>Zip Code</Form.Label>
                        <input 
                            style={{padding:'2px 8px',borderTopRightRadius:'3px',borderBottomRightRadius:'3px',minHeight:'50px',flex:'1',outline:'0px',display:'block',width:'100%'}} 
                            type="text" 
                            placeholder="Zip Code" 
                            {...register('zip', { required: true })}
                        />
                        {errors.zip && <Alert variant="danger">This field is required</Alert>}
                        </Col>
                        <Col>
                        <Form.Label>Phone Number</Form.Label>
                        <input 
                            style={{padding:'2px 8px',borderTopRightRadius:'3px',borderBottomRightRadius:'3px',minHeight:'50px',flex:'1',outline:'0px',display:'block',width:'100%'}} 
                            type="text" 
                            placeholder="+123-456-7890" 
                            {...register('phone', { 
                            required: true, 
                            pattern: /^\+?\d{1,4}?[-. \(\)]?(\d{1,3}[-. \(\)]?){1,4}$/,
                            })}
                        />
                        {errors.phone && <Alert variant="danger">Enter a valid phone number</Alert>}
                        </Col>
                    </Row>
                </Form.Group>
                <div className="form-check form-switch" style={{margin:'15px 0px',display:'flex',alignItems:'center'}} >
                    <input className="form-check-input" 
                        style={{width:'40px',height:'25px'}}
                        type="checkbox" 
                        role="switch" 
                        checked={saveChk}
                        id="formSaveInfo"
                        onClick={()=>setSaveChk(prev=>!prev)}
                        />
                    <label className="form-check-label" for="formSaveInfo" style={{cursor:'pointer',lineHeight:'25px',marginTop:'-10px'}}>Save my info</label>
                </div>
                <div className="form-check form-switch" style={{margin:'15px 0px',display:'flex',alignItems:'center'}} >
                    <input className="form-check-input" 
                        style={{width:'40px',height:'25px'}}
                        type="checkbox" 
                        role="switch" 
                        checked={subscribeChk}
                        id="formSubscribe"
                        onClick={()=>setSubscribeChk(prev=>!prev)}
                        />
                    <label className="form-check-label" for="formSubscribe" style={{cursor:'pointer',lineHeight:'25px',marginTop:'-10px'}}>Subscribe for best offers</label>
                </div>
                {
                    codAllowed && 
                    <Form.Group controlId="formPaymentMethod"  style={{marginBottom:'15px'}}>
                        <Form.Label>Payment Method</Form.Label>
                        <div className="" style={{margin:'15px 0px'}}>
                            <ToggleButtonGroup type="radio" name="paymentMethod" defaultValue={paymentMethod} onChange={val => setPaymentMethod(val)}>
                                <ToggleButton id="tbg-radio-1" value={'stripe'} variant="outline-primary">
                                    Stripe
                                </ToggleButton>
                                {/* <ToggleButton id="tbg-radio-2" value={'klarna'} variant="outline-primary">
                                    Klarna
                                </ToggleButton> */}
                                <ToggleButton id="tbg-radio-3" value={'cod'} variant="outline-primary">
                                    Cash on Delivery
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Form.Group>
                }
                <Button type="submit" style={{width:'100%',border:'none'}} variant="primary" block className="mt-3">Continue</Button>
                {/* <Button variant="secondary" block className="mt-3" onClick={() => setStep(1)}>Back</Button> */}
            </Form>
        </div>
    );
};

export default ShippingDetails;
