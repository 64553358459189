import React, {useState , useEffect} from "react";
import Navbar from "../Navbar/Navbar";
import "./ForgotPassword.css";
import { useNavigate, useParams } from "react-router-dom";
import Env from "../Environment/Env";
import axios from "axios";



const ForgotPassword =()=>{
    const navigate = useNavigate();
    const { id } = useParams(); 
    console.log("abc=====", id);
    const [pass1, setPass1] = useState("");
    const [pass2, setPass2] = useState("");

    const submit  =()=>{
    console.log("abc=====", pass1, pass2);
    if(!pass1 || !pass2){
        alert("Please fill out All Fields");
    }else if(pass1 != pass2){
        alert("Password does not match");
    }else{
        axios.patch(`${Env.server}/api/user/PasschangeForget`, {id, pass1})
        .then((res)=>{
            console.log("abc==-===" , res.data)
            if (res.data.success === true){
                navigate("/");
            }

        })
        .catch((err)=>{
            console.log("err", err)
        })
    }
}


    return(
        <>
        <Navbar/>
            <div class="mainDiv" style={{height:"100vh"}}>
                <div class="cardStyle">  
                        <img src="" id="signupLogo" /> 
                        <h2 class="formTitle">
                            Login to your account
                        </h2> 
                        <div class="inputDiv">
                            <label class="inputLabel" for="password">New Password</label>
                            <input type="password" onChange={(e)=> setPass1(e.target.value)} id="password" name="password" required />
                        </div>

                        <div class="inputDiv">
                            <label class="inputLabel" for="confirmPassword">Confirm Password</label>
                            <input type="password" onChange={(e) => setPass2(e.target.value)} id="confirmPassword" name="confirmPassword" />
                        </div>

                        <div class="buttonWrapper">
                            <button type="submit" id="submitButton" onClick={submit} class="submitButton pure-button pure-button-primary" >
                                <span>Continue</span> 
                            </button>
                        </div> 
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;