import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { setAuthData } from './store/authSlice';


function AppLayout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const verifyLogin = async() =>{
        
        let data = localStorage.getItem('auth-data');
        data = await JSON.parse(data);
        console.log(location.pathname);
        const isPublic = () =>{
            return (location.pathname === '/TradeSignup' ||
                location.pathname === '/contactus' ||
                location.pathname === '/ContactUs' ||
                location.pathname === '/aboutus' ||
                location.pathname === '/Aboutus'
            )
        }
        if(!data){
            if(!isPublic()){
                navigate('/TradeSignin');
            }
        }else{
            dispatch(setAuthData(data));
        }
    }

    useEffect(()=>{
        verifyLogin();
    },[location.pathname])

    return (
        <div>
            <Outlet />
        </div>
    )
}

export default AppLayout