import React, { useState } from "react";
import "../TradeSignup/TradeSignin.css";
import CustomNavbar from "../Navbar";
import CustomFooter from "../../Footer/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../../Environment/Env";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify'; // Added import for react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Added import for react-toastify CSS

const TradeSignin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();



 // Validation: Check if all fields are filled
 if (!formData.email || !formData.password) {
  toast.error('All fields are required!', {
    position: 'top-center', // Updated position
});
 return;
}


    // Add your form submission logic here
    console.log(formData); 
      console.log("abc====", formData)
      let link; 
          link = `${Env.server}/api/trader/login`;
    
          axios.post(link, formData)
          .then((res) => {
              const resp = res.data.trader;
              console.log("response===>>>", res.data)
              localStorage.setItem('auth-data',JSON.stringify(res.data.trader));
              toast.success('Login successful!', {
                position: 'top-center', // Updated position
            });
              navigate("/"); 
          })
          .catch((err) => {
              console.log("abc===>>>", err);
              toast.error(err.message, {
                position: 'top-center', // Updated position
            });
          }) 
  };

  return (
    <>
      <CustomNavbar />
      <div className="trade-signin-container">
        <div className="trade-signin-form-container">
          <form onSubmit={handleSubmit} className="trade-signin-form">
          <h1 className="fs-7" style={{color: '#071A44'}}>Sign In</h1>
            <div className="trade-form-group">
              <input
                type="email"
                name="email"
                className="trade-signin-input"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
              <input
                type="password"
                name="password"
                className="trade-signin-input"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
            </div>
            <button type="button" onClick={handleSubmit} class="btnSubmit btn-lg mt-2 bg-white fw-bolder fs-9" style={{color: '#071A44', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>Sign In</button>
            <p className="text-center">
              Don't have an account? <Link to="/TradeSignup">Sign up</Link>
            </p>
          </form>
        </div>
      </div>
      <CustomFooter />
      <ToastContainer /> {/* Added ToastContainer for toast notifications */}
    </>
  );
};

export default TradeSignin;
