import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const TradeNotAvailable = () => {
  return (
    <>
      <Navbar className="navvvvvv" />
      <div className="container navvvvvv" style={{ marginTop: "100px", marginBottom: '100px' }}>
        <div className="row" style={{overflow: 'hidden'}}>
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert">
              <h5 className="text-center" >Trade Site is not available at this store yet.</h5>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TradeNotAvailable;
