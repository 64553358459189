import React, { useEffect, useState } from "react";
// import "./Banar.css"
import { MdCancel } from 'react-icons/md'; // Import the React Icons
import { AiFillDelete } from 'react-icons/ai';

import Navbar1 from "../Navbar/Navbar1";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Paper, Grid, Card, CardContent } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faComment } from '@fortawesome/free-solid-svg-icons';
import Footer from "../Footer/Footer";

const AccessoryType = () => {

  const navigate = useNavigate();
  const { model, type } = useParams();

  const Model = decodeURIComponent(model);

  const [options, setOptions] = useState(['Choose Options']);

  console.log(type, model);

  let MODEL = model;
  let TYPE = type;

  useEffect(() => {
    getAllByNmeAcc();
  }, [model, type])


  const [isSelected, setIsSelected] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [ModelGetByParams, setModelGetByParams] = useState([]);
  const [AccessGetByParams, setAccessGetByParams] = useState([]);
  const [allProductData1, setAllProductData1] = useState([]);
  const [allProductData, setAllProductData] = useState([]);
  const [FilteredData, setFilteredData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [UniqueTypes, setUniqueTypes] = useState([]);
  const [cartItemIndexes,setCartItemIndexes] = useState([]);

  const [data, setdata] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [Again,setAgain]=useState(true);

  useEffect(() => {
    axios
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
      .then((res) => {
        let resp = res.data.subsubcatagories;
        // Extracting unique types
        const types = Array.from(new Set(resp.map(item => item.typeName)));
        console.log(types)
        setUniqueTypes(types);
      })
      .catch((err) => {
        console.log("err=====", err);
      })
  }, [])


  const [selectedProducts, setSelectedProducts] = useState([]);
  const [ShowPro, setShowPro] = useState(true);

  // Load data from localStorage on component mount
  useEffect(() => {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem("selectedProducts"));
    if (dataFromLocalStorage) {
      setCartItemIndexes(dataFromLocalStorage.map(prod=>prod._id));
      setSelectedProducts(dataFromLocalStorage);
    }
  }, [ShowPro,Again]);

  const [productQuantity, setProductQuantity] = useState({});

  // Function to handle incrementing quantity
  const incrementQuantity = (productId) => {
    console.log("productQuantity", productQuantity);
    setProductQuantity(prevQuantity => {
      const newQuantity = (prevQuantity[productId] || 0) + 1;
      console.log(`Quantity for product ${productId} incremented to ${newQuantity}`);
      return {
        ...prevQuantity,
        [productId]: newQuantity
      };
    });
  };

  // Function to handle decrementing quantity
  const decrementQuantity = (productId) => {
    setProductQuantity(prevQuantity => {
      const newQuantity = Math.max((prevQuantity[productId] || 0) - 1, 1); // Ensure quantity does not go below 1
      console.log(`Quantity for product ${productId} decremented to ${newQuantity}`);
      return {
        ...prevQuantity,
        [productId]: newQuantity
      };
    });
  };

  const handleCheckboxChange = () => {
    setIsSelected(!isSelected);
  };

  const [MinRange, setMinRange] = useState(0);
  const [MaxRange, setMaxRange] = useState(6000);

  const [selectedRange, setSelectedRange] = useState(6000);
  const [selectedColor, setSelectedColor] = useState("");
  const [reload, setReload] = useState(false);


  useEffect(() => {
    getAllByNmeAcc();
  }, [reload, model, type])


  useEffect(() => {
    applyFilterProper();
  }, [selectedRange, MinRange, selectedFilters]);

  

  // in this function we filtering data according to range
  const handleRangeChange = (event) => {
    const range = parseInt(event?.target?.value);
    let filteredItems = allProductData && allProductData.filter(item => parseInt(item.wholesellerPrice) <= selectedRange);
    setAllProductData(filteredItems)
    setSelectedRange(range);
  };



  // this function is used to filter dataa
  const handleFilter = () => {
    localStorage.setItem("priceFilter", MaxRange);
    console.log("abc===", allProductData1)
    let filteredItems = allProductData1 && allProductData1.filter(item => parseInt(item.wholesellerPrice) <= MaxRange);
    setAllProductData(filteredItems)
    // window.location.reload();
  }


  function groupProductsByType(products) {
    const groupedProducts = {};
    products.forEach(product => {
      if (!groupedProducts[product.typeName]) {
        groupedProducts[product.typeName] = [];
      }
      groupedProducts[product.typeName].push(product);
    });
    return groupedProducts;
  }
  const applyFilterProper = async () => {
    let url = `${Env.server}/api/subsubcatagory/GetByFilterForTradeByModel?min=${MinRange}&max=${selectedRange}&subcatagory=${encodeURIComponent(model)}`;
    if (selectedFilters.length === 0 && selectedRange === MaxRange) {
      getAllByNmeAcc();
      return;
    }
    console.log('aaaaaaaaaaaaaaaaaaaa', selectedFilters);
    selectedFilters.map(filter => {
      if (filter.type === "Manufacturer") {
        url += `&manufacturerName=${filter.var}`
      }
      if (filter.type === "Color") {
        url += `&color=${filter.var}`
      }
      if (filter.type === "Variant") {
        url += `&variantName=${filter.var}`
      }
    })
    await axios.get(url)
      .then((res) => {
        console.log('aaaaaaaaaaaaaaaaaaa', res.data.data);
        setAllProductData(res.data.data);
      })
      .catch((err) => console.log('aaaaaaaaaaaaaaaaaaaa', err));
  }


  const getAllByNmeAcc = () => {
    console.log("abc---", encodeURIComponent(Model))
    axios.get(`${Env.server}/api/subsubcatagory/getAllByTypeByModelTS/${type}/${encodeURIComponent(Model)}`)
      .then((res) => {
        console.log("rana getting response", res.data.subsubcatagories);
        setModelGetByParams(res.data.subsubcatagories);
        setAccessGetByParams(res.data.subsubcatagories)
        setdata(res.data.subsubcatagories)
        console.log("abcccc====>>>", res.data.subsubcatagories);
        const prodArr = res.data.subsubcatagories;
        console.log('fffff', prodArr);
        const maxNum = Math.max(...prodArr.map(product => product.wholesellerPrice));
        const minNum = Math.min(...prodArr.map(product => product.wholesellerPrice));
        // setMaxRange(maxNum);
        setMinRange(minNum === Infinity ? 0 : minNum);
        localStorage.setItem("minRange", MinRange);
        setMaxRange(maxNum === -Infinity ? 0 : maxNum);
        if (!localStorage.getItem("minRange")) {
          console.log('I just set the value to be', MinRange);
        } else {
          setMinRange(localStorage.getItem("minRange"));
        }
        setSelectedRange(maxNum === -Infinity ? 0 : maxNum);


        if (localStorage.getItem("filterselected") === 0) {
          console.log("");
        } else {
          const savedFilters = localStorage.getItem('selectedFilters');
          if (savedFilters) {
            setSelectedFilters(JSON.parse(savedFilters));
          }
        }

        console.log("res.data.subsubcatagories====", res.data.subsubcatagories)
        setAllProductData(res.data.subsubcatagories)
        setAllProductData1(res.data.subsubcatagories)
        let dataAll = res.data.subsubcatagories

        console.log('Selected Range ==>', selectedRange);
        let filteredItems = dataAll && dataAll.filter(item => parseInt(item.wholesellerPrice) <= selectedRange && parseInt(item.wholesellerPrice) >= MinRange);
        console.log("muzzamal====", filteredItems)
        setAllProductData(filteredItems)
        setAllProductData1(filteredItems)
        // setAllProductData(res.data.subsubcatagories)
        // setAllProductData1(res.data.subsubcatagories)

        let data = res.data.subsubcatagories;
        // console.log("hhhhhhhhhhhhhhhh======", data);
        const combinedFilters = {};

        data.forEach((product) => {
          if (product.filter) {
            product.filter.forEach((filterCategory) => {
              Object.entries(filterCategory).forEach(([key, values]) => {
                if (values.length === 0) return; // Skip if no data

                if (!combinedFilters[key]) {
                  combinedFilters[key] = [];
                }
                values.forEach((value) => {
                  let existingFilter = combinedFilters[key].find(
                    (existing) => existing.var === value
                  );
                  if (!existingFilter) {
                    combinedFilters[key].push({ var: value, id: [product._id] });
                  } else {
                    existingFilter.id.push(product._id);
                  }
                });
              });
            });
          }
        });

        console.log("Combined filters:", combinedFilters);
        setFilteredData(combinedFilters);

        const subCatagoryArray = res.data.subsubcatagories.map(data => data.subcatagory);
        setOptions(prevOptions => {
          const mergedOptions = [...prevOptions, ...subCatagoryArray];
          const uniqueOptions = Array.from(new Set(mergedOptions));
          return uniqueOptions;
        });

      })
      .catch((err) => {
        console.log("err====", err)
      })
  }
  // const [indexes,setIndexes]=useState('');
  // const [again,setAgain]=useState(true);
  // useEffect(() => {
  //   if ( data && data.length > 0) {
  //     gettingCartItems();
  //   }
  // }, [data,again]);

  // const getMatchingIndexes = (arr1, arr2) => {
  //   let indexes = [];
  //   arr1.forEach((item, index) => {
  //     if (arr2.some(product => product._id === item._id)) {
  //       indexes.push(index);
  //     }
  //   });
  //   console.log(indexes)
  //   return indexes;
  // };

  // const gettingCartItems = () => {
  //   const cartItems = JSON.parse(localStorage.getItem("selectedProducts")) || [];
  //   if (data && data.length > 0) {
  //     const matchingIndexes = getMatchingIndexes(data, cartItems);
  //     setIndexes(matchingIndexes);
  //   }
  // };






  const uniqueSubcategories = new Set(ModelGetByParams.map(card => card.subcatagory));

  // Convert Set back to an array
  const uniqueSubcategoriesArray = [...uniqueSubcategories];


  const handleCardClick = (selectedProduct) => {
    // console.log("working")
    // productQuantity
    console.log("selectedProduct", selectedProduct);

    setShowPro(!ShowPro);


    let quantity = productQuantity[selectedProduct._id] !== undefined ? productQuantity[selectedProduct._id] : 1;

    let products = JSON.parse(localStorage.getItem('selectedProducts')) || [];
    console.log("rana mubashir", products)

    // Check if the selected product already exists in the array
    const existingProductIndex = products.findIndex(product => product._id === selectedProduct._id);

    if (existingProductIndex !== -1) {
      // If the product already exists, update it including quantity
      products[existingProductIndex].quantity = quantity;
      products[existingProductIndex].selectedColor = selectedColor;
    } else {
      // If the product does not exist, add it to the array including quantity
      selectedProduct.quantity = quantity;
      products.push(selectedProduct);
    }

    // Store the updated products array in local storage
    localStorage.setItem('selectedProducts', JSON.stringify(products));
  }






  useEffect(() => {
    // Retrieve saved selected filters from localStorage when the component mounts
    const savedFilters = localStorage.getItem('selectedFilters');
    if (savedFilters) {
      setSelectedFilters(JSON.parse(savedFilters));
    }
  }, []);

  useEffect(() => {
    let getdatafiltercounting = localStorage.getItem("filterselected");
    // alert(selectedFilters.length + "    " + getdatafiltercounting);  
    if (!allProductData) {
      // console.log("selectedFilters====tt", selectedFilters); 
    } else if (selectedFilters.length > 0) {
      const uniqueProducts = []; // Array to store unique products

      selectedFilters.forEach((filter) => {
        const uniqueIds = new Set(); // Create a Set to store unique IDs
        const filterid = filter.id;

        filterid.forEach((idfilter) => {
          const id = idfilter[0]; // Extract the ID 
          if (!uniqueIds.has(id)) { // Check if the ID is not already in the Set 
            const product = allProductData.find((product) => product._id === id);
            if (product) { // Check if product exists
              uniqueProducts.push(product); // Push the unique product to the array
            }
          }
        });
      });
      const uniqueSet = new Set(uniqueProducts);

      // Convert the Set back to an array if needed
      const uniqueArray = Array.from(uniqueSet);
      if (uniqueArray) {
        setAllProductData(uniqueArray);
      }
    }

  }, [selectedFilters]);

  const handleCheckboxClick = (filterVar, productId, filterName) => {
    setSelectedFilters(prevFilters => {
      const selectedIndex = prevFilters.findIndex(filter => filter.var === filterVar);
      if (selectedIndex === -1) {
        const newFilters = [...prevFilters, { var: filterVar, id: [productId], type: filterName }];
        console.log('Here is the data vvv=>>', newFilters);
        return newFilters;
      } else {
        const newFilters = prevFilters.filter((_, index) => index !== selectedIndex);
        return newFilters;
      }
    });
  };



  useEffect(() => {
    // Log the IDs of all selected filters after each state update
    const selectedIds = selectedFilters.flatMap(filter => filter.id);
    console.log("Selected IDs:", selectedIds);
  }, [selectedFilters]);










  const handleInputChange = (event) => {
    setSearchText(event?.target?.value?.toLowerCase());
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };


  const [productQuantities, setProductQuantities] = useState({});


  const increaseQuantity = (productId) => {
    setProductQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: (prevQuantities[productId] || 0) + 1
    }));
  };

  // Function to handle decreasing the quantity for a specific product
  const decreaseQuantity = (productId) => {
    if (productQuantities[productId] && productQuantities[productId] > 0) {
      setProductQuantities(prevQuantities => ({
        ...prevQuantities,
        [productId]: prevQuantities[productId] - 1
      }));
    }
  };

  // Function to handle changing the quantity directly for a specific product
  const handleQuantityChange = (productId, event) => {
    const value = parseInt(event?.target?.value);
    if (!isNaN(value) && value >= 1) {
      setProductQuantities(prevQuantities => ({
        ...prevQuantities,
        [productId]: value
      }));
    }
  };



  const handleRemoveProduct = (index) => {
    setAgain((prev)=> !prev)
    const updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts.splice(index, 1);
    setSelectedProducts(updatedSelectedProducts);
    // Optionally, update localStorage if needed
    localStorage.setItem('selectedProducts', JSON.stringify(updatedSelectedProducts));
  };
  const [stockQty, setStockQty] = useState(0);
  const [changeIndex, setChangeIndex] = useState('');
  const [isStock, setIStock] = useState(true)
  const handleSelectChange = (index, e, card) => {
    console.log('rana', card.color)
    const color = card.color
    const selectedValue = e.target.value;
    console.log('rana2', selectedValue)
    setChangeIndex(index);
    setSelectedColor(selectedValue);
    if (selectedValue === '') {
      console.log('rana i am emty', card.qty)
      setStockQty(card.qty)
      setIStock(true)
    }
    else {
      const selectedColorObject = color.find(color => color.name === selectedValue);
      if (selectedColorObject) {
        setStockQty(selectedColorObject.quantity ? selectedColorObject.quantity : 0);
        if (!selectedColorObject.quantity) {
          setIStock(false);
        }
        else {
          setIStock(true);
        }
      } else {
        setStockQty(null);
      }
    }
  };
  return (
    <>
      <Navbar1 />
      {/* <p>hello world</p> */}
      <br />
      <div className="container-fluid" style={{ width: '100%', overflow: 'hidden' }} >
        <div className="row" style={{ width: '100vw' }} >
          <div className="col-lg-2 col-md-2 pt-4 mb-3" style={{ maxWidth: '200px' }} >
            <div style={{ backgroundColor: '#F8F6ED', borderRadius: '5px' }} >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <input
                    type="range"
                    min={MinRange}
                    max={MaxRange + 1}
                    step="1"
                    value={selectedRange}
                    onChange={handleRangeChange}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                  <input type="text" style={{ width: '35%' }} value={MinRange} onChange={(e) => {
                    setMinRange(e.target.value)
                    localStorage.setItem("minRange", e.target.value);
                  }} placeholder="" /> - to -
                  <input type="text" style={{ width: '35%' }} value={selectedRange} onChange={(e) => setSelectedRange(e.target.value)} placeholder="" />
                  <button onClick={handleFilter} style={{ width: '50%', borderRadius: "17px", border: "1px solid transparent", backgroundColor: '#DB241B', color: 'white', padding: '5px', marginTop: '8px' }} >Filter</button>
                </div>
              </div>
              <div>
                {/* Map over the filtered data and create checkboxes */}
                {FilteredData && Object.entries(FilteredData).map(([filterName, filterValues]) => (
                  // Check if filterName is not "Variant"
                  filterName !== "ProductPrice" &&
                  <div className="box w-75 my-4">
                    <h5 className="text-black border-top pt-3">{filterName}</h5>
                    <div className="p-2">
                      {/* Map over filter values and create checkboxes */}
                      {filterValues.map(filter => (
                        <div key={filter.var} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={filter.var}
                            checked={selectedFilters.some(selectedFilter => selectedFilter.var === filter.var)}
                            onClick={() => handleCheckboxClick(filter.var, filter.id, filterName)}
                          />
                          <label className="form-check-label" style={{ textWrap: 'nowrap', fontSize: '12px' }} htmlFor={filter.var}>
                            {filter.var}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <p>hello world</p> */}
          <div className="col-lg-10">
            <div className="container">
              {allProductData && (
                Object.entries(groupProductsByType(allProductData)).map(([type, products]) => (
                  <div key={type}>
                    <h3 className="" style={{ fontFamily: 'poppins', fontWeight: 'bold' }}>{type}</h3>
                    <div className="row">

                      <div className="col-12 col-lg-9 col-md-9" style={{ padding: "10px", paddingTop: '0px' }}>
                        <div className="container-fluid " style={{ display: 'flex', flexDirection: 'column' }}>
                          <div className="row responsive-row" style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', backgroundColor: '#F8F6ED', padding: '5px 10px 5px 10px' }}>
                            <div className="col-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>Name</h5>
                            </div>
                            <div className="col-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>Model</h5>
                            </div>
                            <div className="col-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>image</h5>
                            </div>
                            <div className="col-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>color</h5>
                            </div>
                            <div className="col-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>price</h5>
                            </div>
                            <div className="col-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <h5 className='responsive-font' style={{ fontWeight: 'bold', fontSize: '17px' }}>Qty</h5>
                            </div>

                            <div className="col-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <h5 className='responsive-font' style={{ fontWeight: 'bold' }}>Stock</h5>
                            </div>

                            <div className="col-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <h5 className='responsive-font' style={{ fontWeight: 'bold' }}>Add to cart</h5>
                            </div>
                          </div>














                          {/* Data */}

                          {products.map((card, index) => (
                            <>
                              <div key={index} className="row responsive-row" style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', padding: '5px 10px 5px 10px' }}>
                                <div className='col-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <h5 className='responsive-font' style={{ fontSize: '14px' }} >
                                    {card && card.subsubcatagory}
                                  </h5>
                                </div>
                                <div className='col-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <h5 className='responsive-font' style={{ fontSize: '14px' }} >{card && card.subcatagory}</h5>
                                </div>
                                <div className='col-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <img className="card-img-top" style={{ height: "80px", width: "80px", border: "none" }} src={getAsset(card && card.imageSubsubCatagory && card.imageSubsubCatagory[0])} alt="Card image cap" />
                                </div>
                                <div className='col-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  {card && card.color && card.color.length === 1 ? (
                                    <>
                                      <h5
                                        style={{ fontSize: "16px" }}
                                        className="card-title"
                                      >
                                        {card && card.color && card.color[0].name || "N/A"}
                                      </h5>
                                    </>
                                  ) : card && card.color && card.color.length > 1 ? (
                                    <select
                                      className="form-select"
                                      style={{ fontSize: '13px', width: '88%' }}
                                      onChange={(e) => handleSelectChange(index, e, card)}
                                      value={index !== changeIndex ? '' : selectedColor}
                                    >
                                      <option value="">Select Color</option>
                                      {card && card.color && card.color.map((color, index) => (
                                        <option key={index} value={color.name}>
                                          {color.name}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (
                                    <>
                                      <h5
                                        style={{ fontSize: "15px" }}
                                        className="card-title"
                                      >
                                        N/A
                                      </h5>
                                    </>
                                  )}
                                </div>
                                {/* ===== WholeSale Price ===== */}
                                <div className='col-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <h5 className='responsive-font-product' style={{ fontSize: '14px' }} >£{card && card.wholesellerPrice}</h5>
                                </div>

                                <div className='col-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <button style={{ border: 'none', background: 'none', width: '14px', fontWeight: 'bold' }} onClick={() => decrementQuantity(card._id)}>-</button>
                                  <span>&nbsp;&nbsp;{productQuantity[card._id] || 1}&nbsp;&nbsp;</span>
                                  <button style={{ border: 'none', background: 'none', width: '14px', fontWeight: 'bold' }} onClick={() => incrementQuantity(card._id)}>+</button>
                                </div>


                                {/* ===== Stock ===== */}
                                <div className='col-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  {card && card.qty > 0 ? (<h5 className='responsive-font-product' style={{ color: '#53C304', fontSize: '14px' }}>{
                                    index === changeIndex ? stockQty : card.qty
                                  }</h5>) :
                                    (<h5 className='responsive-font-product' style={{ color: 'red', fontSize: '14px' }}>NA</h5>)}
                                </div>

                                {/* ===== add to cart ===== */}
                                <div className='col-2 button-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <button
                                    onClick={index === changeIndex && !isStock ? undefined : () => handleCardClick(card)}
                                    style={{
                                      color: index === changeIndex && !isStock ? 'red' : 'black',
                                      borderRadius: '50px',
                                      backgroundColor: '#F8D803',
                                      fontSize: "10px",
                                      padding: '13px 5px',
                                      width: '70%',
                                      marginTop: '5px',
                                      border: 'none',
                                      fontWeight: 'bold',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      margin: 'auto',
                                      marginBottom: '8px',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {
                                      index === changeIndex && !isStock ?
                                        "Out of Stock"
                                        :
                                        ( cartItemIndexes.includes(card._id)  ?
                                        "Update Cart"
                                        :
                                        "Add to Cart")
                                    }
                                  </button>           
                                </div>
                                <div className="row responsive-row" style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', padding: '5px 10px 5px 10px' }}>
                                  <div className='col-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </>
                          ))}


                          {/* {products.map((card, index) => (
                                                
                                            ))} */}








                        </div>
                      </div>

                      {/* WHOLESALE PAYMENT SECTION */}
                      <div className="col-12 col-lg-3 col-md-3  " style={{ padding: "0px", marginTop: '0px' }}>
                        <div style={{ borderRadius: '20px', paddingTop: '10px', paddingRight: '10px', fontSize: '25px', paddingLeft: "10px", border: "2px solid lightgray", backgroundColor: '#F8F6ED' }}>
                          <h5 className="text-center" style={{ color: '#000000', fontWeight: 'bold', marginBottom: '7px' }}>Your order summary</h5>
                          <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <p className="col-3" style={{ fontWeight: '600', marginBottom: '0', fontSize: '14px' }}>Product</p>
                              <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '14px', fontWeight: "bolder" }}>Price</p>
                              <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '14px', fontWeight: "bolder" }}>Qty</p>
                              <p className="col-3" style={{ fontWeight: '500', marginBottom: '0', fontSize: '14px', fontWeight: "bolder" }}>T.Price</p>
                              <p className="col-2 hidden-element" style={{ fontWeight: '500', marginBottom: '0', fontSize: '7px', fontWeight: "bolder", visibility: 'hidden' }}>Price</p>

                            </div>
                            {selectedProducts.map((item, index) => (
                              <>

                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <p className="col-3" style={{ fontWeight: '600', marginBottom: '0', fontSize: '12px' }}>
                                    {item?.subsubcatagory}
                                    {item.selectedColor && <span style={{ fontWeight: 'normal', borderRadius: '3px', background: '#fff' }} >{` (${item.selectedColor})`}</span>}
                                  </p>
                                  <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '12px' }}>£{item.wholesellerPrice}</p>
                                  <p className="col-2" style={{ fontWeight: '600', marginBottom: '0', fontSize: '12px' }}>{item?.quantity}</p>
                                  <p className="col-3" style={{ fontWeight: '500', marginBottom: '0', fontSize: '12px' }}>£{(item.wholesellerPrice * item?.quantity).toFixed(2)}</p>
                                  <MdCancel className="col-2" onClick={() => handleRemoveProduct(index)} style={{ fontSize: '30px', color: 'red', cursor: 'pointer', margin: 'auto', minWidth: '50px' }} />
                                </div>
                                <hr />
                              </>
                            ))}



                            <br />

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <p style={{ fontWeight: '600', marginBottom: '0', fontSize: '13px' }}>Total Price</p>
                              <p style={{ fontWeight: '500', marginBottom: '0', fontSize: '13px' }}>
                                £{selectedProducts?.reduce((total, item) => total + parseFloat(item?.wholesellerPrice * item?.quantity), 0).toFixed(2)}
                              </p>
                            </div>
                            <br />

                            <Link to="/Cart1">
                              <button
                                style={{
                                  color: "black",
                                  borderRadius: '50px',
                                  backgroundColor: '#F8D803',
                                  fontSize: "10px",
                                  padding: '10px 10px 10px 10px',
                                  width: '70%',
                                  marginTop: '5px',
                                  border: 'none',
                                  fontWeight: 'bold',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  margin: 'auto',
                                  marginBottom: '8px',
                                  alignItems: 'center'

                                }}
                              >
                                Continue to checkout
                              </button>
                            </Link>


                          </div>
                        </div>

                      </div>








                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  )
}
export default AccessoryType; 