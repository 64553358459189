const Env = {
  // server: "http://localhost:8089",
  // dashboard: "http://localhost:3001/",  
  server: "https://accback.selectezi.com",
  dashboard: "https://accdash.selectezi.com",  
  tradeSite: "https://acctrade.selectezi.com",
  picLogo: "https://ik.imagekit.io/cy8phtesy/eeb992d2ea2f7fb585438f0e5e4fb2f1.webp?updatedAt=1714825272028",
  address: "32 moulton street Manchester Manchester M88FQ",
  phone: "07454731877",
  email: "admin@fonecaremanchester.com",
  facebook: "https://www.facebook.com/",
  instagram: "https://www.instagram.com/",
  twitter: "https://twitter.com/",
  linkedin: "https://www.linkedin.com/",
  businessName:'FoneCare',
  publicKey:'pk_live_51PIqZhRqzjzYqY8kibfngyld14dalHoNnfMyrt0B8ivqkXmgPCxGkwcYLMebNS2XZYeP97kCasRS1svE4LkUktr900yaYGIBRA'
}
export default Env;
