import React from 'react';
import Navbar1 from "../Navbar/Navbar1";
import Footer from "../Footer/Footer";
import Env from "../Environment/Env";
function TermsAndConditions() {
  return (
    <>
    <Navbar1 />
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col">
          <h2>Terms and Conditions of Mobile Bitz</h2>
          <p>Welcome to Mobile Bitz! We are delighted to provide you with our comprehensive range of mobile accessories and services. Before you proceed with using our website or making any purchases, we kindly request you to carefully read and understand the following terms and conditions. Your use of our website and services constitutes your agreement to abide by these terms.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Acceptance of Terms</h3>
          <p>By accessing or using the Mobile Bitz website (hereinafter referred to as "the Site") and any services provided therein, you agree to comply with and be bound by the terms and conditions outlined herein.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Definitions</h3>
          <p>"Mobile Bitz" refers to the website and business entity operating under the Mobile Bitz brand.</p>
          <p>"User" refers to any individual accessing or using the Site.</p>
          <p>"Products" refers to mobile accessories and related items available for purchase on the Site.</p>
          <p>"Services" refers to any additional services provided by Mobile Bitz, including but not limited to repair services, warranty services, and customer support.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Scope of Services</h3>
          <p>Mobile Bitz offers a wide range of mobile accessories for various brands and models of smartphones and electronic devices. Additionally, we provide repair services, warranty services, and customer support to ensure a seamless experience for our customers.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Purchasing Products</h3>
          <p>By placing an order through the Site, you warrant that you are legally capable of entering into binding contracts.</p>
          <p>All orders are subject to availability and confirmation of the order price.</p>
          <p>Mobile Bitz reserves the right to refuse or cancel any order for any reason, including but not limited to product availability, errors in pricing or product descriptions, or suspicion of fraudulent activity.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Payment and Pricing</h3>
          <p>Prices for products and services are subject to change without notice.</p>
          <p>Payment for orders placed on the Site can be made using various payment methods accepted by Mobile Bitz.</p>
          <p>All prices displayed on the Site are inclusive of applicable taxes unless otherwise stated.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Delivery</h3>
          <p>Mobile Bitz offers two types of delivery services: regular delivery and express delivery.</p>
          <p>Regular delivery is the standard shipping option and may take longer to arrive compared to express delivery.</p>
          <p>Express delivery is available for customers who require expedited shipping for their orders.</p>
          <p>Customers will be provided with estimated delivery timeframes for both regular and express delivery options at the time of placing the order.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Returns and Refunds</h3>
          <p>Mobile Bitz offers a returns policy for products purchased on the Site. Please refer to our Returns Policy for detailed information on the process and eligibility criteria.</p>
          <p>Refunds for returned products will be issued in accordance with our Refund Policy.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Warranty</h3>
          <p>Certain products sold by Mobile Bitz may come with a manufacturer's warranty. Customers are advised to review the warranty terms and conditions provided with the product.</p>
          <p>Mobile Bitz also offers warranty services for eligible products purchased through the Site. Please refer to our Warranty Policy for more information.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>User Accounts</h3>
          <p>Users may be required to create an account on the Site to access certain services or make purchases.</p>
          <p>User accounts are for personal use only and should not be shared with third parties.</p>
          <p>Mobile Bitz reserves the right to suspend or terminate user accounts if there is any suspicion of unauthorized use or fraudulent activity.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Intellectual Property</h3>
          <p>All content on the Mobile Bitz website, including but not limited to text, graphics, logos, images, and software, is the property of Mobile Bitz or its licensors and is protected by copyright laws.</p>
          <p>Users are prohibited from reproducing, distributing, or modifying any content from the Site without prior written consent from Mobile Bitz.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Limitation of Liability</h3>
          <p>Mobile Bitz shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of the Site or the products and services offered therein.</p>
          <p>Mobile Bitz's total liability for any claim arising from or related to the use of the Site shall not exceed the total amount paid by the user for the products or services in question.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Governing Law and Jurisdiction</h3>
          <p>These terms and conditions shall be governed by and construed in accordance with the laws of the United Kingdom.</p>
          <p>Any disputes arising from or relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of the United Kingdom.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>Contact Information</h3>
          <p>If you have any questions or concerns regarding these terms and conditions, please contact us at info@{Env. businessName}.co.uk.</p>
          <p>By using the Mobile Bitz website and services, you acknowledge that you have read, understood, and agreed to these terms and conditions. Mobile Bitz reserves the right to amend or update these terms and conditions at any time without prior notice. It is your responsibility to review these terms periodically for any changes.</p>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default TermsAndConditions;
