import React, { useEffect, useState } from "react";
// import "./Banar.css"
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Paper, Grid, Card, CardContent } from '@mui/material'
import Footer from "../Footer/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faComment } from '@fortawesome/free-solid-svg-icons';

const Model2 = () => {
    const navigate = useNavigate();
    const { brand , model } = useParams();
   
    const [allProductData1, setAllProductData1] = useState([]);
    const [allProductData, setAllProductData] = useState([]);
    const [FilteredData, setFilteredData] = useState([]); 
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [UniqueTypes, setUniqueTypes] = useState([]);

    useEffect(() => {
        getAllByNmeAcc();
    }, [])

let MODEL = model;
 
console.log(model)
console.log(MODEL)
    useEffect(()=>{
        getAllSubSubCata();
        getAllByNmeAcc();
    }, [MODEL])

    const getAllSubSubCata=()=>{
        axios
        .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
        .then((res) => {
            let resp = res.data.subsubcatagories;
            // Extracting unique types
            const types = Array.from(new Set(resp.map(item => item.typeName)));
            console.log("types====", types)
            setUniqueTypes(types);
        })
        .catch((err) => {
            console.log("err=====", err);
        })
    }


    function groupProductsByType(products) {
        const groupedProducts = {};
        products.forEach(product => {
            if (!groupedProducts[product.typeName]) {
                groupedProducts[product.typeName] = [];
            }
            groupedProducts[product.typeName].push(product);
        });
        return groupedProducts;
    }

    const getAllByNmeAcc = () => {
        axios.get(`${Env.server}/api/subsubcatagory/GetModelByModelbybrandalsoTS/${model}/${brand}`)
            .then((res) => {
                console.log("abcccc====>>>", res.data.subsubcatagories); 
                if (localStorage.getItem("filterselected") === 0) {
                    console.log("");
                } else {
                    const savedFilters = localStorage.getItem('selectedFilters');
                    if (savedFilters) {
                        setSelectedFilters(JSON.parse(savedFilters));
                    }
                }
                setAllProductData(res.data.subsubcatagories)
                setAllProductData1(res.data.subsubcatagories)
                let data = res.data.subsubcatagories; 
                const combinedFilters = {}; 
                data.forEach((product) => {
                    if (product.filter) {
                        product.filter.forEach((filterCategory) => {
                            Object.entries(filterCategory).forEach(([key, values]) => {
                                if (values.length === 0) return; // Skip if no data

                                if (!combinedFilters[key]) {
                                    combinedFilters[key] = [];
                                }
                                values.forEach((value) => {
                                    let existingFilter = combinedFilters[key].find(
                                        (existing) => existing.var === value
                                    );
                                    if (!existingFilter) {
                                        combinedFilters[key].push({ var: value, id: [product._id] });
                                    } else {
                                        existingFilter.id.push(product._id);
                                    }
                                });
                            });
                        });
                    }
                });

                console.log("Combined filters:", combinedFilters);
                setFilteredData(combinedFilters);



            })
            .catch((err) => {
                console.log("err====", err)
            })
    }




    const [isSelected, setIsSelected] = useState(false);

    const handleCheckboxChange = () => {
        setIsSelected(!isSelected);
    };

    const handleCardClick = (selectedProduct, quantity) => {
        if (quantity === undefined) {
            quantity = 1;
        }
        console.log("quantity------", quantity);
        let products = JSON.parse(localStorage.getItem('selectedProducts')) || [];

        // Check if the selected product already exists in the array
        const existingProductIndex = products.findIndex(product => product._id === selectedProduct._id);

        if (existingProductIndex !== -1) {
            // If the product already exists, update it including quantity
            products[existingProductIndex].quantity = quantity;
        } else {
            // If the product does not exist, add it to the array including quantity
            selectedProduct.quantity = quantity;
            products.push(selectedProduct);
        }

        // Store the updated products array in local storage
        localStorage.setItem('selectedProducts', JSON.stringify(products));
    }







    useEffect(() => {
        // Retrieve saved selected filters from localStorage when the component mounts
        const savedFilters = localStorage.getItem('selectedFilters');
        if (savedFilters) {
            setSelectedFilters(JSON.parse(savedFilters));
        }
    }, []);

    useEffect(() => {
        let getdatafiltercounting = localStorage.getItem("filterselected");
        // alert(selectedFilters.length + "    " + getdatafiltercounting);  
        if (!allProductData) {
            // console.log("selectedFilters====tt", selectedFilters); 
        } else if (selectedFilters.length > 0) {
            const uniqueProducts = []; // Array to store unique products

            selectedFilters.forEach((filter) => {
                const uniqueIds = new Set(); // Create a Set to store unique IDs
                const filterid = filter.id;

                filterid.forEach((idfilter) => {
                    const id = idfilter[0]; // Extract the ID 
                    if (!uniqueIds.has(id)) { // Check if the ID is not already in the Set 
                        const product = allProductData.find((product) => product._id === id);
                        if (product) { // Check if product exists
                            uniqueProducts.push(product); // Push the unique product to the array
                        }
                    }
                });
            });
            const uniqueSet = new Set(uniqueProducts);

            // Convert the Set back to an array if needed
            const uniqueArray = Array.from(uniqueSet);
            if (uniqueArray) {
                setAllProductData(uniqueArray);
            }
        }

    }, [selectedFilters]);



    const handleCheckboxClick = (filterVar, productId) => {
        setSelectedFilters(prevFilters => {
            console.log("prevFilters===================", prevFilters)
            const selectedIndex = prevFilters.findIndex(filter => filter.var === filterVar);

            // Create a Set to store unique IDs
            const uniqueIds = new Set(prevFilters.flatMap(filter => filter.id));

            if (selectedIndex === -1) {
                // If the filter is not already selected, add it to the selectedFilters state
                const newFilters = [...prevFilters, { var: filterVar, id: [productId] }];
                uniqueIds.add(productId); // Add the ID to the Set  
                localStorage.setItem('selectedFilters', JSON.stringify(newFilters));
                localStorage.setItem("filterselected", newFilters.length);
                window.location.reload();
                return newFilters;
            } else {
                // If the filter is already selected, remove it from the selectedFilters state
                const newFilters = prevFilters.filter((_, index) => index !== selectedIndex);
                let valprevfilter = localStorage.getItem("filterselected");
                uniqueIds.delete(productId); // Remove the ID from the Set
                console.log("filterrrrrrrrrrrrrrrrrrrrrrrr", newFilters)
                if (newFilters.length === 0) {
                    setAllProductData(allProductData1);
                } else if (newFilters.length > 0) {
                    // alert(newFilters.length ,"    p", prevFilters.length)
                    if (newFilters.length < valprevfilter) {
                        localStorage.setItem("filterselected", newFilters.length);
                        window.location.reload();
                        // alert(newFilters.length +  "   j " + valprevfilter );
                    } else if (newFilters.length > valprevfilter) {
                        alert("increase")
                        localStorage.setItem('selectedFilters', JSON.stringify(newFilters));
                        localStorage.setItem("filterselected", newFilters.length);
                        // const filteredProducts = allProductData.filter(product => {
                        //     return newFilters.every(filter => filter.id.every(id => product._id === id));
                        // });
                        // console.log("filteredProducts========", filteredProducts);
                        // setAllProductData(filteredProducts);
                    }
                }
                // Save selected filters to localStorage
                localStorage.setItem('selectedFilters', JSON.stringify(newFilters));
                return newFilters;
            }
        });
    };



    useEffect(() => {
        // Log the IDs of all selected filters after each state update
        const selectedIds = selectedFilters.flatMap(filter => filter.id);
        console.log("Selected IDs:", selectedIds);
    }, [selectedFilters]);




















    const [productQuantities, setProductQuantities] = useState({});


    const increaseQuantity = (productId) => {
        setProductQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 0) + 1
        }));
    };

    // Function to handle decreasing the quantity for a specific product
    const decreaseQuantity = (productId) => {
        if (productQuantities[productId] && productQuantities[productId] > 0) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: prevQuantities[productId] - 1
            }));
        }
    };

    // Function to handle changing the quantity directly for a specific product
    const handleQuantityChange = (productId, event) => {
        const value = parseInt(event.target.value);
        if (!isNaN(value) && value >= 1) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: value
            }));
        }
    };







    return (
        <>

            <Navbar />




            <div className="container-fluid text-center mt-1">
                <h3 style={{ fontFamily: "poppins", fontWeight: "bold" }}>Model Description</h3>



                <p>A mobile phone is a wireless handheld device that allows users to make and receive calls.
                    While the earliest generation of mobile phones could only make and receive calls, today's mobile phones do a
                    lot more, accommodating web browsers, games, cameras, video players and navigational systems.
                </p>
            </div>





            <div className="container-fluid">
                <div className="row">



                    {/* <div className="col-lg-3">
            <div style={{ width: '100%', backgroundColor: '#F8F8F8', padding: '10px', borderRadius: '3px', overflow: 'hidden', textAlign: 'center' }}>
              <h3 style={{ marginTop: '1rem', marginBottom: '0.5rem', fontSize: '1.5rem' }}>Browse by Category</h3>
              <div style={{ fontSize: '1rem', display: 'inline-block' }}>
                <div style={{ marginBottom: '0.5rem', display: 'inline-block', verticalAlign: 'middle' }}>
                  <h6 style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '0.5rem' }}>
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={handleCheckboxChange}
                      style={{ verticalAlign: 'middle', marginRight: '4px' }}
                    />
                    Single Hardcoded
                  </h6> 
                </div>
              </div>
            </div>
          </div> */}
                    <div className="col-lg-3 col-md-2 pt-4 mb-3" style={{}}>
                        <div>
                            
                            {FilteredData && Object.entries(FilteredData).map(([filterName, filterValues]) => (
                                <div className="box w-75 my-4">
                                    <h5 className="text-black border-top pt-3">{filterName}</h5>
                                    <div className="p-2">
                                       
                                        {filterValues.map(filter => (
                                            <div key={filter.var} className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={filter.var}
                                                    checked={selectedFilters.some(selectedFilter => selectedFilter.var === filter.var)}
                                                    onClick={() => handleCheckboxClick(filter.var, filter.id)}
                                                />
                                                <label className="form-check-label" style={{textWrap: 'nowrap'}} htmlFor={filter.var}>
                                                    {filter.var}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <p style={{fontWeight: 'bold'}}>Browse by Category</p>
              <ul style={{listStyleType: 'none'}}>
                            {UniqueTypes && UniqueTypes.map((data , index)=>(
                                <>
                                    <Link to={`/AmPage3/${data}`}>  <li>{data}</li></Link>
                                </>
                            ))}
                 
              </ul> */}
                    </div>


                    <div className="col-lg-9">
                        <div className="container">
                            {allProductData && (
                                Object.entries(groupProductsByType(allProductData)).map(([type, products]) => (
                                    <div key={type}>
                                        <h3 className="" style={{fontFamily: 'poppins'}}>{type}</h3>
                                        <div className="row">
                                            {products.slice(0,4).map((card, index) => (
                                                <div key={index} className="col-6 col-sm-6 col-md-3 col-lg-3">
                                                   <Link to="/Cart">
                                                    <div className="card mb-4 align-items-center pt-4 pr-2 pl-2" style={{ cursor: "pointer", backgroundColor: "white" }}>
                                                        <div className="view overlay">
                                                            <img className="card-img-top" style={{ height: "110px", width: "110px", border: "none" }} src={getAsset(card && card.imageSubsubCatagory && card.imageSubsubCatagory[0])} alt="Card image cap" />
                                                            <a href="#!">
                                                                <div className="mask rgba-white-slight"></div>
                                                            </a>
                                                        </div>

                                                        <div className="row align-items-center pt-2">
                                {card.subsubcatagory.split(" ").length > 8 ? (
                                    <h5 style={{fontSize: '13px', lineHeight:"18px"}} className="card-text text-left">
                                      {card.subsubcatagory.split(" ").slice(0, 8).join(" ")}...
                                    </h5>
                                  ) : (
                                    <h5 style={{fontSize: '13px', lineHeight:"18px"}} className="card-text text-left">
                                      {card.subsubcatagory}
                                    </h5>
                                  )}
<div style={{ display: 'flex', alignItems: 'center', margin: "4px 0px" }}>
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
        </div>


                                  <h5 style={{fontSize: '13px'}} className="card-title text-left">£{card.wholesellerPrice}</h5>
                                           
                                           
                                  <div style={{ display: 'flex' }}>
                                    <img src="https://ik.imagekit.io/vhfsx9xkeh/Ok.png?updatedAt=1712168587135" width="8%" height="8%" />
                                    <h5 style={{fontSize: '13px', lineHeight:"18px", marginLeft: '5px'}} className="card-text text-left">
                                      In Stock
                                    </h5>
                                    </div>
                                           
                                           
                                                        </div>

                                                        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                                                            
                                                        </div>
{/* 
                                                        <div className="col-md-12 mt-3">
                                                            <div className="row align-items-center">
                                                                
                                                                <div className="col-md-12">
                                                                    <button className="btn" style={{ backgroundColor: '#DB241B', color: "white", cursor: "pointer", width: "100%" }} onClick={() => {
                                                                        handleCardClick(card, productQuantities[card._id]);
                                                                        navigate("/Cart");
                                                                    }}>Buy</button>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        </div> */}
                                                        <br />
                                                    </div>
                                                    </Link>
                                                </div>
                                            ))}
                                            {/* <Link to={`/AccessoryType/${model}/${type}`}>
                                                <button style={{ backgroundColor: '#DB241B', border: 'none', width: '430px' }} className="btn btn-primary float-right " >Show more {model} {type}</button>
                                            </Link>  */}
                                            <Link to={`/AccessoryType/${model}/${type}`}>
                                                <button style={{ backgroundColor: '#DB241B', border: 'none', width: '430px' }} className="btn btn-primary float-right">
                                                    {`${(`Show more ${model} ${type}`).split(' ').slice(0, 7).join(' ')}${(`Show more ${model} ${type}`).split(' ').length > 6 ? '...' : ''}`}
                                                </button>
                                            </Link>

                                        </div>
                                        <div style={{ width: '100%'}}><hr /></div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>




                </div>
            </div>
            <Footer />
        </>
    )
}
export default Model2; 