import React, { useEffect, useState, useRef } from "react";
import "./LandingPage.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import { Container, Row, Col } from "react-bootstrap";

import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Paper, Grid, CardContent } from "@mui/material";
import {
  TextField,
  List,
  ListItemButton,
  ListItemText,
  Popper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";


const LandingPage = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState(["Choose Options"]);
  const [options1, setOptions1] = useState(["Choose Options"]);
  const [GetAllModelsLanding, setGetAllModelsLanding] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [CardData, setcardData] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [allgetVideoData, setAllgetVideoData] = useState([]);


  const inputRef = useRef(null);
  const popperRef = useRef(null);

  // New Arrivals\

  const [NewArrivalOne, setNewArrivalOne] = useState([]);
  const [NewArrivalTwo, setNewArrivalTwo] = useState([]);
  const [NewArrivalThree, setNewArrivalThree] = useState([]);
  const [NewArrivalFour, setNewArrivalFour] = useState([]);
  const [NavbarData, setNavbarData] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [newNavData, setNewNavData] = useState();


  useEffect(() => {
    if (allgetVideoData.length > 0) {
      setSelectedVideo(allgetVideoData[0].videoLink);
    }
  }, [allgetVideoData]);

  useEffect(() => {
    gettingNavbarData()
    getAllNavbarData();
    getAllData();
    getAllVideos();
    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [])




  const handleClickOutside = (event) => {
    // Close popper if clicked outside of input and popper
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setAnchorEl(null);
    }
  };


  const getAllVideos = () => {
    axios.get(`${Env.server}/api/videoLanding/GetAllVideo`)
      .then((res) => {
        console.log("getAllDataTypes", res.data.data)
        console.log("gettingVidoes",res.data.data)
        setAllgetVideoData(res.data.data)
      })
      .catch((err) => {
        console.log("there is an error in fetching videos")
        console.log("abc====", err)
      })
  }

  // function used for getting new navbar data

  function gettingNavbarData(){
    axios.get(`${Env.server}/api/filters/getNavBarDataForTrade`)
    .then((res) => {
      console.log("getting new navbar data", res.data.navData);
      setNewNavData(res.data.navData);
    })
    .catch((err) => {
      console.log("err====", err)
    })
  }


  const getAllNavbarData = () => {
    axios.get(`${Env.server}/api/filters/gettypePermitedToShowTrade`)
      .then((res) => {
        console.log("allTypesData===", res.data.allType)
        setNavbarData(res.data.allType)
      })
      .catch((err) => {
        console.log("err====", err)
      })
  }

  const getAllData = () => {
    axios.post(`${Env.server}/api/tradenewarrival/getAll`)
      .then((res) => {
        console.log("Abresc===", res.data.data[0])
        setNewArrivalOne(res.data.data[0].One[0])
        setNewArrivalTwo(res.data.data[0].Two[0])
        setNewArrivalThree(res.data.data[0].Three[0])
        setNewArrivalFour(res.data.data[0].Four[0])
      })
      .catch((err) => {
        console.log("err====", err)
      })
  }

  const formatDate = timestamp => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // Format date as 'MM/DD/YYYY'
    const formattedTime = date.toLocaleTimeString(); // Format time as 'HH:MM:SS AM/PM'
    return `${formattedDate} ${formattedTime}`;
  };

  // const options = ['Option 1', 'Option 2', 'Option 3'];
  useEffect(() => {
    axios
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagoryByPermitedTypeTS`)
      .then((res) => {
        console.log("Abc=====", res.data.subsubcatagories);
        // setSearchedData(res.data.subsubcatagories);
        console.log("Abc=====", res.data.subsubcatagories);
        setGetAllModelsLanding(res.data.subsubcatagories);
        // subcategory-Model
        const SubCatagoryArray = res.data.subsubcatagories.map(
          (data) => data.subcatagory
        );
        const uniqueOptionsSet1 = new Set(SubCatagoryArray);
        const uniqueOptionsArray1 = Array.from(uniqueOptionsSet1);
        setOptions(uniqueOptionsArray1);
        // category-brand
        const SubCatagoryArray1 = res.data.subsubcatagories.map(
          (data) => data.catagory
        );
        const uniqueOptionsSet11 = new Set(SubCatagoryArray1);
        const uniqueOptionsArray11 = Array.from(uniqueOptionsSet11);
        setOptions1(uniqueOptionsArray11);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  }, []);

  const [cartItems, setCartItems] = useState([
    {
      id: 1,
      name: 'Product 1',
      price: 10.99,
      image: 'https://ik.imagekit.io/cy8phtesy/FONECARE%20Img.jpg?updatedAt=1712421427035',
      quantity: 1,
    },
    {
      id: 2,
      name: 'Product 2',
      price: 19.99,
      image: 'https://ik.imagekit.io/cy8phtesy/FONECARE%20Img.jpg?updatedAt=1712421427035',
      quantity: 1,
    },
    {
      id: 3,
      name: 'Product 2',
      price: 19.99,
      image: 'https://ik.imagekit.io/cy8phtesy/FONECARE%20Img.jpg?updatedAt=1712421427035',
      quantity: 1,
    },
    {
      id: 4,
      name: 'Product 2',
      price: 19.99,
      image: 'https://ik.imagekit.io/cy8phtesy/FONECARE%20Img.jpg?updatedAt=1712421427035',
      quantity: 1,
    },
    // Add more items as needed
  ]);

  const increaseQuantity = (id) => {
    const updatedCart = cartItems.map(item =>
      item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedCart);
  };

  const decreaseQuantity = (id) => {
    const updatedCart = cartItems.map(item =>
      item.id === id && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
    );
    setCartItems(updatedCart);
  };

  const uniqueSubcategories = new Set(
    GetAllModelsLanding.map((card) => card.subcatagory)
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArray = [...uniqueSubcategories];

  const uniqueSubcategoriesBrand = new Set(
    GetAllModelsLanding.map((card) => card.catagory)
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArrayBrand = [...uniqueSubcategoriesBrand];

  const handleInputChange = (event) => {
    setSearchText(event.target.value?.toLowerCase());
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const truncateTitle = (title, maxLength) => {
    const words = title.split(" ");
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(" ") + " ...";
    }
    return title;
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const selectCategory = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };

  const mainContainerStyle = {
    backgroundImage: "linear-gradient(to right, #F1DAFF, #EFBDF3)",
    // padding: '20px', // Adjust padding as needed
    // borderRadius: '10px', // Adjust border radius as needed
  };

  const [sublistContent, setSublistContent] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const [currentActive, setCurrentActive] = useState(-1);

  const handleMouseEnter = (option) => {

    const currType = newNavData && newNavData.filter(obj=>obj.type === option)[0];
    console.log('Current Item Found',currType);
    let categories = {}; // Object to store categories and their corresponding subcategories
    // Render sublist content dynamically

    let sublistContent = currType && currType.categories && currType.categories.map(({category, subcategories}) => 
      category && subcategories && category !== "undefined" ?
      (
      <Col key={category} md={4} sm={4} lg={4} xl={4} xs={4} style={{ textAlign: "initial", marginTop: '8px ' }}>
        <h5 style={{ fontWeight: "bold", fontSize: "13px", marginBottom: '0px', cursor: "pointer" }} className="sublist-headings" onClick={() => {
          setIsHovered(false);
          navigate(`/AmPage4/${option}/${category}`)
        }}>{category}</h5>
        {[...subcategories].slice(0, 5).map((subcategory, index) => ( // Convert Set to array and remove duplicates
          <li key={index} style={{ listStyle: "none", fontSize: "11px", cursor: "pointer" }} className="sublist-sub-headings" onClick={() => {
            setIsHovered(false);
            navigate(`/AccessoryType/${subcategory}/${option}`)
          }}>{subcategory}</li>
        ))}
        {[...subcategories].length >= 5 && <li style={{ listStyle: "none", fontSize: "11px", cursor: "pointer" }} className="sublist-sub-headings" onClick={() => {
          setIsHovered(false);
          navigate(`/AmPage4/${option}/${category}`)
        }}>See more</li>}
      </Col>
    ):null);

    setSublistContent(
      <Container>
        <Row className="sublist-container" style={{ width: "auto" }}>
          {sublistContent}
        </Row>
      </Container>
    );

    setIsHovered(true); // User is hovering over a list item or sublist
  };


  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setIsHovered(false); // User is not hovering over a list item or sublist
  };

  return (
    <>
      <Navbar />
      <div className="row justify-content-center bannerafternavbar" style={{}}>
        <div className="col-3 text-center pt-2 pr-0 d-none d-md-block">

          <ul className="list-group">
            {NavbarData?.slice(0, 13).map((item, index) => (
              <li
                key={index}
                className="list-group-item d-flex justify-content-between align-items-center"
                style={{
                  borderBottom: "1px solid lightgray",
                  backgroundColor: `${currentActive === index ? '#FBE9E8' : '#ffffff'}`,
                  borderLeft: "none",
                  borderRight: "none",
                  borderTop: "none",
                  fontSize: '13px',
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  cursor: "pointer"
                }}
                onMouseEnter={() => {
                  setCurrentActive(index);
                  handleMouseEnter(`${item.type}`)
                }}
                onMouseLeave={handleMouseLeave}
                onClick={() => navigate(`/AmPage3/${item.type}`)}
              >
                {item.type}
                <span
                  className="badge"
                  style={{ color: "#c5c5c5", fontSize: "13px", paddingTop: '4px', paddingBottom: '4px' }}
                >
                  &#62;
                </span>
              </li>
            ))}



          </ul>
          <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '10px' }}>
            <Link to="/AllTypes">
              <p style={{ cursor: "pointer", fontSize: "13px", color: "black", textDecoration: "underline" }}>See All</p>
            </Link>
          </div>
          {/* Sublist */}
          <div
            className="sublist"
            style={{
              display: isHovered ? "block" : "none",
              position: "absolute",
              left: "100%",
              top: 0,
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "5px",
              width: "780px",
              height: "390px",
              zIndex: 999,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {sublistContent}
          </div>
        </div>

        <div className="col-9 text-center pt-2 pr-0">
          <div className="main d-flex align-items-center justify-content-center pb-4">
            <div className="card bg-white m-3 pl-5 pr-5 banner-search-card">
              <div className="card-body">
                <h2
                  className="card-title text-start banner-card-main-text"
                  style={{
                    fontSize: "20px",
                    color: "#D42E2E",
                    fontWeight: "600",
                  }}
                >
                  Find accessories for your device
                </h2>
                <p
                  className="card-text text-start banner-card-secondary-text"
                  style={{ fontSize: "15px" }}
                >
                  Enter phone, tablet or wearable
                </p>

                <div className="input-group search-field-banner">
                  {/* <input type="text" className="form-control" placeholder="Search products" /> */}
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "400px",

                    }}
                  >
                    <TextField
                      id="search"
                      className=""
                      label="e.g. iPhone 15, Galaxy S24, iPad Pro, Apple Watch"
                      variant="outlined"
                      value={searchText}
                      onChange={handleInputChange}
                      onClick={handleClick}
                      ref={inputRef}
                      sx={{ width: "100%", border: '1px solid black', borderRadius: '10px', color: 'black', zIndex: "0" }} // Set the width of the TextField
                      InputLabelProps={{
                        style: {
                          fontStyle: "italic", // Set label to italic
                          fontSize: "11px", // Set label font size


                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon style={{ margin: "0" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Popper
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      style={{ width: "400px" }}
                      className="search-bar-popper"
                      ref={popperRef}
                    >
                      <Paper
                        style={{
                          maxHeight: "200px",
                          overflowY: "auto",
                          width: "100%",

                        }}
                      >
                        <List>
                          {options
                            .filter((option) =>
                              option?.toLowerCase().includes(searchText)
                            )
                            .map((option, index) => (
                              <Link
                                to={`/Model/brand/${option}`}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                <ListItemButton
                                  key={index}
                                  onClick={() => setSearchText(option)}

                                >
                                  <ListItemText primary={<span style={{ fontSize: "11px" }}>{option}</span>} />
                                  {/* primary={option} style={{ fontSize: "smaller" }} */}
                                </ListItemButton>
                              </Link>
                            ))}
                        </List>
                      </Paper>
                    </Popper>
                  </div>

                </div>

                <p className="card-text text-start mb-0">
                  <Link to="/AllTypes">
                    <a
                      href="#"
                      className="text-primary banner-card-secondary-text"
                      style={{
                        fontSize: "13px",
                        background: "none",
                        fontStyle: "italic",
                        textDecoration: "underline",
                      }}
                    >
                      Can't see your device?
                    </a>
                  </Link>
                </p>
                <p className="card-text text-start">
                  <Link to="/contactus">
                    <a
                      href="#"
                      className="text-primary banner-card-secondary-text"
                      style={{
                        fontSize: "13px",
                        background: "none",
                        fontStyle: "italic",
                        textDecoration: "underline",
                      }}
                    >
                      Need help identifying your device?
                    </a>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center" style={{ marginTop: "100px" }}>

        <div className="col-md-12 text-center pt-2 pr-0" >
          <div className="row justify-content-center pt-4 pb-4" style={{ backgroundColor: '#F8F6ED' }}>

            <div className="col-md-6 text-center pt-2 pr-0 pl-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', justifyContent: 'center' }}>
              <div className="responsive-video">
                {selectedVideo && (
                 <iframe width="560" height="315" src={selectedVideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                )}
              </div>
            </div>



              {/* Code for video */}

            <div className="col-md-4 pt-2 pr-0" style={{ marginLeft: '200px' }}>

              <div className="video-list" style={{ height: '400px', overflowY: 'scroll' }}>
                {allgetVideoData && allgetVideoData.map((video, index) => (
                  <div className="row video-row" key={index} onClick={() => setSelectedVideo(video.videoLink)}>
                    <div className="col-md-3">

                      <img src={getAsset(video && video.Thumbnail && video.Thumbnail[0])} style={{ width: '100%', height: "80px" }} alt="Video Thumbnail" className="img-fluid" />
                    </div>
                    <div className="col-md-9 align-left video-info">

                      <h6>{video.title}</h6>

                      <p style={{ marginBottom: '0.25rem', lineHeight: '1' }}>Channel: {video && video.channelName}</p>

                      <p style={{ marginBottom: '0.25rem', lineHeight: '1' }}>{formatDate(video && video.createdAt)}</p>
                    </div>
                  </div>
                ))}
              </div>


            </div>


          </div>

          <br /><br /><br />
          <div className="container">

            <h1 className="fw-bold text-left">New Arrivals:</h1>
            <div className="shopping-cart" style={{ marginBottom: "250px" }}>
              <div className="cart-container" style={{ display: "flex", flexWrap: "wrap" }}>

                <Link className="cart-item d-flex flex-column justify-content-between pt-4 pb-4" style={{ border: "1px solid grey", width: "calc(25% - 10px)", margin: "5px" }} to={`/AmPage3/${NewArrivalOne && NewArrivalOne.type}`}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={getAsset(NewArrivalOne && NewArrivalOne.image && NewArrivalOne.image[0])} alt={NewArrivalOne && NewArrivalOne.type} style={{ maxHeight: "auto", maxWidth: "auto" }} className="item-image image-fluid" />
                  </div>

                  <div className="item-details">
                    <h4 className="fw-bold ml-2 text-center" style={{ textAlign: "left" }}>{NewArrivalOne && NewArrivalOne.type && NewArrivalOne.type}</h4>
                    <h6 className="fw-bold ml-2 text-center" style={{ textAlign: "left" }}>{NewArrivalOne && NewArrivalOne.content && NewArrivalOne.content}</h6>
                  </div>
                </Link>
                <Link className="cart-item d-flex flex-column justify-content-between pt-4 pb-4" style={{ border: "1px solid grey", width: "calc(25% - 10px)", margin: "5px" }} to={`/AmPage3/${NewArrivalTwo && NewArrivalTwo.type}`}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={getAsset(NewArrivalTwo && NewArrivalTwo.image && NewArrivalTwo.image[0])} alt={NewArrivalTwo && NewArrivalTwo.type && NewArrivalTwo.type} style={{ maxHeight: "250px", maxWidth: "180px", padding: "10px" }} className="item-image image-fluid" />
                  </div>

                  <div className="item-details">
                    <h4 className="fw-bold ml-2 text-center" style={{ textAlign: "left" }}>{NewArrivalTwo && NewArrivalTwo.type && NewArrivalTwo.type}</h4>
                    <h6 className="fw-bold ml-2 text-center" style={{ textAlign: "left" }}>{NewArrivalTwo && NewArrivalTwo.content && NewArrivalTwo.content}</h6>

                  </div>
                </Link>
                <Link style={{ border: "1px solid grey", width: "calc(25% - 10px)", margin: "5px" }} className="cart-item d-flex flex-column justify-content-between pt-4 pb-4" to={`/AmPage3/${NewArrivalThree && NewArrivalThree.type}`}>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={getAsset(NewArrivalThree && NewArrivalThree.image && NewArrivalThree.image[0])} alt={NewArrivalThree && NewArrivalThree.type && NewArrivalThree.type} style={{ maxHeight: "250px", maxWidth: "180px", padding: "10px" }} className="item-image image-fluid" />
                  </div>

                  <div className="item-details">
                    <h4 className="fw-bold ml-2 text-center" style={{ textAlign: "left" }}>{NewArrivalThree && NewArrivalThree.type && NewArrivalThree.type}</h4>
                    <h6 className="fw-bold ml-2   text-center" style={{ textAlign: "left" }}>{NewArrivalThree && NewArrivalThree.content && NewArrivalThree.content}</h6>
                  </div>
                </Link>
                <Link className="cart-item d-flex flex-column justify-content-between pt-4 pb-4" style={{ border: "1px solid grey", width: "calc(25% - 10px)", margin: "5px" }} to={`/AmPage3/${NewArrivalFour && NewArrivalFour.type}`}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={getAsset(NewArrivalFour && NewArrivalFour.image && NewArrivalFour.image[0])} alt={NewArrivalFour && NewArrivalFour.type && NewArrivalFour.type} style={{ maxHeight: "250px", maxWidth: "180px", padding: "10px" }} className="item-image image-fluid" />
                  </div>

                  <div className="item-details">
                    <h4 className="fw-bold ml-2  text-center" style={{ textAlign: "left" }}>{NewArrivalFour && NewArrivalFour.type && NewArrivalFour.type}</h4>
                    <h6 className="fw-bold ml-2 text-center" style={{ textAlign: "left" }}>{NewArrivalFour && NewArrivalFour.content && NewArrivalFour.content}</h6>

                  </div>
                </Link>
              </div>

            </div>
          </div>
          {/* <div className="container">
            <h1 className="fw-bold text-left">New Arrivals:</h1>
            <div className="shopping-cart">
                <div className="cart-container">
                    <Link className="cart-item" to={`/AmPage3/${NewArrivalOne.type}`}>
                        <div className="image-container">
                            <img src={getAsset(NewArrivalOne.image[0])} alt={NewArrivalOne.type} className="item-image image-fluid" />
                        </div>
                        <div className="item-details">
                            <h4 className="fw-bold text-center">{NewArrivalOne.type}</h4>
                            <h6 className="fw-bold text-center">{NewArrivalOne.content}</h6>
                        </div>
                    </Link>
                    <Link className="cart-item" to={`/AmPage3/${NewArrivalTwo.type}`}>
                        <div className="image-container">
                            <img src={getAsset(NewArrivalTwo.image[0])} alt={NewArrivalTwo.type} className="item-image image-fluid" />
                        </div>
                        <div className="item-details">
                            <h4 className="fw-bold text-center">{NewArrivalTwo.type}</h4>
                            <h6 className="fw-bold text-center">{NewArrivalTwo.content}</h6>
                        </div>
                    </Link>
                    <Link className="cart-item" to={`/AmPage3/${NewArrivalThree.type}`}>
                        <div className="image-container">
                            <img src={getAsset(NewArrivalThree.image[0])} alt={NewArrivalThree.type} className="item-image image-fluid" />
                        </div>
                        <div className="item-details">
                            <h4 className="fw-bold text-center">{NewArrivalThree.type}</h4>
                            <h6 className="fw-bold text-center">{NewArrivalThree.content}</h6>
                        </div>
                    </Link>
                    <Link className="cart-item" to={`/AmPage3/${NewArrivalFour.type}`}>
                        <div className="image-container">
                            <img src={getAsset(NewArrivalFour.image[0])} alt={NewArrivalFour.type} className="item-image image-fluid" />
                        </div>
                        <div className="item-details">
                            <h4 className="fw-bold text-center">{NewArrivalFour.type}</h4>
                            <h6 className="fw-bold text-center">{NewArrivalFour.content}</h6>
                        </div>
                    </Link>
                </div>
            </div>
        </div> */}
        </div>


      </div>





      {/* <Footer/> */}




    </>
  );
};
export default LandingPage;
