import { Delete } from '@mui/icons-material';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { getAsset } from '../utils/helper';
import { useNavigate } from 'react-router-dom';
// import { get } from 'react-hook-form';


const OrderSummary = ({ setStep }) => {
    const [coupon, setCoupon] = useState('');
    const [products, setProducts] = useState([])
    const [subTotal,setSubTotal] = useState(0);
    const [shipping,setShipping] = useState(0);
    const [totalAmount,setTotalAmount] = useState(0);
    const [disabled,setDisabled] = useState(false);
    const navigate = useNavigate();
    const getItems = async () => {
        try {
            let data = await JSON.parse(localStorage.getItem('selectedProducts'))
            const deliveryCharge = JSON.parse(localStorage.getItem('deliveryCharge'));
            console.log('delivery charges ===>>>',deliveryCharge);
            setShipping(deliveryCharge);
            setProducts(data);
            console.log('Here is the data of all items in cart::', data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if(products.length > 0){
            setSubTotal(products.reduce((accumulator, prod) => accumulator + ( prod.wholesellerPrice * prod.quantity ), 0));
            const total = products.reduce((accumulator, prod) => accumulator + ( prod.wholesellerPrice * prod.quantity ), 0) + shipping;
            setTotalAmount(total);
            localStorage.setItem('totalAmount',total);
            setDisabled(false);
        }else{
            setSubTotal(0);
            setTotalAmount(0);
            setShipping(0);
            setDisabled(true);
        }
    }, [products])
    
    useEffect(() => {
        getItems();
    }, [])

    async function handleDelete(id) {
        try {
            const confirm = window.confirm("Do you really want to delete this product.")
            if (confirm) {
                const data = JSON.parse(localStorage.getItem("selectedProducts")) || [];
                const updatedProducts = data.filter(product => product._id !== id);
                localStorage.setItem("selectedProducts", JSON.stringify(updatedProducts));
                getItems();
            }
        } catch (error) {
            console.log("Problem in deleting product...")
        }
    }
    
    return (
        <div>
            <p style={{fontSize:'14px',color:'#777',marginBottom:'22px'}} >
                <span onClick={()=>navigate('/Cart3')} style={{cursor:'pointer'}} >
                ← Back
                </span>
            </p>
            <div style={{ minHeight: '80px', display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '15px' }} >
                {
                    products && 
                    products.length > 0 && products.map(product => (
                        <div style={{ display: 'flex', gap: '14px' }} key={product.id} >
                            <img src={getAsset(product.imageSubsubCatagory)} width={80} height={80} alt={product.catagory} />
                            <div>
                                <p>{product.catagory} <span>({product.quantity})</span> </p>
                                <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }} >£{product.wholesellerPrice}
                                    <span onClick={() => handleDelete(product._id)} style={{ cursor: 'pointer', marginTop: '-40px' }} ><Delete /></span>
                                </p>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '90%', margin: '12px auto' }} >
                <input
                    style={{ padding: '2px 8px', borderTopRightRadius: '3px', borderBottomRightRadius: '3px', minHeight: '50px', flex: '1', outline: '0px' }}
                    type="text" placeholder="Enter Coupon Code" value={coupon} onChange={(e) => setCoupon(e.target.value)} className="my-3" />
                <button
                    style={{ padding: '2px 15px', backgroundColor: '#0B5ED7', color: 'white', borderTopRightRadius: '3px', borderBottomRightRadius: '3px', minHeight: '50px', border: 'none' }}
                >Apply</button>
            </div>
            <ListGroup>
                <div style={{display:'flex',justifyContent:'space-between'}} ><span>SubTotal</span><span>£{subTotal}</span></div>
                <div style={{display:'flex',justifyContent:'space-between'}} ><span>Shipping Charges</span><span>£{shipping}</span></div>
            </ListGroup>
            <Row style={{ marginTop: '80px', fontSize: '26px', fontWeight: 'medium' }} >
                <Col md={9} style={{}} >Total:</Col>
                <Col md={3} >£{totalAmount.toFixed(2)}</Col>
            </Row>
            <Button variant="primary" disabled={disabled} style={{ width: '100%', border: 'none' }} className="mt-3" onClick={() => setStep(2)}>Continue</Button>
        </div>
    )
};

export default OrderSummary;
