import { combineReducers, createStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";


const reducer = combineReducers({
    auth:authSlice
})

const store = createStore(reducer);

export default store;

