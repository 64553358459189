import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Env from '../Environment/Env';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'; // Added import for react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Added import for react-toastify CSS



const Payment = ({ setStep }) => {
    const [paymentMethod, setPaymentMethod] = useState('stripe');
    const [prevData, setPrevData] = useState(null);
    const [finalProducts,setFinalProducts] = useState([]);
    const [shipping,setShipping] = useState(0);
    const [totalAmount,setTotalAmount] = useState(0);
    const [loadingP,setLoadingP] = useState(false);
    const [loadingO,setLoadingO] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [userData,setUserData] = useState(null);
    const navigate = useNavigate();

    useEffect(()=>{
        getOldData();
    },[])

    const getOldData = async()=>{
        const userData = await JSON.parse(localStorage.getItem('auth-data'));
        setUserData(userData);
        const data = await JSON.parse(localStorage.getItem('shippingData'));
        setPrevData(data);
        const productData = await JSON.parse(localStorage.getItem('selectedProducts'));
        const total = await JSON.parse(localStorage.getItem('totalAmount'));
        const deliveryCharge = JSON.parse(localStorage.getItem('deliveryCharge'));
        setPaymentMethod(data.paymentMethod);
        setShipping(deliveryCharge);
        setFinalProducts(productData);
        setTotalAmount(total.toFixed(2));
    }

    const cleanCartAndOldData = ()=>{
        localStorage.removeItem('selectedProducts');
        localStorage.removeItem('shippingData');
        localStorage.removeItem('totalAmount');
        localStorage.removeItem('deliveryCharge');
        localStorage.removeItem('totalAmount');
    }

    const processStripePayment = async () => {
        setLoadingP(true);
        try{

            const cardElement = elements.getElement(CardElement);
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
    
            if (error) {
                setLoadingP(false)
                toast.error('Fialed to process payment',{
                    position:'top-right'
                });
                console.error(error);
                return;
            }
    
            console.log('Here is the payment method', paymentMethod);
    
            const response = await axios.post(`${Env.server}/api/payment/make-payment`, {
                paymentMethodId: paymentMethod.id,
                amount: totalAmount * 100, 
                shipping:shipping * 100,
                products: finalProducts,
                customerDetails: prevData,
            });
    
            
            console.log('Here is the response ===>>>',response);
    
            if (response.status === 200) {
                const paymentIntent = response.data.paymentIntent;
                setLoadingP(false)
                await saveOrder(paymentIntent);
                console.log('Payment processed successfully');
            } else {
                setLoadingP(false)
                console.error('Failed to process payment');
                toast.error('Fialed to process payment',{
                    position:'top-right'
                });
            }
        }catch(error){
            setLoadingP(false)
            console.error('Failed to process payment');
            toast.error('Fialed to process payment',{
                position:'top-right'
            });
        }
    };

    const saveOrder = async(paymentIntent)=>{
        setLoadingO(true);
        const data = {
            paymentType:paymentMethod,
            customerName:userData.firstName,
            customerEmail:userData.email,
            customerPhone:userData.contactNumber,
            customerAddress:userData.address,
            deliverycharges:shipping,
            postalCode:userData.postcode,
            paymentStatus:paymentMethod === "stripe" ? 'paid' : 'unpaid',
            productInfo:finalProducts,
            userid:userData._id,
            paymentSessionID:paymentMethod === 'stripe' ? paymentIntent.id : ''
        }
        try {
            const res = await axios.post(`${Env.server}/api/order/create`,data);
            cleanCartAndOldData();
            console.log('Response at creating an order ==>>',res.data);
            setLoadingO(false);
            navigate('/');
        } catch (error) {
            setLoadingO(false);
            console.log('Error saving this order==>>',error);
            toast.error('Fialed to save order',{
                position:'top-right'
            });
        }
    }

    const handlePayment = async (e) => {
        e.preventDefault();

        if (paymentMethod === 'stripe') {
            await processStripePayment();
        } else {
            await saveOrder();
        }
    };

    const containerStyles = {
        border: '1px solid #d3d3d3',
        padding: '5px 10px 5px 10px',
        borderRadius: '4px',
        width: '100%', };

    const cardStyle = {
        style: {
            base: {
                border:'1px solid #888',
                color: '#32325d',
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    return (
        <div>
            <p style={{fontSize:'14px',color:'#777',marginBottom:'22px'}} >
            <span onClick={()=>setStep(prev=>prev-1)} style={{cursor:'pointer'}} >
            ← Back
            </span>
            </p>
            <h3>Payment</h3>
            <div className="mb-3">
                <div style={{display:'flex',justifyContent:'space-between'}} >
                    <h5>Delivery Address:</h5>
                    <span>{prevData && prevData.address}</span>
                </div>
                <div style={{display:'flex',justifyContent:'space-between'}} >
                    <h5>Phone Number: </h5>
                    <span>{prevData && prevData.phone}</span>
                </div>
                <div style={{display:'flex',justifyContent:'space-between'}} >
                    <h5>Total Bill: </h5>
                    <span>£{totalAmount}</span>
                </div>
            </div>
            <Form onSubmit={handlePayment} style={{margin:'30px auto'}} >
                {paymentMethod === 'stripe' && (
                    <div className="mb-3" style={containerStyles}  >
                        <CardElement options={cardStyle} />
                    </div>
                )}
                <Button type="submit" style={{width:'100%',border:'none', backgroundColor:((loadingP || loadingO) ?'green':'' )}} variant="primary" block className="mt-3">{ (!loadingP && !loadingO) ? 'Place Order': loadingP ? 'Processing Payment...' : 'Saving Order...'  }</Button>
            </Form>
            <ToastContainer />
        </div>
    );
};

export default Payment;
