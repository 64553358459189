
import React, { useState , useEffect } from "react";
// import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Col, Form, Button, Row, Container, Card } from "react-bootstrap";
import { FaSearch, FaShoppingCart, FaBars } from "react-icons/fa";
import "./Cart2.css";
import cart from "./cart.png";
import Navbar1 from "../Navbar/Navbar1"
import { useNavigate } from "react-router-dom"; 
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import Env from '../Environment/Env'
import { PubKey } from "../PubKey/PubKey";
import { getAsset } from "../utils/helper";  
import { MdCancel } from 'react-icons/md'; // Import the React Icons 
import { FaArrowLeft } from 'react-icons/fa'; 
import Footer from "../Footer/Footer";
const ResponsiveSection = () => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showCheckoutForm, setShowCheckoutForm] = useState(true);

  let navigate = useNavigate();
  const [ClickData, setClickData] = useState([]);
  const [productData, setProductData] = useState([]); // State hook to hold product data from localStorage
  const [TotalPrice, setTotalPrice] = useState(0); // State hook to hold product data from localStorage
  const [Message, setMessage] = useState(""); // State hook to hold product data from localStorage
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [DeliveryCharges, setDeliveryCharges] = useState([]);
  const [selectedOption, setSelectedOption] = useState('regular');
  const [IDLocal, setIDLocal] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // New state variable
  
 

  useEffect(() => {
    setIDLocal(localStorage.getItem("id"));
  }, [localStorage.getItem("id")]);

  // Add this useEffect to update IDLocal state upon login
  useEffect(() => {
    const login = localStorage.getItem("role");
    if (login) {
      setIDLocal(localStorage.getItem("id"));
    }
  }, []);

  useEffect(()=>{
    getDeliveryCharges();
  },[])

  const getDeliveryCharges=()=>{
    axios.get(`${Env.server}/api/deliverycharges/getAllRetail`)
    .then((res)=>{
      console.log("abc====", res.data);
      setDeliveryCharges(res.data.data);
    })
    .catch((err) => {
      console.log("err===>>>", err);
    })
  }


  const handleRemoveProduct = (index) => {
    const updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts.splice(index, 1);
    setSelectedProducts(updatedSelectedProducts);
    // Optionally, update localStorage if needed
    localStorage.setItem('selectedProducts', JSON.stringify(updatedSelectedProducts));
};

  useEffect(() => {
    const storedProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];

    // Store items into the hook
    setSelectedProducts(storedProducts);
    const parsedData = storedProducts;
    console.log("Abcd====", parsedData);
    // if (storedProducts) {
    //   setProductData(JSON.parse(storedProducts)); // Parse the data and update the state

    //   let totalPrice = parsedData.reduce((total, item) => {
    //     return total + (item.wholesellerPrice * item.quantity);
    //   }, 0);

    //   console.log("Total Price:", totalPrice);
    //   setTotalPrice(totalPrice)
    // }

  }, [])

  const removeItem = (id) => {
    const updatedProductData = productData.filter(item => item.data._id !== id);
    setProductData(updatedProductData);
    localStorage.setItem("productData", JSON.stringify(updatedProductData));
  };


  const SubmitOrder = async () => {
    console.log("TotalPrice====", TotalPrice)
    if (selectedProducts.length === 0) {
      alert("Please Add Product, Your Cart is Empty");
    }else{

      const stripe = await loadStripe(PubKey);
      console.log("abc====", selectedProducts)
      let deliveryCharge = 0;
      
      if (deliveryMethod === "standard") {
        if (TotalPrice >= 15) {
          deliveryCharge = 0
        } else {
          deliveryCharge = DeliveryCharges.standardTrade*100
        }
      } else if (deliveryMethod === "express") {
        if (TotalPrice >= 15) {
          deliveryCharge = 0
        } else {
          deliveryCharge = DeliveryCharges.expressTrade*100
        }
      }
    
    // axios
    //   .post(`${Env.server}/api/customer/order-online`, { data: selectedProducts, totalPrice: TotalPrice, deliverycharges: deliveryCharge })
    //   .then((res) => {
    //       console.log("muzzamal", res.data);
    //     const result = stripe.redirectToCheckout({
    //       sessionId: res.data.sessionId,
    //     });
    //     if (result.error) throw new Error("Stripe Payment Not Successful!");
    //   })
    //   .catch((err) => console.log(err));
    }
  }

  React.useEffect(() => {
    getDataByIDLocalStorage();
  }, [])

  // Function to retrieve data by ID from localStorage
  const getDataByIDLocalStorage = () => {
    let data = localStorage.getItem("selectedProducts");
    const parsedData = JSON.parse(data);
    console.log("Abcd====", JSON.parse(data));
    if (data) {
      setProductData(JSON.parse(data)); // Parse the data and update the state

      let totalPrice = parsedData.reduce((total, item) => {
        console.log("hhhh", item);
        return total + (item.wholesellerPrice * item.quantity);
      }, 0);

      console.log("Total Price:", totalPrice);
      setTotalPrice(totalPrice)
    }
  }


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(event.target.value);
  };

  const handleSubmit = () => {
    let login = localStorage.getItem("role");
    if (login) {
      console.log("abc=====>>>>", productData);
      let id = localStorage.getItem("id");
      let role = localStorage.getItem("role");
      let orderplaceData = {
        clientid: id,
        role: "forsale",
        name: localStorage.getItem("firstname") + " " + localStorage.getItem("lastname"),
        product: productData,
        totalprice: TotalPrice
      }
      console.log("abcbbecbeb======", orderplaceData);

      axios.post(`${Env.server}/api/order/neworder`, orderplaceData)
        .then((res) => {
          console.log("abc===>>>", res.data)
          console.log("abc===>>>", res.data.message)
          if (res.data.message === "success") {
            localStorage.removeItem("productData");
            setProductData([]);
            setTotalPrice(0);
            setMessage("Product Sell request sent Successfully. Please Check your email");
            const timer = setTimeout(() => {
              setMessage("");
              navigate("/");
            }, 5000);
            return () => clearTimeout(timer);
          }
        })
        .catch((err) => {
          console.log("err======", err);
        })

    } else {
      alert("Please Login First")
    }

  }

  const handleDelete = (index) => {
    // Create a copy of selectedProducts array
    const updatedProducts = [...selectedProducts];
    // Remove the product at the specified index
    updatedProducts.splice(index, 1);
    // Update the state with the modified array
    setSelectedProducts(updatedProducts);
    // Update the localStorage with the modified array
    localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
    window.location.reload();
  };


  const handleLoginToggle = () => {
    setShowLoginForm(!showLoginForm);
    setShowSignupForm(false);
    setShowCheckoutForm(false);
  };

  const handleSignupToggle = () => {
    setShowSignupForm(!showSignupForm);
    setShowLoginForm(false);
    setShowCheckoutForm(false);
  };

  const handleCheckoutToggle = () => {
    setShowCheckoutForm(!showCheckoutForm);
    setShowSignupForm(false);
    setShowLoginForm(false);
  };

  const [deliveryMethod, setDeliveryMethod] = useState("standard");

  const handleChange = (event) => {
    setDeliveryMethod(event.target.value);
  };

  const [data, setData] = useState({});
  const [EmailforgotPass, setEmailforgotPass] = useState("");

 

  const handleChange1 = (e) => {
    let obj = data;
    obj[e.target.name] = e.target.value;
    setData(obj);
  }

  const submitLogin = () => {
    console.log("abc====", data)
    axios.post(`${Env.server}/api/user/login`, data)
      .then((res) => {
        const resp = res.data.response;
        console.log("abc===>>>", res.data)
        localStorage.setItem("id", resp._id)
        localStorage.setItem("firstname", resp.firstname)
        localStorage.setItem("lastname", resp.lastname)
        localStorage.setItem("phNum", resp.phNum)
        localStorage.setItem("email", resp.email)
        if (resp.firstname === "admin") {
          localStorage.setItem("role", "admin"); 
          navigate("/");
        } else if (resp.firstname !== "admin") {
          localStorage.setItem("role", "seller"); 
          alert("Signin Successfull")
           // Update IDLocal state
        setIDLocal(resp._id);
        // Close the signup form
        setShowSignupForm(false);

        }
      })
      .catch((err) => {
        console.log("err===>>>", err);
      })
  }


  const forgotFun = () => {
    axios.get(`${Env.server}/api/user/forgotPassword/${EmailforgotPass}`)
      .then((res) => {
        console.log("Abc=====", res.data);
        window.open("https://mail.google.com/", "_blank");
      })
      .catch((err) => {
        console.log("err", err)
      })
  }
  const submitSignup = () => {

    let finalData = {
      ...data,
      allowemail: true,
      allowsms: true
    };
    console.log("finalData====", finalData);

    axios.post(`${Env.server}/api/user/signup`, finalData)
      .then((res) => {
        console.log("abc===>>>", res.data) 
        alert("Signup Successfull")
      })
      .catch((err) => {
        console.log("err===>>>", err);
      })
  }


  return (
  <>
      <Navbar1 />
      <Container
        fluid
        style={{ marginTop: "50px", marginBottom: "50px", paddingLeft: "50px", paddingRight: "50px" }}
      >
<Link to="/Cart1" style={{display: "block", width: '5%'}}>
          <FaArrowLeft className="faarrowww"  style={{ cursor: "pointer", fontSize: '30px', marginBottom: '20px' }} />
          </Link>
          <h2 className="ml-5 mb-0">Account</h2>
        <Row className="justify-content-between ">
          <Col lg={7}>
       
          <>
                <Card
                  style={{
                    borderRadius: "20px",
                    padding: "20px",
                    border: "2px solid #212059",
                  }}
                >
                  <h4>User Credentials</h4>
                  <p><strong>Name:</strong> {localStorage.getItem("firstname")} {localStorage.getItem("lastname")}</p>
                  <p><strong>Email:</strong> {localStorage.getItem("email")}</p>
                  <p><strong>Phone Number:</strong> {localStorage.getItem("phNum")}</p>
                  {/* <p><strong>Role:</strong> {localStorage.getItem("role")}</p> */}
                  <p><strong>Address:</strong> {localStorage.getItem("address")}</p>
                </Card>
              </>


           
          </Col>

          
              
              <div className="col-4 " style={{ padding: "0px",marginTop: '0px',backgroundColor: '#F8F6ED' }}>
          <div style={{borderRadius: '20px', paddingTop: '10px',  paddingRight: '10px',fontSize: '25px', paddingLeft: "10px", border: "2px solid lightgray"}}>
            <h5 className="text-center" style={{color: '#000000', fontWeight: 'bold', marginBottom: '7px'}}>Your order summary</h5>
            <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <p className="col-3" style={{ fontWeight: '600', marginBottom: '0', fontSize: '17px' }}>Product</p>
    <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '17px' , fontWeight:"bolder" }}>Price</p>
    <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '17px' , fontWeight:"bolder" }}>Qty</p>
    <p className="col-3" style={{ fontWeight: '500', marginBottom: '0', fontSize: '17px' , fontWeight:"bolder" }}>T.Price</p>
    <p className="col-2 hidden-element" style={{ fontWeight: '500', marginBottom: '0', fontSize: '7px' , fontWeight:"bolder", visibility: 'hidden' }}>Price</p> 

  </div>
  {selectedProducts.map((item, index) => (
    <>
        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p className="col-3" style={{ fontWeight: '600', marginBottom: '0', fontSize: '13px' }}>
            {item?.subsubcatagory}
            {item.selectedColor && <span style={{fontWeight:'normal',borderRadius:'3px',background:'#fff'}} >{` (${item.selectedColor})`}</span>}
            </p>
          <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '13px' }}>£{item.wholesellerPrice}</p>
          <p className="col-2" style={{ fontWeight: '600', marginBottom: '0', fontSize: '13px' }}>{item?.quantity}</p>
          <p className="col-3" style={{ fontWeight: '500', marginBottom: '0', fontSize: '13px' }}>£{(item.wholesellerPrice * item?.quantity).toFixed(2)}</p>
          <MdCancel className="col-2" onClick={() => handleRemoveProduct(index)} style={{fontSize: '20px', color: 'red', cursor: 'pointer',  margin: 'auto' }} />
        </div>
        <hr/>
    </>
      ))}

                
               
                <br/> 
                
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ fontWeight: '600', marginBottom: '0', fontSize: '13px' }}>Total Price</p>
        <p style={{ fontWeight: '500', marginBottom: '0', fontSize: '13px' }}>
          £{selectedProducts?.reduce((total, item) => total + parseFloat(item?.wholesellerPrice * item?.quantity), 0).toFixed(2)}
        </p>
      </div>
        <br/>
                
                <Link to="/Cart3">
                <button
                    style={{
                      color: "black",
                      borderRadius: '50px',
                      backgroundColor: '#F8D803',
                      fontSize: "10px", 
                      padding: '10px 10px 10px 10px',
                      width: '70%',
                      marginTop: '5px',
                      border: 'none',
                      fontWeight: 'bold',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'auto',
                      marginBottom: '8px',
                      alignItems: 'center'
                      
                    }}
                  >
                    Continue to checkout
                </button>
                </Link>
                
             
            </div>
          </div>
            
          </div>  

          {/* <Col lg={4} className="mt-lg-0">
            <Card
              style={{
                borderRadius: "20px",
                paddingTop: "10px",
                marginTop: "0px",
                border: "2px solid #212059",
              }}
            >
              <Card.Body>
                <Card.Title className="fw-bold mb-3">Delivery Method</Card.Title>
                <Form>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Form.Check
                        type="radio"
                        
                        label="Standard"
                        id="standard"
                        name="deliveryMethod"
                        value="standard"
                        checked={deliveryMethod === "standard"}
                        onChange={handleChange}
                        className="custom-radio"
                      />
                      <p
                        style={{
                          color: "#5F6062",
                          textAlign: "center",
                          paddingLeft: "25px",
                        }}
                      >
                        1-3 Days Delivery
                      </p>
                    </div>


                    <div className="pr-4">
                    
                      {TotalPrice >= 15 ? (
                        <>
                          <h5 className="fw-bold">£{TotalPrice}</h5>
                        </>
                      ) : (
                        <>
                            <h5 className="fw-bold">£{((TotalPrice) + (DeliveryCharges && DeliveryCharges.standardTrade)).toFixed(2)}</h5>
                        </>
                      )} 



                   
                    
                    
                    </div>


                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{}}>
                      <Form.Check
                        type="radio"
                      
                        label="Express"
                        id="express"
                        name="deliveryMethod"
                        value="express"
                        checked={deliveryMethod === "express"}
                        onChange={handleChange}
                        className="custom-radio"
                      />
                      <p
                        style={{
                          color: "#5F6062",
                          textAlign: "center",
                          paddingLeft: "25px",
                        }}
                      >
                        Next day delivery
                      </p>
                    </div>
                    <div className="pr-4">
                      {TotalPrice >= 15 ? (
                        <>
                          <h5 className="fw-bold">£{TotalPrice}</h5>
                        </>
                      ) : (
                        <>
                            <h5 className="fw-bold">£{((TotalPrice) + (DeliveryCharges && DeliveryCharges.expressTrade)).toFixed(2)}</h5>
                        </>
                      )} 

                    </div>
                  </div>
                </Form>
                <p className="text-center fw-bold" style={{ color: "#DB241B" }}>
                  Free delivery up-to £15.00
                </p>
              </Card.Body>
            </Card>
            <Card
              className="mt-0"
              style={{
                borderRadius: "20px",
                padding: "10px",
                border: "2px solid #212059",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                 
                  <div className="mr-2">
                    <img src={cart} alt="cart" width="30px" height="30px" />
                  </div>
                  <div>
                    <p className="fw-bold mb-0">Order Total</p>
                   <Link to="/Cart1">
                      <p
                        style={{
                          marginBottom: "0px",
                          textDecoration: "underline",
                          color: "#DB241B",
                          fontStyle: "italic",
                        }}
                      >
                        Details
                      </p>
                   </Link>
                  </div>
                </div>
                <div className="pr-4">
                  {deliveryMethod === "standard" && TotalPrice >= 15 ? (
                    <>
                      <h5 className="fw-bold">£{TotalPrice}</h5>
                    </>
                  ) : deliveryMethod === "standard" && TotalPrice < 15 ? (
                    <>
                        <h5 className="fw-bold">£{((TotalPrice) + (DeliveryCharges && DeliveryCharges.standardTrade)).toFixed(2)}</h5>
                    </>
                  ): deliveryMethod === "express" && TotalPrice >= 15 ? (
                    <>
                          <h5 className="fw-bold">£{TotalPrice + 0}</h5>
                    </>
                  ): deliveryMethod === "express" && TotalPrice < 15 ? (
                          <>
                            <h5 className="fw-bold">£{((TotalPrice) + (DeliveryCharges && DeliveryCharges.expressTrade)).toFixed(2)}</h5>
                          </>
                  ):(
                    <>
                            <h5 className="fw-bold">£{TotalPrice}</h5>
                    </>
                  )}
                </div>
              </div>
            
            </Card>
          </Col> */}
        </Row>
      </Container>
      <Footer />
  </>
  );
};


export default ResponsiveSection;
