import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css"; // Import CSS file containing styles for the component
import axios from "axios";
import Env from "../Environment/Env";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

// import logo from "./logo.png";
import {
  TextField,
  List,
  ListItemButton,
  ListItemText,
  Popper,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Paper, Grid, Card, CardContent } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { FaSearch, FaShoppingCart, FaBars } from "react-icons/fa";




const Main = () => {
  const navigate = useNavigate();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [AccessoriesData, setAccessoriesData] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);

  const [options, setOptions] = useState(["Choose Options"]);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);



  const inputRef = useRef(null);
  const popperRef = useRef(null);

  const [showModal, setShowModal] = useState(false);

  const handleTrackOrderClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    GetAllSubSubCatagory();
    getNavBarData();
    GetAllTypes();
    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const handleClickOutside = (event) => {
    // Close popper if clicked outside of input and popper
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setAnchorEl(null);
    }
  };



  const GetAllTypes = () => {
    axios
      .get(`${Env.server}/api/filters/gettypePermitedToShow`)
      .then((res) => {
        let resp = res.data.allType;
        console.log('rana1', resp)
        // Extracting unique types
        // const types = Array.from(new Set(resp.map(item => item.typeName)));
        // console.log("types====", resp)
        const typesArray = resp.map((item) => item.type);

        console.log("typesArray====", typesArray);
        setUniqueTypes(typesArray);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const GetAllSubSubCatagory = () => {
    // axios
    //   .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
    //   .then((res) => {
    //     // let resp = res.data.subsubcatagories;
    //     // const CatagoryArray = res.data.subsubcatagories.map(
    //     //   (data) => data.catagory
    //     // );
    //     // const SubCatagoryArray = res.data.subsubcatagories.map(
    //     //   (data) => data.subcatagory
    //     // );
    //     // const SubSubCatagoryArray = res.data.subsubcatagories.map(
    //     //   (data) => data.subsubcatagory
    //     // );

    //     // Create a Set from the CatagoryArray
    //     const uniqueOptionsSet = new Set(CatagoryArray);
    //     const uniqueOptionsArray = Array.from(uniqueOptionsSet);

    //     // Create a Set from the CatagoryArray
    //     const uniqueOptionsSet1 = new Set(SubCatagoryArray);
    //     const uniqueOptionsArray1 = Array.from(uniqueOptionsSet1);

    //     // Create a Set from the CatagoryArray
    //     const uniqueOptionsSet2 = new Set(SubSubCatagoryArray);
    //     const uniqueOptionsArray2 = Array.from(uniqueOptionsSet2);
    //     setOptions(uniqueOptionsArray);
    //     setOptions1(uniqueOptionsArray1);
    //     setOptions2(uniqueOptionsArray2);
    //   })
    //   .catch((err) => {
    //     console.log("err====", err);
    //   });
  };

  const handleInputChange = (event) => {
    setSearchText(event?.target?.value?.toLowerCase());
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };



  const [NewArrivalOne, setNewArrivalOne] = useState([]);
  const [NewArrivalTwo, setNewArrivalTwo] = useState([]);
  const [NewArrivalThree, setNewArrivalThree] = useState([]);
  const [NewArrivalFour, setNewArrivalFour] = useState([]);
  const [GetAllModelsLanding, setGetAllModelsLanding] = useState([]);





  const [sublistContent, setSublistContent] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [NavbarData, setNavbarData] = useState([]);



  useEffect(() => {
    getAllNavbarData();
    getAllData();
  }, [])


  const getAllNavbarData = () => {
    axios.get(`${Env.server}/api/filters/gettypePermitedToShowTrade`)
      .then((res) => {
        console.log("allTypesData===", res.data.allType)
        setNavbarData(res.data.allType)

      })
      .catch((err) => {
        console.log("err====", err)
      })
  }

  const getAllData = () => {
    axios.post(`${Env.server}/api/tradenewarrival/getAll`)
      .then((res) => {
        console.log("Abresc===", res.data.data[0])
        setNewArrivalOne(res.data.data[0].One[0])
        setNewArrivalTwo(res.data.data[0].Two[0])
        setNewArrivalThree(res.data.data[0].Three[0])
        setNewArrivalFour(res.data.data[0].Four[0])
      })
      .catch((err) => {
        console.log("err====", err)
      })
  }


  // const options = ['Option 1', 'Option 2', 'Option 3'];
  useEffect(() => {
    axios
      // .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagoryByPermitedTypeTS`)
      .then((res) => {
        console.log("Abc=====", res.data.subsubcatagories);
        // setSearchedData(res.data.subsubcatagories);
        console.log("Abc=====", res.data.subsubcatagories);


        setGetAllModelsLanding(res.data.subsubcatagories);

        // subcategory-Model
        const SubCatagoryArray = res.data.subsubcatagories.map(
          (data) => data.subcatagory
        );
        const uniqueOptionsSet1 = new Set(SubCatagoryArray);
        const uniqueOptionsArray1 = Array.from(uniqueOptionsSet1);
        setOptions(uniqueOptionsArray1);

        // category-brand

        const SubCatagoryArray1 = res.data.subsubcatagories.map(
          (data) => data.catagory
        );
        const uniqueOptionsSet11 = new Set(SubCatagoryArray1);
        const uniqueOptionsArray11 = Array.from(uniqueOptionsSet11);
        setOptions1(uniqueOptionsArray11);



      })
      .catch((err) => {
        console.log("err====", err);
      });
  }, []);

  const uniqueSubcategories = new Set(
    GetAllModelsLanding.map((card) => card.subcatagory)
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArray = [...uniqueSubcategories];

  const uniqueSubcategoriesBrand = new Set(
    GetAllModelsLanding.map((card) => card.catagory)
  );

  // Convert Set back to an array
  const uniqueSubcategoriesArrayBrand = [...uniqueSubcategoriesBrand];

  const getNavBarData = async () => {
    axios.get(`${Env.server}/api/filters/getNavBarDataForTrade`)
      .then((res) => {
        setNewNav(res.data.navData)
        console.log("rana navbar data received",res.data.navData)
        console.log('Navbar data recieved==>>>', res.data);
      })
      .catch((err) => {
        console.log('Error loading navbar data==>>', err);
      })
  }

  // Function to handle hover over a category
  const handleMouseEnter = (option) => {
    let TypeData = newNav.filter(
      (item) => item.type === option
    );
    let sublistContent = TypeData[0].categories && TypeData[0].categories.map(({ category, subcategories }) => {
        return (
          category && category !== 'undefined' ?
          <Col key={category} md={4} sm={4} lg={4} xl={4} xs={4} style={{ textAlign: "initial", marginTop: '8px' }}>
            <h5
              style={{ fontWeight: "bold", fontSize: "13px", marginBottom: '0px', cursor: "pointer" }}
              className="sublist-headings"
              onClick={() => {
                setIsHovered(false);
                navigate(`/AmPage4/${option}/${category}`);
              }}>
              {category}
            </h5>
            {subcategories && subcategories.slice(0, 5).map((subcategory, index) => (
              <li
                key={index}
                style={{ listStyle: "none", fontSize: "11px", cursor: "pointer" }}
                className="sublist-sub-headings hover-sublist"
                onClick={() => {
                  setIsHovered(false);
                  navigate(`/AccessoryType/${subcategory}/${option}`);
                }}>
                {subcategory}
              </li>
            ))}
            {
              [...subcategories].length > 5 && <li style={{ listStyle: "none", fontSize: "11px", cursor: "pointer" }} className="sublist-sub-headings" onClick={() => {
                setIsHovered(false);
                navigate(`/AmPage4/${option}/${category}`)
              }}>See more</li>
            }
          </Col>
        :null)
    });
    setSublistContent(
      <Container>
        <Row className="sublist-container">
          {sublistContent}
        </Row>
      </Container>
    );

    setIsHovered(true); //
  };


  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setIsHovered(false); // User is not hovering over a list item or sublist
  };


  const [showSidebar, setShowSidebar] = useState(false);
  const [currentActive, setCurrentActive] = useState(-1);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const [newNav, setNewNav] = useState()
  const [isSideBar, setIsSideBar] = useState(false)
  const [expandedItems, setExpandedItems] = useState([]);
  const [subMenu, setSubMenu] = useState([]);
  const toggleItem = (index) => {
    setExpandedItems(prev => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    });
  };
  const toggleSubMenu = (index) => {
    setSubMenu(prev => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    })
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-center" style={{ gap: '25px', padding: '10px', backgroundColor: '#F8F6ED' }}>


        <Link to="/">
          <p href="#action1" style={{ marginBottom: '0px' }}>Home</p>
        </Link>
        <Link to='/AllTypes'>
          <p href="#action2" style={{ marginBottom: '0px' }}>Shop</p>
        </Link>
        <Link to="/aboutus">
          <p href="#" disabled style={{ marginBottom: '0px' }}>
            About Us
          </p>
        </Link>
        <Link to="/contactus">
          <p href="#action2" style={{ marginBottom: '0px' }}>Contact Us</p>
        </Link>

      </div>

      <Navbar expand="lg" className="" style={{ borderBottom: '1px solid lightgray', backgroundColor: 'white', overflow: 'hidden' }}>
        <Container fluid>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Nav className="ml-auto">
              <div style={{ cursor: 'pointer', padding: '10px' }} className="d-block d-md-none"
                onClick={() => {
                  console.log("clicking done")
                  setIsSideBar((prev) => !prev)
                }}
              >
                <FaBars size={30} color="black" />
              </div>
            </Nav>
            <Navbar.Brand href="/">
              <img
                src={Env.picLogo}
                width="130px"
                height="auto"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
           {/* from here our mobile navbar code  started */}
           <div className={`mobileSidebar ${isSideBar ? 'show' : ''}`}>
              <div className="" style={{ display: 'flex', justifyContent: 'end', padding: '20px' }}>
                <p style={{ fontSize: '25px', color: 'white' }}
                  onClick={() => setIsSideBar(false)}
                >X</p>
              </div>
              <div className="sidebar-content">
                {newNav && newNav.map((item, index) => (
                  <div key={index} className={`item ${expandedItems[index] ? 'expanded' : 'collapsed'}`}>
                    <div className="header" >
                      <h1
                        onClick={() => {
                          setIsSideBar(false)
                          navigate(`/AmPage3/${item.type}`)
                        }}
                      >{item.type}</h1>
                      <p style={{ fontSize: '30px' }} onClick={() => toggleItem(index)}
                      >{expandedItems[index] ? '-' : '+'}
                      </p>
                    </div>
                    <div className={`sub-content ${expandedItems[index] ? 'show' : 'hide'}`}>
                      {item.categories.length > 0 && item.categories.map((data, subIndex) => (
                        <div key={subIndex} className="category">
                          <div className="category-header" >
                            <p
                              onClick={() => {
                                setIsSideBar(false)
                                navigate(`/AmPage4/${item.type}/${data.category}`)
                              }}
                            >{data.category}</p>
                            <p style={{ fontSize: '23px' }} onClick={() => toggleSubMenu(subIndex)}>{subMenu[subIndex] ? '-' : '+'}</p>
                          </div>
                          <div className={`sub-sub-content ${subMenu[subIndex] ? 'show' : 'hide'}`}>
                            { data.subcategories.length > 0 && data.subcategories.map((category, subSubIndex) => (
                              <p key={subSubIndex}
                                onClick={() => {
                                  setIsSideBar(false)
                                  navigate(`/AccessoryType/${category}/${item.type}`)
                                }}
                              >{category}</p>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

            </div>
          <Navbar.Collapse id="navbarScroll">


            <div className="d-none d-lg-flex justify-content-center align-items-center flex-grow-1" >
              <div className="input-group search-bar-lg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }} >
                <input
                  style={{ border: '1px solid black', fontStyle: 'italic', color: 'darkgray' }}
                  type="text"
                  className="form-control"
                  placeholder="By device, brand or item"
                  value={searchText}
                  onChange={handleInputChange}
                  onClick={handleClick}
                  ref={inputRef}
                />
                <div className="input-group-append">
                  <span className="input-group-text" style={{ border: "1px solid black" }}>
                    <i className="bi bi-search"></i>
                  </span>
                </div>
              </div>
              <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                style={{ width: "400px" }}
                ref={popperRef}
              >
                <Paper
                  style={{
                    maxHeight: "250px",
                    overflowY: "auto",
                    width: "auto",
                  }}
                >
                  <List>
                    {/* Assuming options2, options1, and options are defined */}
                    <ListItemButton disabled>
                      <ListItemText primary="Accessories" />
                    </ListItemButton>
                    {options2
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/AwmPageB/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Models" />
                    </ListItemButton>
                    {options1
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/Model/brand/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Brand" />
                    </ListItemButton>
                    {options
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/BrandPage/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                  </List>
                </Paper>
              </Popper>
            </div>

            {/* Search Bar for Mobile */}
            <div className="d-lg-none flex-grow-1 ml-lg-4">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="By device, brand or item"
                  value={searchText}
                  onChange={handleInputChange}
                  onClick={handleClick}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="bi bi-search"></i>
                  </span>
                </div>
              </div>
              <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                style={{ width: "400px" }}
              >
                <Paper
                  style={{
                    maxHeight: "250px",
                    overflowY: "auto",
                    width: "auto",
                  }}
                >
                  <List>
                    {/* Assuming options2, options1, and options are defined */}
                    <ListItemButton disabled>
                      <ListItemText primary="Accessories" />
                    </ListItemButton>
                    {options2
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/AwmPageB/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Models" />
                    </ListItemButton>
                    {options1
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/Model/brand/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Brand" />
                    </ListItemButton>
                    {options
                      .filter((option) =>
                        option?.toLowerCase().includes(searchText?.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/BrandPage/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                  </List>
                </Paper>
              </Popper>
            </div>
            <Form className="d-flex align-items-center m-auto" style={{ position: 'relative' }}>
              {/* <input
  type="text"
  placeholder="Search"
  style={{ paddingRight: '30px' }} 
  className="form-control mr-2"
/>
<span style={{ position: 'absolute', top: '50%', right: '20px', transform: 'translateY(-50%)' }}>
  <i className="fa fa-search"></i>
</span> */}

              {/* <div className="d-flex justify-content-center align-items-center flex-grow-1">
      <div className="input-group search-bar-lg" style={{margin: '0px'}}>
        <input
          type="text"
          className="form-control"
          placeholder="By device, brand or item"
          value={searchText}
          onChange={handleInputChange}
          onClick={handleClick}
        />
        <div className="input-group-append">
          <span className="input-group-text">
            <i className="bi bi-search"></i>
          </span>
        </div>
      </div>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        style={{ width: "400px" }}
      >
        <Paper
          style={{
            maxHeight: "250px",
            overflowY: "auto",
            width: "auto",
          }}
        >
          <List>
            {/* Assuming options2, options1, and options are defined */}
              {/* <ListItemButton disabled>
              <ListItemText primary="Accessories" />
            </ListItemButton>
            {options2
              .filter((option) =>
                option?.toLowerCase().includes(searchText?.toLowerCase())
              )
              .map((option, index) => (
                <Link
                  to={`/AwmPageB/${option}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                  key={index}
                >
                  <ListItemButton onClick={() => setSearchText(option)}>
                    <ListItemText primary={option} />
                  </ListItemButton>
                </Link>
              ))}
            <ListItemButton disabled>
              <ListItemText primary="Models" />
            </ListItemButton>
            {options1
              .filter((option) =>
                option?.toLowerCase().includes(searchText?.toLowerCase())
              )
              .map((option, index) => (
                <Link
                  to={`/Model/brand/${option}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                  key={index}
                >
                  <ListItemButton onClick={() => setSearchText(option)}>
                    <ListItemText primary={option} />
                  </ListItemButton>
                </Link>
              ))}
            <ListItemButton disabled>
              <ListItemText primary="Brand" />
            </ListItemButton>
            {options
              .filter((option) =>
                option?.toLowerCase().includes(searchText?.toLowerCase())
              )
              .map((option, index) => (
                <Link
                  to={`/BrandPage/${option}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                  key={index}
                >
                  <ListItemButton onClick={() => setSearchText(option)}>
                    <ListItemText primary={option} />
                  </ListItemButton>
                </Link>
              ))}
          </List>
        </Paper>
      </Popper>
    </div>  */}
              <Link to={"/UserDashboad"}>


                <div className='col-7 button-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: 'auto' }}>
                  <button
                    style={{
                      color: "black",
                      borderRadius: '50px',
                      backgroundColor: '#F8D803',
                      fontSize: "10px",
                      padding: '10px 10px 10px 10px',
                      width: '250px',
                      marginTop: '5px',
                      border: 'none',
                      fontWeight: 'bold',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'auto',
                      marginBottom: '8px',
                      alignItems: 'center'

                    }}
                  >
                    Manage my account
                  </button>
                </div>

              </Link>

            </Form>
            <div className="position-relative mr-2 cart-icon-lg"  >
              <Link to={"/Cart1"} className="nav-link" >
                <i className="bi bi-cart" ></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {(JSON.parse(localStorage.getItem("selectedProducts")) &&
                    JSON.parse(localStorage.getItem("selectedProducts"))
                      .length) ||
                    0}
                  <span className="visually-hidden">items in cart</span>
                </span>
              </Link>
            </div>
            {/* <span style={{ marginLeft: '10px', fontSize: '25px'  }}>
<i className="fa fa-shopping-cart"></i>
</span> */}
            {/* <span style={{ marginLeft: '10px', fontSize: '25px'  }}>
<i className="fa fa-user" ></i>
</span> */}
            {localStorage.getItem("role") === "admin" ? (
              // If user role is admin
              <>
                <button
                  onClick={() =>
                  (window.location.href =
                    `${Env.dashboard}`)
                  }
                  className="text-center text-black"
                  style={{
                    // backgroundColor: "#F8F9FA",
                    background: 'none',
                    // border: "1px solid #DEE2E6",
                    border: 'none',
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {localStorage.getItem("id") ? (
                    <span style={{ marginLeft: '10px', fontSize: '25px' }}>
                      <i className="fa fa-user"></i>
                    </span>
                  ) : (
                    <img
                      src="https://ik.imagekit.io/vhfsx9xkeh/login.png?updatedAt=1713536012173"
                      alt="Login/Signup"
                      style={{ width: '25px', height: '25px', marginLeft: '10px' }}
                    />
                  )}
                </button>
              </>
            ) : (
              // If user role is not admin
              <>
                <Link to={"/UserDashboad"}>
                  <button
                    className="text-center text-black"
                    style={{
                      // backgroundColor: "#F8F9FA",
                      background: 'none',
                      // border: "1px solid #DEE2E6",
                      border: 'none',

                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    {localStorage.getItem("id") ? (
                      <span style={{ marginLeft: '10px', fontSize: '25px' }}>
                        <i className="fa fa-user"></i>
                      </span>
                    ) : (
                      <img
                        src="https://ik.imagekit.io/vhfsx9xkeh/login.png?updatedAt=1713536012173"
                        alt="Login/Signup"
                        style={{ width: '25px', height: '25px', marginLeft: '10px' }}
                      />
                    )}
                  </button>
                </Link>
              </>
            )}


          </Navbar.Collapse>
        </Container>
      </Navbar>



      <div className="app d-none d-md-block">
        <header>
          <div className="" >
            <div className="hamburger-icon" onClick={toggleSidebar} style={{ width: '5%' }}>
              <div className="line" ></div>
              <div className="line" ></div>
              <div className="line" ></div>
            </div>
          </div>
        </header>

         {/* sidebar starts from here */}

        {showSidebar && (
          <div className="sidebar">
            <div className="row bannerafternavbar" style={{ padding: '0px' }}>
              <div className="col-12 text-center pt-2 pr-0">
                <ul className="list-group">
                  {NavbarData?.slice(0, 13).map((item, index) => (
                    <li
                      key={index}
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{
                        borderBottom: "1px solid lightgray",
                        backgroundColor: `${currentActive === index ? "#FBE9E8" : "white"}`,
                        borderLeft: "none",
                        borderRight: "none",
                        borderTop: "none",
                        fontSize: '13px',
                        paddingTop: '4px',
                        paddingBottom: '4px',
                        cursor: "pointer"
                      }}
                      onMouseEnter={() => {
                        setCurrentActive(index);
                        handleMouseEnter(`${item.type}`)
                      }}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => {
                        setShowSidebar(false)
                        navigate(`/AmPage3/${item.type}`)}}
                    >
                      {item.type}
                      <span
                        className="badge"
                        style={{ color: "#c5c5c5", fontSize: "13px", paddingTop: '4px', paddingBottom: '4px' }}
                      >
                        &#62;
                      </span>
                    </li>
                  ))}
                </ul>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '10px' }}>
                  <Link to="/AllTypes">
                    <p style={{ cursor: "pointer", fontSize: "13px", color: "black", textDecoration: "underline" }}>See All</p>
                  </Link>
                </div>

                {/* Sublist */}

                <div
                  className="sublist"
                  style={{
                    display: isHovered ? "block" : "none",
                    position: "absolute",
                    top: 0,
                    left: "100%",
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    padding: "5px",
                    Height: "390px",
                    width: "780px",
                    // height: "390px",
                    // overflowY: "auto",
                    zIndex: 999,
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {sublistContent}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
   {/* here i am deleting the perivious sidebar */}



























      {/* <main className="cd__main">
        <header className={isMenuOpen ? "dark" : "dark"}>
          <nav role="navigation">
            <a
              href="javascript:void(0);"
              className={`ic menu ${isMenuOpen ? "hidden" : ""}`}
              onClick={toggleMenu}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </a>
            <a
              href="javascript:void(0);"
              className={`ic close ${isMenuOpen ? "" : "hidden"}`}
              onClick={toggleMenu}
            ></a>
            <ul style={{margin: '0px', maxWidth: "100%", display: 'flex', justifyContent: 'center'}} className={`main-nav ${isMenuOpen ? "open" : ""}`}>
              {AccessoriesData?.slice(0, 6).map((data, index) => (
                <React.Fragment key={index}>
                  <li className="top-level-link">
                    <Link
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/AmPage3/${uniqueTypes[index]}`);
                        window.location.reload();
                      }}
                      className="mega-menu"
                    >
                      <span>{uniqueTypes[index]}</span>
                    </Link>
                    <div className="sub-menu-block">
                      <div className="row">
                        {options.map((item, index1) => {
                          // Filter data based on category and type
                          const filteredData = AccessoriesData.filter(
                            (item1) =>
                              item1.catagory === item &&
                              item1.typeName === uniqueTypes[index]
                          );

                          // Check if filtered data is not empty
                          if (filteredData.length > 0) {
                            // Remove duplicates
                            const uniqueSubcategories = [
                              ...new Set(
                                filteredData.map((item) => item.subcatagory)
                              ),
                            ];
                            return (
                              <div
                                className="col-md-4 col-lg-4 col-sm-4"
                                key={index1}
                              >
                                <h2
                                  className="sub-menu-head"
                                  style={{ cursor: "pointer" }}
                                >
                                  <Link
                                    to={`/AmPage4/${uniqueTypes[index]}/${item}`}
                                  >
                                    {item} {uniqueTypes[index]}
                                  </Link>
                                </h2>
                                <ul className="sub-menu-lists">
                                  {uniqueSubcategories.slice(0,7).map(
                                    (subCategory, subIndex) => (
                                      <li
                                        key={subIndex}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Link
                                          to={`/AccessoryType/${encodeURIComponent(
                                            subCategory
                                          )}/${uniqueTypes[index]}`}
                                        >
                                          {" "}
                                          {subCategory} {uniqueTypes[index]}{" "}
                                        </Link>
                                      </li>
                                    )
                                  )}
                                  <Link to="/AccessoryType/IPhone13/ScreenProtector">
                                        <p style={{fontWeight: '500', textDecoration: 'underline'}}>See More</p>
                                 </Link>
                                </ul>
                              </div>
                            );
                          } else {
                            return null; // Don't render if filtered data is empty
                          }
                        })}
                      </div>
                    </div>
                  </li>
                </React.Fragment>
              ))}
            </ul>
            
          </nav>
        </header>
      </main> */}













    </>
  );
};

export default Main;