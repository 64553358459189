import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar"; 
import Slider from "../Slider/Slider";
import LandingPage from "../LandingPage/LandingPage";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuthData } from "../store/authSlice";
const Home = () => {
  const navigate = useNavigate();
    const dispatch = useDispatch();

    const verifyLogin = async() =>{
      let data = localStorage.getItem('auth-data');
      data = await JSON.parse(data);
      console.log('Auth Data found ==>> ',data);
      if(!data){
        navigate('/TradeSignin');
      }else{
        dispatch(setAuthData(data));
      }
    }

    useEffect(()=>{
      verifyLogin();
      localStorage.removeItem("filterselected");
      localStorage.removeItem("priceFilter");
      localStorage.removeItem("selectedFilters");    
    },[])


  return (
    <>
      <div className="container-fluid pl-0 pr-0" style={{overflow:'hidden'}}>
        {/* <Navbar /> */} 
        <LandingPage />
        {/* <Slider /> */}
        <Footer />
      </div>
    </>
  )
}
export default Home; 