// EditableCard.js

import React, { useState } from 'react';

const Useremail = () => {
  const [email, setEmail] = useState('shahananwar39@gmail.com');
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // Implement logic to save changes (e.g., update state, make API calls)

    // For simplicity, just update the email with the new email
    setEmail(newEmail);
  };

  return (
    <div style={{ position: 'relative', width: '100%', maxWidth: '720px', padding: '20px', borderRadius: '16px', margin: '20px auto', backgroundColor: 'white', border: '1px solid #ccc' }}>
      <div style={{ marginBottom: '20px' }}>
        <h3>Email Info</h3>
      </div>
      
        <div style={{ marginBottom: '20px' }}>
          <label style={{ marginBottom: '5px' }}>Email</label>
          <div style={{ padding: '8px', borderRadius: '5px', backgroundColor: 'white' }}>{localStorage.getItem("email")}</div>
        </div> 
    </div>
  );
};

export default Useremail;
