import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import './Verify.css';
import { useParams } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import { Link } from "react-router-dom"; 

const Verify = () => { 
    const { id } = useParams();

     if(id){
         axios.patch(`${Env.server}/api/user/updateData/${id}`)
         .then((res) => {
            console.log("res===>>>", res.data);
         })
         .catch((err) => {
             console.log("abc=====", err);
         })
     }

    return (
        <>
            <Navbar />
            <div class="success-animation">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                <center><p className="mt-3"><b>Verified Successfuly</b></p></center>
            </div>
            <Footer />
        </>
    )
}
export default Verify;