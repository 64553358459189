import React from 'react';
import Navbar1 from "../Navbar/Navbar1";
import Footer from "../Footer/Footer";
import Env from '../Environment/Env';
function MobileBitz() {
  return (
    <>
    <Navbar1 />
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-12">
          <h2>Welcome to {Env.businessName}</h2>
          <p>Your ultimate destination for premium mobile accessories, exceptional customer service, and cutting-edge technology solutions. With over 50 branches across the United Kingdom, {Env.businessName} stands as one of the largest and most trusted mobile accessory retailers in the country.</p>
        </div>
      </div>
      
      <div className="row">
        <div className="col-12">
          <h3>Our Mission</h3>
          <p>At {Env.businessName}, we are dedicated to providing our customers with the latest and highest-quality mobile accessories to enhance their mobile experience. Our mission is to offer a comprehensive range of products and services that cater to the diverse needs and preferences of our customers while maintaining the highest standards of quality, innovation, and customer satisfaction.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h3>Our Products and Services</h3>
          <p>{Env.businessName} offers an extensive selection of mobile accessories, including cases, screen protectors, chargers, cables, headphones, and more, sourced from leading brands and manufacturers. Whether you're looking for stylish protection for your smartphone, reliable charging solutions, or premium audio accessories, we have you covered.</p>
          <p>In addition to our wide range of products, {Env.businessName} provides professional repair services for smartphones, tablets, and other electronic devices. Our skilled technicians are equipped with the latest tools and technology to diagnose and repair various issues efficiently, ensuring that your devices are restored to optimal performance.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h3>Our Commitment to Quality</h3>
          <p>Quality is at the core of everything we do at {Env.businessName}. We are committed to offering only the highest-quality products and services that meet the highest standards of performance, durability, and reliability. Each product in our inventory undergoes rigorous testing and evaluation to ensure its quality and functionality, providing our customers with peace of mind and confidence in their purchases.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h3>Customer Satisfaction</h3>
          <p>At {Env.businessName}, customer satisfaction is our top priority. We strive to exceed our customers' expectations at every touchpoint, from the moment they enter our stores or visit our website to the post-purchase support they receive. Our knowledgeable and friendly staff are dedicated to assisting customers in finding the perfect accessories or solutions to meet their needs, ensuring a seamless and enjoyable shopping experience.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h3>Visit Us Today</h3>
          <p>Whether you're in need of premium mobile accessories, professional device repairs, or expert advice, {Env.businessName} is here to serve you. Visit one of our conveniently located branches or explore our online store to discover the latest products and services tailored to enhance your mobile lifestyle. Experience the {Env.businessName} difference today!</p>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default MobileBitz;
