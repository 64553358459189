import React, { useState } from "react";
import "./singup.css"
import Navbar1 from "../Navbar/Navbar1";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify'; // Added import for react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Added import for react-toastify CSS
const Singup = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [errors,setErrors] = useState({});
    const [emailChecked, setEmailChecked] = useState(false);
    const [smsChecked, setSmsChecked] = useState(true);

    const handleEmailChange = () => {
        setEmailChecked(!emailChecked);
    };

    const handleSmsChange = () => {
        setSmsChecked(!smsChecked);
    };


    const handleChange = (e) => {
        let obj = data;
        obj[e.target.name] = e.target.value;
        setData(obj);
    }

    const submit = () => {
        if(data.conpassword !== data.password){
            // setErrors({...errors,["password"]:"Passwords do not match!"});
            toast.error("Passwords do not match!");
            return;
        }else{
            setErrors({...errors,["password"]:null});
        }
        if(data.email.indexOf('@') === -1 || data.email.substr(data.email.indexOf('@')).length === 1 || data.email.substr(data.email.indexOf('@')).indexOf('.') === -1 || data.email.substr(data.email.indexOf('.',-1)).length === 1){
            // setErrors({...errors,["email"]:"Not A valid Email!"});
            toast.error("Not a valid Email!");
            return;
        }else{
            setErrors({...errors,["email"]:null});
        }


        let finalData = {
            ...data,
            allowemail: emailChecked,
            allowsms: smsChecked
        };
        console.log("finalData====", finalData);

        axios.post(`${Env.server}/api/user/signup`, finalData)
            .then((res) => {
                console.log("abc===>>>", res.data)
                if (res.data.message === "success") {
                    toast.success("Signup Successful!");
                    navigate("/Singin");
                }
            })
            .catch((err) => {
                console.log("err===>>>", err);
                toast.error("Error occurred during signup!");
            })
    }

    return (
        <>
            <Navbar1 /><br /><br /><br />
            <div class="container register-form pt-5   w-75">
                <div class="form justify-content-center align-items-center">
                    <div class="form-content ">
                        <h1 className="fs-7" style={{color: '#071A44'}}>Sign up</h1>
                        <p style={{ font: "20px", color: '#071A44'}}>Already have an account? <Link to="/Singin"><span className="fw-bolder" class="about-link"><b>Sign in here</b></span></Link> </p>
                        <div class="row pt-4">

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="First name " className="fs-9" style={{color: '#071A44'}}> First name*</label>
                                    <input type="text" class="form-control" name="firstname" onChange={handleChange} placeholder="First name" />
                                </div>
                                <div class="form-group">
                                    <label for="Email Address" className="fs-9" style={{color: '#071A44'}}>Email Address*</label>
                                    <input type="text" class="form-control" placeholder="Email Address" name="email" onChange={handleChange} />
                                    {errors.email && <p style={{fontSize:'14px',color:'red'}} >{errors.email}</p>}
                                </div>
                                <div class="form-group">
                                    <label for="Confirm Password" className="fs-9" style={{color: '#071A44'}}>Confirm Password*</label>
                                    <input type="password" class="form-control" placeholder="Confirm Password" name="conpassword" onChange={handleChange} />
                                    {errors.password && <p style={{fontSize:'14px',color:'red'}} >{errors.password}</p>}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="Last name" className="fs-9" style={{color: '#071A44'}} >Last name*</label>
                                    <input type="text" class="form-control" placeholder="Last name" onChange={handleChange} name="lastname" />
                                </div>
                                <div class="form-group">
                                    <label for="Password" className="fs-9" style={{color: '#071A44'}}>Password*</label>
                                    <input type="password" class="form-control" placeholder="Password" onChange={handleChange} name="password" />
                                    {errors.password && <p style={{fontSize:'14px',color:'red'}} >{errors.password}</p>}
                                </div>
                                <div class="form-group">
                                    <label for="Contact Number" className="fs-9" style={{color: '#071A44'}}>Contact Number*</label>
                                    <input type="number" class="form-control" placeholder="Contact Number" onChange={handleChange} name="phNum" />
                                </div>
                            </div>


                        </div>

                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="allowemail"
                                id="flexCheckDefault"
                                checked={emailChecked}
                                onChange={handleEmailChange}
                            />
                            <label className="form-check-label fs-9" style={{color: '#071A44'}} htmlFor="flexCheckDefault">
                                Keep me up to date via email
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckChecked"
                                name="allowsms"
                                checked={smsChecked}
                                onChange={handleSmsChange}
                            />
                            <label className="form-check-label fs-9" style={{color: '#071A44'}} htmlFor="flexCheckChecked" >
                                Keep me up to date via SMS
                            </label>
                        </div>

                        <button type="button" class="btnSubmit btn-lg mt-2 bg-white fw-bolder fs-9" style={{color: '#071A44', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}} onClick={submit} >Create account</button>
                    </div>
                </div>
            </div>
            <Footer />
            <ToastContainer /> {/* Added ToastContainer for toast notifications */}
        </>
    )
}
export default Singup;