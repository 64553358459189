import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    authData:null,
    status:false
};


const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        setAuthData:(state,action)=>{
            state.authData = action.payload;
            state.status = true;
        },
        removeAuthData:(state)=>{
            state.authData = null;
            state.status = false;
        }
    }
})


export const { setAuthData, removeAuthData } = authSlice.actions;

export default authSlice.reducer;

